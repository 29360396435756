import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Grid, IconButton, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { DataTable, SearchField, ViewEditIcon } from 'src/components'
import { useDataTableUtils } from 'src/hooks/useUtils'
import DialogView from './DialogView'
import { API_GetDesignateDataModelList } from 'src/constants/api'
import DeleteWarningDialog from 'src/components/DeleteWarningDialog'

function DataModelTab(props) {
  const { loading, data, getData, PaginationProps } = useDataTableUtils(API_GetDesignateDataModelList)

  const [dialogOpen, setDialogOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)

  const handleDelete = () => {
    // TODO: delete action
    setDeleteOpen(false)
    getData()
  }

  return <Box p={2}>
    <Grid container spacing={1} alignItems="center">
      <Grid item xs>
        <SearchField />
      </Grid>
      <Grid item>
        <Button variant='outlined' onClick={() => setDialogOpen(true)}
          startIcon={<FontAwesomeIcon icon='far fa-plus' />}
        >
          Add Data Model Owner
        </Button>
      </Grid>
    </Grid>
    <DataTable
      loading={loading}
      PaginationProps={PaginationProps}
      columns={[
        {
          field: 'DataModelName',
          title: 'Data Model Name'
        }, {
          field: 'DataModelProviderName',
          title: 'Data Model Provider Name'
        }, {
          field: 'CreatedBy',
          title: 'Created By'
        }, {
          field: 'Created',
          title: 'CreatedDate',
          type: 'datetime'
        }, {
          field: 'LastUpdatedBy',
          title: 'Last Updated By'
        }, {
          field: 'LastUpdatedDate',
          title: 'Last Updated',
          type: 'datetime'
        }, {
          title: 'Actions',
          render: rowData => <>
            <Tooltip title='View/Edit'>
              <IconButton onClick={() => setDialogOpen(rowData)}>
                <ViewEditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Delete' onClick={() => setDeleteOpen(rowData)}>
              <IconButton>
                <FontAwesomeIcon icon='fal fa-trash-alt' fixedWidth />
              </IconButton>
            </Tooltip>
          </>
        }
      ]}
      data={data}
    />

    <DialogView open={dialogOpen} onClose={() => setDialogOpen(false)} />
    <DeleteWarningDialog title='Delete Data Model Owner Configuration'
      open={Boolean(deleteOpen)}
      onClose={() => setDeleteOpen(false)}
      onConfirm={handleDelete}
    >
      The Selected Data Model Owner Configuration will be deleted.
      This action is <b>irreversible</b> and will affect all items related to this DataModel Owner Configuration.<br /><br />
      Are you sure you want to continue?
    </DeleteWarningDialog>
  </Box>
}

export default DataModelTab