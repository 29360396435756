import en from './en'
import zhCn from './zh-cn'
import jp from './jp'

const languagePack = {
  en,
  zhCn,
  jp
}

export default languagePack