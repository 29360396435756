import React, { useContext, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Menu as MuiMenu,
  MenuItem,
  Toolbar,
  Tooltip,
} from '@mui/material'
import { DXPContext } from 'src/layouts/MainLayout'

import LANG from 'src/testdata/language'
import { useTranslation } from 'react-i18next'

const MENU_KEY = { LANG: 'lang', USER: 'user' }

const Menu = ({ id, anchorEl, open, ...props }) => <MuiMenu
  id={`${id}-menu`}
  anchorEl={anchorEl}
  open={Boolean(anchorEl) && open}
  MenuListProps={{
    'aria-labelledby': `${id}-button`,
  }}
  anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'right',
  }}
  transformOrigin={{
    vertical: 'top',
    horizontal: 'right',
  }}
  {...props}
/>

function Topbar(props) {
  const { isSidebarOpen, setIsSidebarOpen } = useContext(DXPContext)
  const [anchorEl, setAnchorEl] = useState(null)
  const [menu, setMenu] = useState()
  const { t, i18n } = useTranslation()

  const handleClose = () => setAnchorEl(null) && setMenu(null)

  const handleButtonClick = (e, key) => {
    setMenu(key)
    setAnchorEl(e.target.closest("div.MuiGrid-item"))
  }

  const handleChangeLanguage = lang => {
    i18n.changeLanguage(lang)
    handleClose()
  }

  const handleLogout = () => {
    // TODO: log out
    handleClose()
  }

  return <Toolbar>
    <IconButton
      onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      size="large"
    >
      <FontAwesomeIcon icon='fal fa-bars' />
    </IconButton>
    <Box sx={theme => ({ height: theme.props.toolbar, py: .75 })}
      flexGrow={1} mx={1}
    >
      <img src={`${process.env.PUBLIC_URL}/dxp_logo.png`}
        height='100%' alt='DXP logo'
      />
    </Box>
    <Grid item>
      <Tooltip title="Change language">
        <IconButton size="large" id="language-button"
          onClick={e => handleButtonClick(e, MENU_KEY.LANG)}
        >
          <FontAwesomeIcon icon='fal fa-globe' />
        </IconButton>
      </Tooltip>
      <Menu id="language"
        anchorEl={anchorEl}
        open={menu === MENU_KEY.LANG}
        onClose={handleClose}
      >
        {LANG.map((lang, l) => <MenuItem key={l}
          onClick={() => handleChangeLanguage(lang.code)}
        >
          {lang.name}
        </MenuItem>)}
      </Menu>
    </Grid>
    <Grid item>
      <ListItemButton size="large"
        onClick={e => handleButtonClick(e, MENU_KEY.USER)}
        sx={{ px: { xs: 0, sm: 2 } }}
      >
        <ListItemAvatar sx={{ minWidth: { xs: 'unset', sm: 56 } }}><Avatar>
          <FontAwesomeIcon icon='fal fa-user' />
        </Avatar></ListItemAvatar>
        <ListItemText
          primary="Willem Johnson"
          secondary="DXP-Admin"
          sx={{ mr: 2, display: { xs: 'none', sm: 'block' } }}
        />
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          <FontAwesomeIcon icon={`fal fa-chevron-${menu === MENU_KEY.USER && anchorEl ? 'up' : 'down'}`} />
        </Box>
      </ListItemButton>
      <Menu id="user"
        anchorEl={anchorEl}
        open={menu === MENU_KEY.USER}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>{t('myAccount')}</MenuItem>
        <MenuItem onClick={handleLogout}>{t('logout')}</MenuItem>
      </Menu>
    </Grid>
  </Toolbar >
}

export default Topbar