import React, { useState, useEffect } from 'react'
import { Box, Button, Grid, IconButton, LinearProgress, MenuItem, Pagination, Select, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material'
import { Trans } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useFieldUtils } from 'src/hooks/useUtils'

function DataTable({
  expand,
  columns, data, loading,
  PaginationProps: { onPerChange, onPageChange, ...PaginationProps } = {},
  hidePagination,
  footerContents,
  children,
  ...props
}) {
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [expandedRows, setExpandedRows] = useState([])
  const [keyChg, setKeyChg] = useState(new Date().toUTCString())

  const handlePerChange = e => {
    setExpandedRows([])
    setItemsPerPage(e.target.value)
    onPerChange(e.target.value)
  }
  const handlePageChange = (...args) => {
    setExpandedRows([])
    onPageChange(...args)
  }

  const handleExpandRow = r => {
    if (expandedRows.includes(r))
      setExpandedRows(expandedRows.filter(i => i !== r))
    else
      setExpandedRows([...expandedRows, r])
  }

  const renderCell = (col, row, rowIndex) => {
    if (col.render)
      return col.render(row, rowIndex)
    let field = col.field?.split('.')
    let item = row[field.shift()]
    while (field.length)
      item = item[field.shift()]

    if (col.type === 'date' || col.format)
      return moment(item).format(col.format)
    if (col.type === 'datetime')
      return moment(item).format('D MMM YYYY HH:mmA')
    return item
  }

  useEffect(() => {
    setKeyChg(new Date().toUTCString())
  }, [data])

  return <Stack spacing={2}>
    <Table stickyHeader {...props}>
      <TableHead>
        <TableRow>
          {columns.map((column, c) => <TableCell key={c}>
            {column.title || column.field}
          </TableCell>)}
          {Boolean(expand) && <TableCell />}
        </TableRow>
        {loading && <TableRow><TableCell colSpan='100%'
          sx={{ borderBottom: 0, p: 0 }}
        >
          <LinearProgress />
        </TableCell></TableRow>}
      </TableHead>
      <TableBody>
        {data.filter(r => !r.hidden).map((row, r) => <React.Fragment key={keyChg + '-' + r}>
          <TableRow>
            {columns.map((col, c) => <TableCell key={c}>
              {renderCell(col, row, r)}
            </TableCell>)}
            {Boolean(expand) && <TableCell sx={{ textAlign: 'center' }}>
              <IconButton onClick={() => handleExpandRow(r)}>
                <Box sx={theme => ({
                  transform: expandedRows.includes(r) ? 'rotate(180deg)' : 'none',
                  transition: theme.transitions.create()
                })}>
                  <FontAwesomeIcon icon={`far fa-chevron-down`} fixedWidth />
                </Box>
              </IconButton>
            </TableCell>}
          </TableRow>
          {(Boolean(expand) && expandedRows.includes(r)) && <TableRow>
            <TableCell colSpan='100%'>
              {expand(row)}
            </TableCell>
          </TableRow>}
        </React.Fragment>)}
      </TableBody>
    </Table>

    {children}

    {!hidePagination && <Grid container spacing={1} justifyContent='space-between'>
      <Grid item>
        <Trans i18nKey="showItem">
          <Select value={itemsPerPage} size="small"
            onChange={handlePerChange}
          >
            <MenuItem value={-1}>All</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </Trans>
      </Grid>
      {footerContents && <Grid item xs>{footerContents}</Grid>}
      <Grid item>
        <Pagination color="primary" {...PaginationProps}
          onChange={handlePageChange}
        />
      </Grid>
    </Grid>}
  </Stack>
}

export const EditTable = ({ name, columns, hideRemove, ...props }) => {
  const { register, formState: { errors }, setValue, getValues } = useFormContext()
  const { errorState } = useFieldUtils({ errors })
  const { remove } = useFieldArray({ name })

  const handleRemove = index => {
    remove(index)
    // reset indices
    setValue(name, getValues(name).map((x, index) => ({ ...x, index })))
  }

  return <DataTable hidePagination sx={{ '& tr': { verticalAlign: 'top' } }}
    {...props}
    columns={[
      ...columns.map(col => ({
        render: rowData => <TextField
          {...register(`${name}.${rowData.index}.${col.name || col.field}`)}
          fullWidth
          {...errorState(`${name}.${rowData.index}.${col.name || col.field}`)}
        />,
        ...col,
      })),
      !hideRemove && {
        render: rowData => <IconButton onClick={() => handleRemove(rowData.index)}>
          <FontAwesomeIcon icon='fal fa-trash-alt' />
        </IconButton>
      }
    ]}
  />
}

export const AddFieldArrayBtn = ({ defaultFields, ...props }) => {
  const { append, fields } = useFieldArray({ name: 'AuthorisedPersonelData' })

  return <Button variant='outlined' {...props}
    onClick={() => append({ ...defaultFields, index: fields.length })}
    startIcon={<FontAwesomeIcon icon='far fa-plus' />}
  />
}

export default DataTable