import { Box, Tab, Tabs } from '@mui/material'
import React, { useState } from 'react'
import ServicesTab from './ServicesTab'
import SFTPTab from './SFTPTab'
import NotificationTab from './NotificationTab'

const tabContent = [
  <ServicesTab />,
  <SFTPTab />,
  <NotificationTab />
]

function ServicesPage(props) {
  const [tabIndex, setTabIndex] = useState(0)

  return <Box p={3} pt={1}>
    <Tabs value={tabIndex}
      onChange={(e, i) => setTabIndex(i)}
      textColor="secondary"
      indicatorColor="secondary"
    >
      <Tab label="Web Services" />
      <Tab label="SFTP" />
      <Tab label="Notifications" />
    </Tabs>

    {tabContent[tabIndex]}
  </Box>
}

export default ServicesPage