import React, { createContext, createRef, useContext, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Checkbox, Collapse, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'

const TreeContext = createContext()

const TreeItem = ({ item, index, isLastItem, parents = [], level = 0 }) => {
  const [open, setOpen] = useState(false)
  const [checkState, setCheckState] = useState(false)
  const { onCheck, checkbox, dense, ListProps, renderItem, onClick, openOnCheck, childKeys } = useContext(TreeContext)
  const checkboxRef = createRef()

  const getKey = () => childKeys ? childKeys[level] : 'children'

  const handleButtonClick = e => {
    if (Boolean(onClick))
      onClick(checkboxRef.current.checked, item, index, parents)
  }
  const handleCheck = e => {
    if (Boolean(onCheck))
      onCheck(e, item, index, parents)
    else
      setCheckState(!checkState)
    if (e.target.checked && openOnCheck && item.children?.length)
      setOpen(true)
  }

  return <>
    <ListItem disablePadding
      secondaryAction={(Array.isArray(item[getKey()]) && item[getKey()].length) ?
        <IconButton edge="end"
          onClick={() => setOpen(!open)}
        >
          <FontAwesomeIcon icon="fal fa-chevron-down" />
        </IconButton>
        : null}
      divider={!isLastItem || (isLastItem && open)} dense={dense}
    >
      <ListItemButton sx={{ pl: (level + 1) * 2 }} onClick={handleButtonClick}>
        {(Boolean(onCheck) || checkbox) ? <ListItemIcon sx={{ minWidth: 'unset' }}>
          <Checkbox edge="start" disableRipple
            checked={item.checked || checkState} tabIndex={-1}
            indeterminate={item[getKey()]?.filter(i => i.checked).length < item[getKey()]?.length && (item.checked || checkState)}
            inputRef={checkboxRef}
            onChange={handleCheck}
            inputProps={{
              'aria-labelledby': `checklabel-${level}-${index}`,
              id: `checkbox-${level}-${index}`
            }}
          />
        </ListItemIcon> : null}
        <ListItemText id={`checklabel-${level}-${index}`}
          primary={<label htmlFor={`checkbox-${level}-${index}`}>
            {Boolean(renderItem) ? renderItem(item, level) : (item.label || String(item))}
          </label>}
        />
      </ListItemButton>
    </ListItem>
    {(Array.isArray(item[getKey()]) && item[getKey()].length) ? <Collapse
      in={open} timeout="auto" unmountOnExit
    >
      <List component="div" disablePadding {...ListProps}>
        {item[getKey()].map((child, c) => <TreeItem
          key={c}
          parents={[{ item, index }, ...parents]}
          item={child} index={c}
          isLastItem={isLastItem && c === item[getKey()].length - 1}
          level={level + 1}
        />)}
      </List>
    </Collapse> : null}
  </>
}

function TreeView({
  renderItem,
  onCheck, checkbox, openOnCheck,
  ListProps, onClick,
  childKeys,
  items, ...props
}) {

  return <TreeContext.Provider value={{
    renderItem,
    onCheck, checkbox, openOnCheck,
    ListProps, onClick,
    childKeys
  }}>
    <List disablePadding {...ListProps}>
      {items.map((item, i) => <TreeItem
        key={i}
        item={item} index={i}
        isLastItem={i === items.length - 1}
        level={0}
      />)}
    </List>
  </TreeContext.Provider>
}

export default TreeView