import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from 'yup'

export const DATAMODEL = {
  resolver: yupResolver(yup.object({
    DesignateDataModelId: yup.string().required(),
    OrganisationId: yup.string().required(),
    ApprovalEmailAddressData: yup.array().of(yup.object({
      Name: yup.string(),
      Email: yup.string().email()
    }))
  })),
  defaultValues: {
    DesignateDataModelId: '',
    OrganisationId: '',
    ApprovalEmailAddressData: []
  }
}

export const SUBSCRIBER = {
  resolver: yupResolver(yup.object({
    Id: yup.string(),
    OrganisationName: yup.string().required(),
    OrganisationAbbreviation: yup.string().required(),
    AuthorisedPersonelData: yup.array().of(yup.object({
      Name: yup.string(),
      Email: yup.string().email()
    })),
    OrganisationApprovalData: yup.array().of(yup.object({
      Name: yup.string(),
      Email: yup.string().email()
    }))
  })),
  defaultValues: {
    Id: '',
    OrganisationName: '',
    OrganisationAbbreviation: '',
    AuthorisedPersonelData: [],
    OrganisationApprovalData: []
  }
}