import { Button, Card, CardActionArea, Chip, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, MenuItem, Radio, RadioGroup, TextField, ToggleButton, ToggleButtonGroup, Typography, useRadioGroup } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import PerfectScrollbar from 'react-perfect-scrollbar'
import React, { useEffect, useMemo, useRef } from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { DateTimeControl, DialogTitle } from 'src/components'
import { DAYS_OF_WEEK, SCHEDULE_COLOR } from 'src/constants'
import { sftp } from '../form-validation'
import TESTDATA from 'src/testdata/svc_sftp-COPY'
import TESTENTITIES from 'src/testdata/svc_entities'
import AttributeList from './AttributeList'
import { useFieldUtils } from 'src/hooks/useUtils'

const DATE_FIELDS = ['EffectiveStartDate', 'EffectiveEndDate', 'Created', 'Modified']
const DATAMODELS = TESTDATA.map(x => x.DataModel)


function EntityRadio({ Entity, Attributes, inbound, ...props }) {
  const { setValue, getValues } = useFormContext()
  const { value } = useRadioGroup()
  const ref = useRef()

  useEffect(() => {
    if (value === Entity)
      setValue('Attributes', ref.current)
  }, [value, Entity, setValue])


  return <Card><CardActionArea sx={{ px: 2, py: 1 }}>
    <FormControlLabel
      value={Entity} control={<Radio />} label={<>
        <Typography fontWeight='fontWeightBold'>{Entity}</Typography>
        <AttributeList attrs={Attributes}
          selectable={!inbound}
          preselected={getValues('Entity') === Entity ? getValues('Attributes') : []}
          innerRef={ref}
          onChange={sel => setValue('Attributes', sel)}
          mt={-.5}
        />
      </>}
    />
  </CardActionArea></Card>
}

function DeltaDataDialog({ item, inbound, ...props }) {
  const { handleSubmit, register, watch, setValue, getValues, reset, ...methods } = useForm(sftp)
  const { registerRadio } = useFieldUtils({ setValue, watch })

  const isInbound = useMemo(
    () => item ? item.ServiceType === "DeltaUpload" : inbound,
    [inbound, item]
  )

  const TEST_ENTITIES = useMemo(() => {
    if (item)
      return [{
        Entity: item.Entity,
        Attributes: item.Attributes
      }, ...TESTENTITIES]
    return TESTENTITIES
  }, [item])

  useEffect(() => {
    if (item) {
      DATE_FIELDS.forEach(df => item[df] = moment(item[df]))
      reset(item)
    } else
      reset(sftp.defaultValues)
  }, [item, reset])

  const submitForm = data => console.log(data)

  return <FormProvider {...{ handleSubmit, register, watch, setValue, getValues, reset, ...methods }}><form onSubmit={handleSubmit(submitForm)}>
    <Dialog maxWidth="lg" {...props}>
      <DialogTitle onClose={props.onClose}>
        <Chip variant="square"
          label={isInbound ? 'Inbound' : 'Outbound'}
          color={isInbound ? 'success' : 'error'}
        />&ensp;
        {item && 'Edit '}
        Delta Data Upload Service Subscriptions
      </DialogTitle>
      <PerfectScrollbar>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant='h6' mb={1}>Service Info</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField fullWidth required
                    label='Service Name'
                    {...register('ServiceName')}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DateTimeControl Component={DatePicker}
                    name='EffectiveStartDate'
                    label="Effective start date"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DateTimeControl Component={DatePicker}
                    name='EffectiveEndDate'
                    label="Effective end date"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant='h6' mb={1}>Schedule</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ToggleButtonGroup
                    onChange={(e, val) => setValue('Schedule.Type', val)}
                    value={watch('Schedule.Type')}
                    color='primary'
                    fullWidth exclusive
                  >
                    {Object.entries(SCHEDULE_COLOR).map(([k, v]) =>
                      <ToggleButton value={k} key={k} size='small'>{v.label}</ToggleButton>
                    )}
                  </ToggleButtonGroup>
                </Grid>
                {watch('Schedule.Type') !== 'day' && <Grid item xs={12} sm={4}>
                  <TextField select fullWidth
                    label={watch('Schedule.Type') === 'month' ? "Date" : 'Day'}
                    {...register('Schedule.Day')}
                    value={getValues('Schedule.Day')}
                  >
                    {watch('Schedule.Type') === 'month'
                      ? [...Array(28)].map((e, i) => <MenuItem
                        key={'mth' + i}
                        value={i + 1}
                      >
                        {i + 1}
                      </MenuItem>) : DAYS_OF_WEEK.map((e, i) => <MenuItem
                        key={'week' + i}
                        value={i}
                      >
                        {e}
                      </MenuItem>)
                    }
                  </TextField>
                </Grid>}
                <Grid item xs={12} sm={4}>
                  <TextField select fullWidth
                    label='Time'
                    {...register('Schedule.Time')}
                    value={getValues('Schedule.Time')}
                  >
                    {[...Array(24)].map((e, i) => <MenuItem
                      key={i} value={i}
                    >
                      {i}
                    </MenuItem>)}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField select fullWidth
                    label='Effective Days'
                    {...register('Schedule.EffectiveDays')}
                    value={getValues('Schedule.EffectiveDays')}
                  >
                    {[...Array(28)].map((e, i) => <MenuItem
                      key={i} value={i}
                    >
                      {i}
                    </MenuItem>)}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography variant='h6' mb={1}>Data Request</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={9} md={6}>
                  <TextField select fullWidth
                    label='Data Model'
                    {...register('DataModel')}
                    value={getValues('DataModel')}
                  >
                    {DATAMODELS.map((dm, d) => <MenuItem
                      key={d} value={dm}
                    >
                      {dm}
                    </MenuItem>)}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={3} md={2}>
                  <TextField select fullWidth
                    label='Version'
                    {...registerRadio('Version')}
                  >
                    {[...Array(5)].map((e, i) => <MenuItem
                      key={i} value={i + 1}
                    >
                      {i + 1}
                    </MenuItem>)}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel id="entity-label">
                      Select the entity below
                    </FormLabel>
                    <FormHelperText sx={{ mx: 0 }}>
                      (You can only select attributes from 1 data entity per submission)
                    </FormHelperText>
                    <RadioGroup
                      aria-labelledby="entity-label"
                      name='Entity'
                      onChange={e => setValue('Entity', e.target.value)}
                      value={watch('Entity') || ''}
                    >
                      <Grid container mt={0} spacing={1.5} direction='column'>
                        {TEST_ENTITIES.map(({ Entity, Attributes }, e) => <Grid item key={e}>
                          <EntityRadio {...{
                            Entity, Attributes, inbound, setValue
                          }} />
                        </Grid>)}
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </DialogContent>
      </PerfectScrollbar>
      <DialogActions>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button variant='contained' onClick={handleSubmit(submitForm)}>Add</Button>
      </DialogActions>
    </Dialog>
  </form></FormProvider>
}

export default DeltaDataDialog