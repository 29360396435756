import React, { useContext, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Card,
  CardActionArea,
  CardContent,
  Drawer as MuiDrawer,
  List,
  Stack,
  Typography,
  styled,
  Box,
  ListItem,
  Badge,
} from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { DXPContext } from '../MainLayout'

export const SidebarIcon = ({ icon, family, ...props }) => <FontAwesomeIcon
  icon={`${family || 'far'} fa-${icon}`}
  fixedWidth size="lg"
  {...props}
/>

export const MenuItem = ({ item, light, ...props }) => {

  const location = useLocation()
  const {
    secondOpen, setSecondOpen,
  } = useContext(DXPContext)

  const backgroundColor = useMemo(() => {
    if (location?.pathname === item.href)
      return 'secondary.main'
    if (secondOpen === item)
      return 'secondary.light'
    return 'transparent'
  }, [location, secondOpen, item])

  const handleClick = ix => {
    if (!secondOpen && ix.children)
      setSecondOpen(ix)
    else
      setSecondOpen(false)
  }

  return <Card square elevation={0}
    sx={{
      backgroundColor, height: '100%',
      textDecoration: 'none'
    }}
    component={ListItem}
    disablePadding divider
    {...props}
  >
    <CardActionArea sx={theme => ({
      height: '100%',
      transition: theme.transitions.create(),
      '&:hover': { backgroundColor: 'secondary.hover' }
    })}
      onClick={() => handleClick(item)}
      {...(!item.children || item.external) && {
        component: Link,
        to: item.href,
        target: item.external ? '_blank' : '_self'
      }}
    >
      <CardContent component={Stack}
        alignItems='center' spacing={1}
        justifyContent='center'
        height='100%' p={1.5}
        textAlign='center'
      >
        <Badge invisible={!item.external} badgeContent={
          <FontAwesomeIcon size='xs'
            icon='far fa-arrow-up-right-from-square'
          />
        } color='secondary' sx={theme => ({
          position: 'absolute',
          top: theme.spacing(2),
          right: theme.spacing(2)
        })} />
        <Box color={light ? 'primary.dark' : 'secondary.50'}>
          {item.Icon}
        </Box>
        <Typography color={light ? 'primary.dark' : 'secondary.50'} variant='body2'>
          {item.title}
        </Typography>
      </CardContent>
    </CardActionArea>
  </Card>
}

const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
  width: theme.props.sidebar,
  left: open ? 0 : -theme.props.sidebar,
  transition: theme.transitions.create(),
  position: theme.breakpoints.down('sm') ? 'absolute' : 'relative',
  bottom: 0,
  top: 0,
  '& > .MuiPaper-root': {
    position: 'relative',
    top: 'unset',
    background: theme.palette.primary.main,
    border: 0,
  }
}))

function Sidebar({ items, ListProps, ...props }) {
  const { secondOpen } = useContext(DXPContext)

  return <Drawer
    variant="permanent"
    anchor="left"
    elevation={16}
    PaperProps={{ elevation: secondOpen ? 16 : 0 }}
    {...props}
  >
    <PerfectScrollbar><List
      disablePadding
      sx={theme => ({ width: theme.props.sidebar })}
      {...ListProps}
    >
      {items.map((item, i) => <MenuItem key={i} item={item} />)}
    </List></PerfectScrollbar>
  </Drawer>
}

export default Sidebar