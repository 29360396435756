import { pascalCase } from "pascal-case"

import USERNAMES from 'src/testdata/username_gen'

export function useTestPaginator(MAX_ITEMS, delay) {

  const TEST_paginate = ({ data, ...res }, itemsPerPage, pageNum) => new Promise(resolve => {
    let pageInfo = {
      totalItems: MAX_ITEMS > data.length ? data.length : MAX_ITEMS,
      page: pageNum, itemsPerPage
    }
    pageInfo.totalPages = Math.ceil(pageInfo.totalItems / itemsPerPage)

    const startIndex = pageNum * itemsPerPage
    let endIndex = startIndex + itemsPerPage
    if (endIndex > MAX_ITEMS)
      endIndex = MAX_ITEMS

    let ret = {
      ...pageInfo,
      data: data.slice(startIndex, endIndex)
    }

    setTimeout(() => resolve({ ...res, data: ret }), delay)
  })

  return { TEST_paginate }
}

export function useTestUsernames() {
  return () => {
    const adj = USERNAMES.adjectives[Math.floor(Math.random() * USERNAMES.adjectives.length)]
    const noun = USERNAMES.nouns[Math.floor(Math.random() * USERNAMES.nouns.length)]
    return pascalCase(adj + ' ' + noun)
      + (Math.floor(Math.random() * 999))
  }
}