import React, { useState, useEffect, createContext, useContext } from "react";
import {
  Collapse,
  Grid,
  Typography,
  ListItemButton,
  Box,
  Chip,
} from "@mui/material";
import { DataTable, DisplayField } from "src/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useDataTableUtils } from "src/hooks/useUtils";

const SectionContext = createContext(null)

const Section = ({ label, children }) => {
  const { open, handleClick } = useContext(SectionContext)
  return <>
    <ListItemButton
      onClick={() => handleClick(label)}
      sx={theme => ({
        borderRadius: 1,
        ...open[label] && ({
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }),
        bgcolor: "grey.200",
        p: 1.5, mt: 1
      })}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <Typography color={"text.primary"} fontWeight={"fontWeightNormal"}>
            {label}
          </Typography>
        </Grid>
        <Grid item>
          <FontAwesomeIcon icon="fal fa-chevron-down" rotation={open[label] ? 180 : 0} />
        </Grid>
      </Grid>
    </ListItemButton>
    <Collapse in={open[label]} sx={theme => ({
      bgcolor: "grey.200",
      p: .5, pt: 0,
      borderBottomRightRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
      m: 0
    })}>
      <Box bgcolor='common.white' borderRadius={1} p={1.5}>
        <Grid container spacing={1} width='100%'>
          {children}
        </Grid>
      </Box>
    </Collapse>
  </>
}

function ApplicationFormDetails({ fetchUrl, ...props }) {
  const { loading, data, getData, PaginationProps } = useDataTableUtils(
    "/testdata/inbox/19_GetWorkflowFormHistoryGrid.json"
  );

  const [applicationFormData, setApplicationFormData] = useState({});
  const getApplicationFormData = () =>
    axios(fetchUrl).then((res) => {
      setApplicationFormData(res.data);
    });

  const [open, setOpen] = useState({
    'Form Service Request Detail': false,
    'Requestor Details': false,
    'Subscribed Data Detail': false,
    'Special Data Request Detail': false,
    'SFTP Sync Access Details': false,
    'Notification Settings Details': false,
    'Declaration Details': false,
    'Form History': false
  })

  useEffect(() => {
    getApplicationFormData();
    getData();
    // eslint-disable-next-line
  }, []);

  const handleClick = key => setOpen({ ...open, [key]: !open[key] })



  return <SectionContext.Provider value={{
    open, handleClick
  }}>
    <Section label={'Form Service Request Detail'}>
      <Grid item xs={12} sm={6}>
        <DisplayField label='Reference Number'>
          {applicationFormData.FormServiceRequestDetail?.ReferenceNo}
        </DisplayField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <DisplayField label='Request Name'>
          {applicationFormData.FormServiceRequestDetail?.RequestName}hi
        </DisplayField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <DisplayField label='Purpose'>{applicationFormData.FormServiceRequestDetail?.Purpose}</DisplayField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <DisplayField label='Effective Start Date'>{applicationFormData.FormServiceRequestDetail?.EffectiveStartDate}</DisplayField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <DisplayField label='Effective End Date'>{applicationFormData.FormServiceRequestDetail?.EffectiveEndDate || "--"}</DisplayField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <DisplayField label='Remarks'>{applicationFormData.FormServiceRequestDetail?.Remarks || "--"}</DisplayField>
      </Grid>
    </Section>

    <Section label={'Requestor Details'}>
      <Grid item xs={12} sm={6}>
        <DisplayField label='Organisation Name'>{applicationFormData.RequestorDetails?.OrganisationName}</DisplayField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <DisplayField label='Organisation Abbreviation'>{applicationFormData.RequestorDetails?.OrganisationAbbreviation}</DisplayField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <DisplayField label='Organisation Status'>{applicationFormData.RequestorDetails?.OrganisationStatus}</DisplayField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <DisplayField label='Title'>{applicationFormData.RequestorDetails?.Title}</DisplayField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <DisplayField label='Name'>{applicationFormData.RequestorDetails?.Name || "--"}</DisplayField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <DisplayField label='Email'>{applicationFormData.RequestorDetails?.Email || "--"}</DisplayField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <DisplayField label='Contact Number'>{applicationFormData.RequestorDetails?.ContactNumber || "--"}</DisplayField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <DisplayField label='Approval Authority'>{applicationFormData.RequestorDetails?.ApprovalAuthority || "--"}</DisplayField>
      </Grid>
    </Section>

    <Section label={'Subscribed Data Detail'}>
      <Grid item xs={12} sm={6}>
        <DisplayField label='Data Model Version Number'>{applicationFormData.SubscribedDataDetail?.DataModelVersionNumber}</DisplayField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <DisplayField label='Data Resource'>{applicationFormData.SubscribedDataDetail?.DataResource}</DisplayField>
      </Grid>
      <Grid item xs={12}>
        <DisplayField label='Selected Fields'>
          <Box display='flex' gap={.5} flexWrap='wrap' mt={.5}>
            {applicationFormData.SubscribedDataDetail?.SelectedFields.split(',').map((field, i) => <Chip size='small' key={i}
              label={field.trim()}
            />)}
          </Box>
        </DisplayField>
      </Grid>
    </Section>

    <Section label={'Special Data Request Detail'}>
      <Grid item xs={12} sm={6}>
        <DisplayField label='PII Fields Unmask'>{applicationFormData.SpecialDataRequestDetail?.PiiFieldsUnmask ? 'Yes' : 'No'}</DisplayField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <DisplayField label='Special Data Request PII Fields'>{applicationFormData.SpecialDataRequestDetail?.SpecialDataRequestPiiFields}</DisplayField>
      </Grid>
    </Section>

    <Section label={'SFTP Sync Access Details'}>
      <Grid item xs={12} sm={6}>
        <DisplayField label='Do you require SFTP Sync Access'>{applicationFormData.SFTPSyncAccessDetails?.IsSftpSyncAccessRequired ? 'Yes' : 'No'}</DisplayField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <DisplayField label='Daily Data Sync Time'>{applicationFormData.SFTPSyncAccessDetails?.SftpSyncAccessDailyTime}</DisplayField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <DisplayField label='Effective Days'>{applicationFormData.SFTPSyncAccessDetails?.SftpSyncAccessDailyEffectiveDays}</DisplayField>
      </Grid>
    </Section>

    <Section label={'Notification Settings Details'}>
      <Grid item xs={12} sm={6}>
        <DisplayField label='Production Notification Callback URL'>{applicationFormData.NotificationSettingsDetails?.ProductionNotificationCallbackURL || '--'}</DisplayField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <DisplayField label='Sandbox Notification Callback URL'>{applicationFormData.NotificationSettingsDetails?.SandboxNotificationCallbackURL || '--'}</DisplayField>
      </Grid>
    </Section>

    <Section label={'Declaration Details'}>
      <Grid item xs={12} sm={6}>
        <DisplayField label='Declaration Name'>{applicationFormData.DeclarationDetails?.DeclarationName || '--'}</DisplayField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <DisplayField label='Terms Of Service Agreed'>{applicationFormData.DeclarationDetails?.TermsOfServiceAgreed ? 'Yes' : 'No'}</DisplayField>
      </Grid>
    </Section>

    <Section label={'Form History'}>
      <Box mt={0} ml={-.5} mr={-2.5} flexGrow={1}>
        <DataTable
          loading={loading}
          PaginationProps={PaginationProps}
          columns={[
            {
              field: "FormId",
              title: "RowIndex",
            },
            {
              field: "TransactionDateString",
              title: "Transaction Date",
            },
            {
              field: "ActionBy",
              title: "Action By",
            },
            {
              field: "Remarks",
              title: "Remarks",
            },
            {
              field: "WorkflowStateEndDescription_EN",
              title: "WorkflowStateEndDescription_EN",
            },
          ]}
          data={data}
        />
      </Box>
    </Section>
  </SectionContext.Provider>
}

export default ApplicationFormDetails;
