export const DATEFMT_SIMPLE_DATE = "DD MMM YYYY"
export const DATEFMT_SIMPLE_DATETIME = "DD MMM YYYY HH:mmA"

export const ENVS = {
  staging: { name: 'Staging', color: 'info', icon: 'display-code' },
  sandbox: { name: 'Sandbox', color: 'error', icon: 'list-check' },
  production: { name: 'Production', color: 'success', icon: 'globe' }
}

export const PERMISSIONS_LIST = [
  {
    name: "Create",
    color: "info",
  }, {
    name: "Read",
    color: "success",
  }, {
    name: "Update",
    color: "warning"
  }, {
    name: "Delete",
    color: "error"
  }
]
export const PERMISSIONS_LIST_2 = [
  {
    name: "Get",
    color: "success",
  }, {
    name: "Post",
    color: "info",
  }, {
    name: "Put",
    color: "warning"
  }, {
    name: "Delete",
    color: "error"
  }
]

export const SCHEDULE_COLOR = {
  day: {
    label: "Daily",
    color: "warning"
  },
  week: {
    label: "Weekly",
    color: "primary"
  },
  month: {
    label: "Monthly",
    color: "secondary"
  }
}

export const SUBMISSION_COLOR = {
  Submitted: 'info',
  Pending: 'warning',
  Approved: 'success',
  Rejected: 'error',
  Cancelled: 'error'
}

export const APPROVAL_STATUS = [
  'Approved', 'Cancelled', 'Rejected', 'Pending'
]

export const ACTIVE_COLOR = {
  Active: 'success',
  Inactive: 0,
  New: 'primary',
  "Pending Activation": 2
}

export const ENV_COLOR = {
  Staging: "info",
  Sandbox: "error",
  Production: "success"
}

export const DAYS_OF_WEEK = [
  "Sunday",
  "Monday",
  "Tuesday",
  "wednesday",
  "Thursday",
  "Friday",
  "Saturday"
]

export const CLASSIFICATION_LEVELS = [
  "General",
  "Restricted",
  "Confidential",
  "Top Secret"
]


export const ATTR_TYPE = [
  'Decimal', 'Number', 'Date', 'Time', 'String'
]

export const RELATIONSHIP_TYPE = [
  "Is A", "Has A"
]
export const CARDINALITY_TYPE = [
  "One-to-One", "One-to-Many", "Many-to-One", "Many-to-Many"
]

export * from './tabs'