import React, { createContext, createRef, useState } from 'react'
import {
  AppBar,
  Backdrop,
  Box,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import Sidebar from './components/Sidebar'
import Topbar from './components/Topbar'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { ROUTE_LIST } from 'src/routes'
import L2Sidebar from './components/L2Sidebar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const DXPContext = createContext(null)

function MainLayout(props) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const containerRef = createRef(null)

  const [isSidebarOpen, setIsSidebarOpen] = useState(true)
  const [activeTab, setActiveTab] = useState(0)
  const [secondOpen, setSecondOpen] = useState(false)
  const [loadingBackdropOpen, setLoadingBackdropOpen] = useState(false)

  return <DXPContext.Provider
    value={{
      isSidebarOpen, setIsSidebarOpen,
      activeTab, setActiveTab,
      secondOpen, setSecondOpen,
      loadingBlock: loadingBackdropOpen,
      setLoadingBlock: setLoadingBackdropOpen
    }}
  >
    <Box sx={{
      display: 'flex',
      height: '100vh',
      width: '100vw',
      flexDirection: 'column'
    }}>
      <AppBar color='white' elevation={1} position='relative'
        sx={theme => ({ zIndex: theme.zIndex.appBar })}
      >
        <Topbar />
      </AppBar>
      <Box sx={{
        display: 'flex', overflow: 'hidden',
        height: '100%', width: '100%',
        position: 'relative'
      }} ref={containerRef}>
        <Box flexGrow={1} sx={theme => ({
          ml: {
            xs: 0,
            md: isSidebarOpen ? theme.props.sidebar + 'px' : 0
          },
          transition: theme.transitions.create(),
        })}>
          <PerfectScrollbar>
            <Box height='100%' width='100%'>
              {props.children}
            </Box>
          </PerfectScrollbar>
        </Box>
        <Backdrop open={isSidebarOpen}
          sx={theme => ({
            position: 'absolute',
            zIndex: theme.zIndex.drawerBackdrop,
            ...!isMobile && {
              visibility: 'hidden', '& > *': { visibility: 'visible' }
            }
          })}
        >
          <Sidebar open={isSidebarOpen} items={ROUTE_LIST} />
          <L2Sidebar in={Boolean(secondOpen)} container={containerRef.current} />
        </Backdrop>

        <Backdrop
          sx={{
            color: 'primary.200',
            zIndex: (theme) => theme.zIndex.modal + 1
          }}
          open={loadingBackdropOpen}
        >
          <FontAwesomeIcon icon='fad fa-spinner-third ' spin size='8x' />
        </Backdrop>
      </Box>
    </Box>
  </DXPContext.Provider>
}

export default MainLayout