import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Box, Button, Grid, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import { DataTable, SearchField } from 'src/components'
import SFTPExpanded from './SFTPExpanded'
import DeltaDataDialog from './DeltaDataDialog'
import { useDataTableUtils } from 'src/hooks/useUtils'

const DisplayAvatar = ({ up }) => <Tooltip title={up ? 'In' : 'Out'}>
  <Avatar sx={{
    width: 30, height: 30,
    backgroundColor: up ? 'success.main' : 'error.main',
    color: 'grey.700'
  }}>
    <FontAwesomeIcon size="xs" fixedWidth
      icon={`far fa-arrow-${up ? 'up-to' : 'down-from'}-arc`}
    />
  </Avatar>
</Tooltip>

function ServicesTab(props) {
  const { loading, data, getData, PaginationProps } = useDataTableUtils('/testdata/svc_sftp.json')
  const [anchorEl, setAnchorEl] = useState(null)
  const [dialogOpen, setDialogOpen] = useState(false)

  const handleSearch = e => {
    getData({ searchTerm: e.target.value })
  }

  const handleFilter = filterType => {
    setAnchorEl(null)
    getData({ filterType })
  }

  return <>
    <Grid container spacing={1} alignItems='center' my={2}>
      <Grid item xs={12} md={4}>
        <SearchField onChange={handleSearch}
          fullWidth label="Search"
        />
      </Grid>
      <Grid item xs>
        <Button variant='outlined'
          startIcon={<FontAwesomeIcon icon='fal fa-filter' />}
          endIcon={<FontAwesomeIcon icon='fal fa-chevron-down' />}
          onClick={e => setAnchorEl(e.target)}
        >
          Filter by
        </Button>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem onClick={() => handleFilter('all')}>All</MenuItem>
          <MenuItem onClick={() => handleFilter('in')}>Inbound</MenuItem>
          <MenuItem onClick={() => handleFilter('out')}>Outbound</MenuItem>
        </Menu>
      </Grid>
      <Grid item>
        <Button variant='outlined'
          startIcon={<Box color='success.dark'>
            <FontAwesomeIcon icon='fas fa-plus' />
          </Box>}
          onClick={() => setDialogOpen({ open: true, inbound: true })}
        >
          Inbound Data
        </Button>
      </Grid>
      <Grid item>
        <Button variant='outlined'
          startIcon={<Box color='error.dark'>
            <FontAwesomeIcon icon='fas fa-plus' />
          </Box>}
          onClick={() => setDialogOpen({ open: true })}
        >
          Outbound Data
        </Button>
      </Grid>
    </Grid>

    <DataTable
      loading={loading}
      PaginationProps={PaginationProps}
      columns={[
        {
          field: 'ServiceName',
          title: 'Service Name',
          render: row => <Grid container spacing={1} alignItems='center'>
            <Grid item>
              <DisplayAvatar up={row.ServiceType === 'DeltaUpload'} />
            </Grid>
            <Grid item xs>
              {row.ServiceName}
            </Grid>
          </Grid>
        }, {
          field: 'Entity',
          title: 'Data Model / Entity',
          render: row => <>
            <Typography>{row.DataModel}</Typography>
            <Typography fontWeight='fontWeightBold' color='secondary'>
              {row.Entity}
            </Typography>
          </>
        }, {
          field: 'Version',
          title: 'Version #'
        }, {
          field: 'Schedule.Type',
          title: 'Schedule'
        }, {
          title: "Actions",
          render: item => <IconButton onClick={() => setDialogOpen({ open: true, item })}>
            <FontAwesomeIcon icon="fal fa-edit" />
          </IconButton>
        }
      ]}
      data={data}
      expand={row => <SFTPExpanded data={row} />}
    />

    <DeltaDataDialog open={false} {...dialogOpen}
      onClose={() => setDialogOpen(false)}
    />
  </>
}

export default ServicesTab