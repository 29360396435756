import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Button, CardContent, Dialog, DialogActions, DialogContent, Grid } from '@mui/material'

import DATAMODELS from 'src/testdata/organization_models.json'
import Collapsible from './Collapsible'
import { FormProvider, useForm } from 'react-hook-form'
import { datamodel_permissions } from '../form-validation'
import { useTranslation } from 'react-i18next'
import { DialogTitle, SearchField } from 'src/components'



function PermissionDialog({ onClose = () => { }, ...props }) {
  const methods = useForm(datamodel_permissions)
  const { t } = useTranslation()

  const handleSearch = e => console.log("search for", e.target.value)

  const handleSubmit = () => {
    methods.handleSubmit(data => {
      const filtered = data.values.filter(dm =>
        dm.Entities?.filter(ent => ent.Permissions?.length > 0)?.length > 0
      )
      console.log("submission", filtered)
      methods.reset(datamodel_permissions.defaultValues)
      onClose()
    })()
  }

  return <FormProvider {...methods}>
    <Dialog fullWidth maxWidth='lg' {...props}>
      <DialogTitle onClose={onClose}>
        {t('organization.dialogTitles.permission')}
      </DialogTitle>
      <DialogContent dividers sx={{
        p: 0, display: 'flex', flexDirection: 'column', overflow: 'hidden'
      }}>
        <CardContent>
          <SearchField onChange={handleSearch} label='Search' />
        </CardContent>
        <PerfectScrollbar><CardContent>

          <Grid container spacing={1.5} direction='column' height='100%'>
            {DATAMODELS.map((dm, d) => <Grid item key={d}>
              <Collapsible item={dm} open={d === 0} index={d} />
            </Grid>)}


          </Grid>

        </CardContent></PerfectScrollbar>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("common.cancel")}</Button>
        <Button onClick={handleSubmit}
          autoFocus color="primary" variant='contained'
        >
          {t('common.save')}
        </Button>
      </DialogActions>
    </Dialog>
  </FormProvider>
}

export default PermissionDialog