import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Grid, IconButton, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { DataTable, SearchField, ViewEditIcon } from 'src/components'
import DeleteWarningDialog from 'src/components/DeleteWarningDialog'
import { API_GetDesignateOrganisationList } from 'src/constants/api'
import { useDataTableUtils } from 'src/hooks/useUtils'
import SubscriberDialog from './SubscriberDialog'

function SubscriberTab(props) {
  const { loading, data, getData, PaginationProps } = useDataTableUtils(API_GetDesignateOrganisationList)

  const [dialogOpen, setDialogOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)

  const handleDelete = () => {
    // TODO: delete action
    setDeleteOpen(false)
    getData()
  }

  return <Box p={2}>
    <Grid container spacing={1} alignItems="center">
      <Grid item xs>
        <SearchField />
      </Grid>
      <Grid item>
        <Button variant='outlined' onClick={() => setDialogOpen(true)}
          startIcon={<FontAwesomeIcon icon='far fa-plus' />}
        >
          Add Authorised Subscriber Organization
        </Button>
      </Grid>
    </Grid>

    <DataTable
      loading={loading}
      PaginationProps={PaginationProps}
      columns={[
        {
          field: 'OrganisationName',
          title: 'Organisation Name'
        }, {
          field: 'OrganisationAbbreviation',
          title: 'Organisation Abbreviation'
        }, {
          field: 'CreatedBy',
          title: 'Created By'
        }, {
          field: 'Created',
          title: 'CreatedDate',
          type: 'datetime'
        }, {
          field: 'LastUpdatedBy',
          title: 'Last Updated By'
        }, {
          field: 'LastUpdatedDate',
          title: 'Last Updated',
          type: 'datetime'
        }, {
          title: 'Actions',
          render: rowData => <>
            <Tooltip title='View/Edit'>
              <IconButton onClick={() => setDialogOpen(rowData)}>
                <ViewEditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Delete' onClick={() => setDeleteOpen(rowData)}>
              <IconButton>
                <FontAwesomeIcon icon='fal fa-trash-alt' fixedWidth />
              </IconButton>
            </Tooltip>
          </>
        }
      ]}
      data={data}
    />

    <DeleteWarningDialog title='Delete Authorised Subscriber Organization Configuration'
      open={Boolean(deleteOpen)}
      onClose={() => setDeleteOpen(false)}
      onConfirm={handleDelete}
    >
      The Selected Authorised Subscriber Configuration will be deleted.
      This action is <b>irreversible</b> and will affect all items related to this Authorised Subscriber Configuration.<br /><br />
      Are you sure you want to continue?
    </DeleteWarningDialog>
    <SubscriberDialog open={dialogOpen} onClose={() => setDialogOpen(false)} />
  </Box>
}

export default SubscriberTab