import React, { useContext, useEffect } from 'react'
import { Button, Dialog, DialogActions, DialogContent, Grid, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { DatePicker } from '@mui/x-date-pickers'

import { addOrg } from './form-validation'
import { useFieldUtils } from 'src/hooks/useUtils'
import { DateTimeControl, DialogTitle } from 'src/components'
import moment from 'moment'
import { DXPContext } from 'src/layouts/MainLayout'
import { enqueueSnackbar } from 'notistack'

function AddNewOrgDialog({ open, ...props }) {
  const { t } = useTranslation()
  const { register, handleSubmit, reset, formState: { errors }, control } = useForm({
    ...addOrg
  })
  const { errorState } = useFieldUtils({ errors })
  const { setLoadingBlock } = useContext(DXPContext)

  const submitForm = data => {
    // DEMO: disabling whole page on action
    setLoadingBlock(true)
    // do something
    console.log(data)
    let msg = 'Error saving info!'
    if (open === true)
      msg = `Created new organisation ${data.Name}.`
    else
      msg = `Saved edits for ${data.Name}.`
    setTimeout(() => {
      setLoadingBlock(false)
      enqueueSnackbar(msg, { variant: 'success' })
    }, 2000)


  }

  useEffect(() => {
    if (typeof open !== 'boolean')
      reset({
        ...open,
        EffectiveStartDate: moment(open.EffectiveStartDate),
        EffectiveEndDate: moment(open.EffectiveEndDate),
      })
    else if (open)
      reset({ ...addOrg.defaultValues })
  }, [open, reset])



  return <form onSubmit={handleSubmit(submitForm)}>
    <Dialog maxWidth="lg" {...props} open={Boolean(open)}>
      <DialogTitle onClose={props.onClose}>
        {t(`common.${open === true ? 'addNew' : 'edit'}Thing`, { thing: 'Organization' })}
      </DialogTitle>
      <DialogContent dividers><Grid container spacing={1} wrap='wrap'>
        <Grid item xs={12}>
          <Typography variant="h5">Organization Details</Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField {...register('Name')}
            label="Organization name"
            fullWidth required
            {...errorState('Name')}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField {...register('Abbreviation')}
            label="Abbreviation"
            fullWidth required
            {...errorState('Abbreviation')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField {...register('Description')}
            label="Description"
            fullWidth required
            multiline rows={3}
            {...errorState('Description')}
          />
        </Grid>

        <Grid item xs={12} md={6} mt={2}>
          <TextField {...register('RepresentativeName')}
            label="Representative name"
            fullWidth required
            {...errorState('RepresentativeName')}
          />
        </Grid>
        <Grid item xs={12} md={6} mt={2}>
          <TextField {...register('RepresentativeEmail')}
            label="Representative e-mail"
            fullWidth required type="email"
            {...errorState('RepresentativeEmail')}
          />
        </Grid>

        <Grid item xs={12} mt={3}>
          <Typography variant="h5">Service Agreement</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <DateTimeControl Component={DatePicker}
            control={control}
            name='EffectiveStartDate'
            label="Effective start date"
            slotProps={{
              textField: {
                required: true,
                fullWidth: true,
                readOnly: true,
                ...errorState('EffectiveStartDate')
              }
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DateTimeControl Component={DatePicker}
            control={control}
            name='EffectiveEndDate'
            label="Effective end date"
            slotProps={{
              textField: {
                required: true,
                fullWidth: true,
                readOnly: true,
                ...errorState('EffectiveEndDate')
              }
            }}
          />
        </Grid>
      </Grid></DialogContent>


      <DialogActions>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button onClick={handleSubmit(submitForm)} variant='contained'>
          {t(`common.${open === true ? 'add' : 'save'}`)}
        </Button>
      </DialogActions>
    </Dialog>
  </form>
}

export default AddNewOrgDialog