import { Button, Card, Dialog, DialogActions, DialogContent, FormLabel, Grid, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import Quill from 'src/components/Quill'
import { PERMISSIONS_LIST_2 } from 'src/constants'
import { newEntity } from './form-validation'
import { useFieldUtils } from 'src/hooks/useUtils'
import { DDContext } from '../MainView'
import { DialogTitle } from 'src/components'

function AddEntityDialog({ createMode, ...props }) {
  const methods = useForm(newEntity)
  const { register, formState: { errors }, reset } = methods
  const { errorState } = useFieldUtils({ errors })
  const { activeItem } = useContext(DDContext)

  const submitForm = data => console.log(data)

  useEffect(() => {
    reset(createMode ? newEntity.defaultValues : {
      ...newEntity.defaultValues,
      Name: activeItem.Entity
    })
    // eslint-disable-next-line
  }, [props.open, createMode])

  return <FormProvider {...methods}><form onSubmit={methods.handleSubmit(submitForm)}>
    <Dialog fullWidth maxWidth='md' {...props}>
      <DialogTitle onClose={props.onClose}>
        {createMode ? 'Add' : 'Edit'} Data Entity
        {createMode && ` to ${createMode?.DataModel || activeItem?.DataModel}`}
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={1}>
          <Grid item xs={12}><Typography variant='subtitle1'>
            Summary
          </Typography></Grid>
          <Grid item xs={12} sm={8}>
            <TextField {...register('Name')}
              label='Name'
              required fullWidth
              {...errorState('Name')}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField {...register('MaxRecord')}
              label='Max Records Returned'
              fullWidth
              {...errorState('MaxRecord')}
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel htmlFor='Description'>Description</FormLabel>
            <Quill
              name='Description'
              onChange={val => methods.setValue('Description', val)}
              sx={{
                height: 200
              }}
            />
          </Grid>

          <Grid item xs={12} mt={2}><Typography variant='subtitle1'>
            Endpoint Configuration
          </Typography></Grid>
          {PERMISSIONS_LIST_2.map(({ name, color }) => <Grid item xs={12} key={name} >
            <Card sx={{
              p: 2, mb: 2, backgroundColor: `${color}.50`
            }}><Grid container spacing={2} direction='column'>
                <Grid item>
                  <TextField {...register(`${name}.Short`)}
                    label={`${name.toUpperCase()} Short Description`}
                    fullWidth
                    {...errorState(`${name}.Short`)}
                  />
                </Grid>
                <Grid item>
                  <FormLabel htmlFor={`${name}.Long`}>
                    {name.toUpperCase()} Long Description
                  </FormLabel>
                  <Quill
                    name={`${name}.Long`}
                    onChange={val => methods.setValue(`${name}.Long`, val)}
                    sx={{
                      height: 200
                    }}
                  />
                </Grid>
              </Grid></Card>

          </Grid>)}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>
          Cancel
        </Button>
        <Button variant='contained' onClick={methods.handleSubmit(submitForm)}>
          {createMode ? 'Add' : 'Edit'}
        </Button>
      </DialogActions>
    </Dialog>
  </form></FormProvider>
}

export default AddEntityDialog