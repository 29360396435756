import { Button, Chip, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { DataTable, SearchField } from 'src/components'
import { ENV_COLOR } from 'src/constants'
import DataUpdateDialog from './DataUpdateDialog'
import { useDataTableUtils } from 'src/hooks/useUtils'

function NotificationTab(props) {
  const { loading, data, PaginationProps } = useDataTableUtils('/testdata/svc_notification.json')
  const [dialogOpen, setDialogOpen] = useState(false)

  const handleSearch = e => {
    console.log("search for:", e.target.value)
    // For example, getData(searchTerm)
  }

  return <>
    <Grid container spacing={1} alignItems='center' justifyContent='space-between' my={2}>
      <Grid item xs={12} md={4}>
        <SearchField onChange={handleSearch} label='Search' />
      </Grid>
      <Grid item>
        <Button variant='outlined'
          onClick={() => setDialogOpen({ open: true })}
        >
          Data Update Assignment
        </Button>
      </Grid>
    </Grid>

    <DataTable
      loading={loading}
      PaginationProps={PaginationProps}
      columns={[
        {
          field: 'Entity',
          title: 'Data Model / Entity',
          render: row => <>
            <Typography>{row.DataModel}</Typography>
            <Typography fontWeight='fontWeightBold' color='secondary'>
              {row.Entity}
            </Typography>
          </>
        }, {
          field: 'Version',
          title: 'Version #'
        }, {
          field: 'NotificationURL',
          title: 'Notification URL'
        }, {
          field: 'Environment',
          title: 'Environment',
          render: row => <Chip label={row.Environment} size='small'
            sx={{ backgroundColor: ENV_COLOR[row.Environment] + '.light' }}
          />
        }
      ]}
      data={data}
    />
    <DataUpdateDialog open={false} {...dialogOpen}
      onClose={() => setDialogOpen(false)}
    />
  </>
}

export default NotificationTab