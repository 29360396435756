import { Card, Checkbox, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Typography } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { DDContext } from '../../MainView'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

const AttributeList = ({ title, list, name, ...props }) => {
  const { getValues, setValue } = useFormContext()

  const toggleButton = (attr, a) => {
    const key = `${name}.${a}`
    setValue(key, !getValues(key) ? attr : false)
  }

  const handleCheck = (attr, a, field) => {
    setValue(`${name}.${a}`, !field.value ? attr : false)
  }

  return <Card sx={{ height: '100%', backgroundColor: 'primary.50' }}>
    <List disablePadding dense>
      <ListSubheader sx={theme => ({
        backgroundColor: 'primary.100',
        fontSize: theme.typography.body1.fontSize,
        color: 'text.primary',
        fontWeight: 'fontWeightBold'
      })}
      >
        {title}
      </ListSubheader>
      {list.map((attr, a) => {
        const labelId = `entity-${title}-attr-${attr.Id}`
        return <ListItem key={labelId} disablePadding>
          <ListItemButton onClick={() => toggleButton(attr, a)}>
            <ListItemIcon>
              <Controller
                name={`${name}.${a}`}
                render={({ field }) => (
                  <Checkbox
                    checked={!!field.value}
                    onChange={e => handleCheck(attr, a, field)}
                    edge="start"
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                )}
              />
            </ListItemIcon>
            <ListItemText id={labelId} primary={attr.Attribute}
              primaryTypographyProps={{
                variant: 'body2'
              }}
            />
          </ListItemButton>
        </ListItem>
      })}
    </List></Card>
}

function HasATable(props) {
  const { activeItem } = useContext(DDContext)
  const { resetField } = useFormContext()
  const [rshipType, relatedEntity] = useWatch({
    name: ['RelationshipType', 'relatedEntityObj']
  })

  useEffect(() => {
    if (rshipType === 'Has A')
      resetField('HasA')
    // eslint-disable-next-line
  }, [rshipType, relatedEntity])

  return <Grid container spacing={1} mt={2}>
    <Grid item xs={12} md={5}>
      <AttributeList title={activeItem?.Entity}
        list={activeItem?.Attributes}
        name='HasA.Attr'
      />
    </Grid>
    <Grid item xs={12} md={2} textAlign='center' alignSelf='center'>
      Has A
    </Grid>
    <Grid item xs={12} md={5} alignSelf={relatedEntity ? 'start' : 'center'}>
      {relatedEntity ? <AttributeList
        title={relatedEntity?.Entity}
        list={relatedEntity?.Attributes}
        name='HasA.Related'
      /> : <Typography color='text.secondary' fontStyle='italic'>
        Please select a related entity.
      </Typography>}
    </Grid>
  </Grid>
}

export default HasATable