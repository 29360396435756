import React, { useState } from 'react'
import { Avatar, Box, Button, Chip, Dialog, DialogActions, Grid, Tooltip, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DataTable, DialogTitle, SearchField } from 'src/components'
import { PERMISSIONS_LIST } from 'src/constants'

import OwnershipDialog from './OwnershipDialog'
import PermissionDialog from './PermissionDialog'
import { useDataTableUtils } from 'src/hooks/useUtils'

const DIALOGS = {
  OWNERSHIP: "ownership",
  PERMISSIONS: "permission"
}

function PermissionsPage(props) {
  const { t } = useTranslation()
  const { loading, data, PaginationProps } = useDataTableUtils('/testdata/organization_permissions.json')
  const [dialog, setDialog] = useState(null)

  const handleCloseDialog = () => setDialog(null)


  return <Box p={2}>
    <Grid container spacing={1} alignItems="center">
      <Grid item xs>
        <SearchField />
      </Grid>
      <Grid item>
        <Button variant="outlined" onClick={() => setDialog(DIALOGS.OWNERSHIP)}>Assign Ownership</Button>
      </Grid>
      <Grid item>
        <Button variant="outlined" onClick={() => setDialog(DIALOGS.PERMISSIONS)}>Assign Permission</Button>
      </Grid>
      <Grid item>
        <Button variant="outlined">Assign Permitted Attribute</Button>
      </Grid>
    </Grid>
    <DataTable
      loading={loading}
      PaginationProps={PaginationProps}
      columns={[
        {
          field: 'entity',
          title: 'Data Model / Entity',
          render: row => <>
            <Typography>{row.dataModel}Model</Typography>
            <Typography fontWeight='fontWeightBold' color='secondary'>
              {row.entity}Entity
            </Typography>
          </>
        }, {
          field: 'version',
          title: 'Version'
        }, {
          title: 'Attributes',
          sort: false,
          render: row => <Grid container spacing={1} direction='column'>
            {row.attributes.map((attr, a) => <Grid item key={a}>
              <Tooltip title={attr.items.join(', ')}>
                <Chip label={attr.name} size='small'
                  avatar={<Avatar sx={{
                    backgroundColor: 'secondary.main'
                  }}>
                    <Box component="span" color="secondary.contrastText">
                      {attr.items.length}
                    </Box>
                  </Avatar>}
                  clickable
                />
              </Tooltip>
            </Grid>)}
          </Grid>
        }, {
          title: 'Permissions',
          sort: false,
          render: row => <Grid container spacing={1} direction='column'>
            {row.attributes.map((attr, a) => <Grid item key={a}>
              <Grid container spacing={1}>
                {PERMISSIONS_LIST.map((perm, p) => <Grid item key={p}>
                  <Chip label={perm.name} size='small'
                    disabled={!attr.permissions.includes(perm.name)}
                    sx={{ backgroundColor: perm.color + '.light' }}
                  />
                </Grid>)}
              </Grid>

            </Grid>)}
          </Grid>
        }
      ]}
      data={data}
    />

    <Dialog open={dialog === DIALOGS.OWNERSHIP}
      onClose={handleCloseDialog}
      fullWidth maxWidth='xl'
    >
      <DialogTitle onClose={handleCloseDialog}>
        {t('organization.dialogTitles.ownership')}
      </DialogTitle>
      <OwnershipDialog />
      <DialogActions>
        <Button onClick={handleCloseDialog}>{t("common.cancel")}</Button>
        <Button onClick={handleCloseDialog}
          autoFocus color="primary" variant='contained'
        >
          {t('common.add')}
        </Button>
      </DialogActions>
    </Dialog>
    <PermissionDialog open={dialog === DIALOGS.PERMISSIONS}
      onClose={handleCloseDialog}
    />
  </Box>
}

export default PermissionsPage