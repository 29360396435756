import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'

const DATA = [
  ['GET', 'Retrieve data entity record', 'This operation retrieves records in the data entity based on the filter criteria'],
  ['POST', 'Post the data to entity record', '	This operation adds a new record to the data entity.'],
  ['PUT', 'Upload data entity record', 'This operation updates the details of the given data entity record.'],
  ['DELETE', 'Delete data entity record', 'This operation performs a soft delete of the record details as specified by the filter criteria.'],
]

function EndpointPage(props) {
  return <Box p={2}>
    <Table>
      <TableHead><TableRow>
        <TableCell>Endpoint</TableCell>
        <TableCell>Short Description</TableCell>
        <TableCell>Long Description</TableCell>
      </TableRow></TableHead>
      <TableBody>
        {DATA.map(([ep, short, long], i) => <TableRow key={i}>
          <TableCell><Typography fontWeight="fontWeightBold" color="secondary">
            {ep}
          </Typography></TableCell>
          <TableCell>{short}</TableCell>
          <TableCell>{long}</TableCell>
        </TableRow>)}
      </TableBody>
    </Table>
  </Box>
}

export default EndpointPage