import { Button, Dialog, DialogActions, DialogContent, FormLabel, Grid, TextField } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import Quill from 'src/components/Quill'
import { useFieldUtils } from 'src/hooks/useUtils'
import { DDContext } from './MainView'
import { dataModel } from './form-validation'
import { DialogTitle } from 'src/components'

function ModelDialog({ createMode, ...props }) {
  const methods = useForm(dataModel)
  const { register, formState: { errors }, reset } = methods
  const { errorState } = useFieldUtils({ errors })
  const ctx = useContext(DDContext) || {}
  const { activeItem } = ctx

  const submitForm = data => console.log(data)

  useEffect(() => {
    reset(createMode ? dataModel.defaultValues : activeItem)
    // eslint-disable-next-line
  }, [props.open, createMode])

  return <FormProvider {...methods}><form onSubmit={methods.handleSubmit(submitForm)}>
    <Dialog fullWidth maxWidth='md' {...props}>
      <DialogTitle onClose={props.onClose}>
        {createMode ? 'Add' : 'Edit'} Data Model
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField {...register('DataModel')}
              label='Name'
              required fullWidth
              {...errorState('DataModel')}
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel htmlFor='Description'>Description</FormLabel>
            <Quill
              name='Description'
              onChange={val => methods.setValue('Description', val)}
              sx={{
                height: 200
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel htmlFor='Terms'>Terms of Service</FormLabel>
            <Quill
              name='Terms'
              onChange={val => methods.setValue('Terms', val)}
              sx={{
                height: 600
              }}
            />
          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>
          Cancel
        </Button>
        <Button variant='contained' onClick={methods.handleSubmit(submitForm)}>
          {createMode ? 'Add' : 'Edit'}
        </Button>
      </DialogActions>
    </Dialog>
  </form></FormProvider>
}

export default ModelDialog