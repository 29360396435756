import React from 'react'
import SummaryPage from './SummaryPage'
import EndpointPage from './EndpointPage'
import RelationshipPage from './RelationshipPage'
import AttributesPage from './AttributesPage'
import HistoryPage from './HistoryPage'

export const ENTITY_PAGES = [
  <AttributesPage />,
  <SummaryPage />,
  <EndpointPage />,
  <RelationshipPage />,
  <HistoryPage />
]