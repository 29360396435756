import { useEffect, useState } from "react"
import { useTestPaginator } from "./useTestHooks"
import axios from "axios"
import { useFormContext } from "react-hook-form"
import { getFieldKey } from "src/constants/utils"

export const useFieldUtils = ({ errors, setValue, watch }) => ({
  errorState: name => errors ? ({
    helperText: getFieldKey(errors, name)?.message,
    error: Boolean(getFieldKey(errors, name))
  }) : {},
  registerRadio: name => ({
    name,
    onChange: e => setValue(name, e.target.value),
    value: watch(name) || ''
  }),
  displayField: (readonly, InputProps = {}) => (readonly ? {
    InputProps: {
      ...InputProps,
      readOnly: true,
      variant: 'display'
    }
  } : { InputProps })
})

const MAX_ITEMS = Math.floor(Math.random() * 255)

export const useDataTableUtils = (fetchURL, fetchOptions, postFetch, maxItems) => {
  const [pageNum, setPageNum] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [pageInfo, setPageInfo] = useState({})

  const { TEST_paginate } = useTestPaginator(maxItems || MAX_ITEMS, 1000)

  const getData = () => {
    setLoading(true)
    axios(fetchURL, fetchOptions)
      .then(res => TEST_paginate(res, itemsPerPage, pageNum - 1))
      .then(res => {
        let { data: resData, ...resPage } = res.data
        if (postFetch)
          resData = postFetch(resData)
        setData(resData)
        setPageInfo(resPage)
      }).finally(() => setLoading(false))
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [pageNum, itemsPerPage])

  return ({
    pageNum, setPageNum,
    itemsPerPage, setItemsPerPage,
    data, pageInfo, getData,
    loading,
    PaginationProps: {
      count: pageInfo.totalPages,
      page: pageNum,
      onPageChange: (e, page) => setPageNum(page),
      onPerChange: per => setItemsPerPage(per)
    }
  })
}

export const useArrayTableSearch = (fieldName, searchField = 'Name', formCtx) => {
  const { getValues, setValue } = useFormContext() || formCtx

  const handleSearch = e => {
    let value = e.target.value.toUpperCase()
    let all = getValues(fieldName)
    if (value)
      setValue(fieldName, all.map(x => ({
        ...x,
        hidden: !getFieldKey(x, searchField).toUpperCase().includes(value)
      })))
    else
      setValue(fieldName, all.map(x => ({ ...x, hidden: false })))
  }

  return handleSearch
}