import { Box, Card, CardContent, Chip, DialogContent, Divider, Grid, List, ListItem, Typography } from '@mui/material'
import React, { useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { SearchField } from 'src/components'
import TreeView from 'src/components/TreeView'

import ATTRS_LIST from 'src/testdata/organization_models'

const KEYNAMES = ['DataModel', 'Entity', 'Attribute']
const CHILDNAMES = ['Entities', 'Attributes']

const markParentsChecked = (state, data, parentList) => {
  let { item, index } = parentList.pop() // get oldest parent
  const key = item.Attributes ? 'Attributes' : 'Entities'

  if (item[key]) // does item have children?
    // check if any other children marked
    item.checked = state || item[key].filter(i => i.checked).length > 0
  else // mark current item as `state`
    item.checked = state
  // replace item on `data` list
  data[index] = item
  // pop next parent
  if (parentList.length)
    markParentsChecked(state, item[key], parentList)
}

const markChildrenChecked = (state, item) => {
  if (!item) return;
  const key = item.Attributes ? 'Attributes' : 'Entities'
  item[key]?.forEach(child => {
    child.checked = state
    markChildrenChecked(state, child)
  })
}

function OwnershipDialog(props) {
  const [data, setData] = useState(ATTRS_LIST)

  const handleCheck = (e, item, index, parents) => {
    parents.splice(0, 0, { item, index })
    const state = e.target.checked
    let copy = [...data]
    // check/uncheck all descendants
    markChildrenChecked(state, item)

    markParentsChecked(state, copy, [...parents])
    setData(copy)
  }

  const handleUncheck = (attr, ent, model) => {
    // TODO: remove from `data` checked list? use item IDs better - testdata doesn't have IDs
    let copy = [...data]
    copy.find(dm => dm.Id === model.Id).Entities
      .find(en => en.Id === ent.Id).Attributes
      .find(att => att.Id === attr.Id).checked = false
    setData(copy)
  }

  return <DialogContent dividers sx={{ p: 0, display: 'flex' }}>
    <Grid container>
      <Grid item height="100%">
        <Card square sx={{ backgroundColor: 'primary.200', height: '100%', overflow: 'auto' }} elevation={0}>
          <PerfectScrollbar>
            <CardContent><Grid container spacing={2} direction='column'>
              <Grid item><SearchField /></Grid>
              <Grid item><Card square elevation={0}>
                <TreeView items={data}
                  childKeys={CHILDNAMES}
                  ListProps={{ dense: true }}
                  onCheck={handleCheck} openOnCheck
                  renderItem={(item, level) => level === 2 ? <>
                    <Box component="span" color="primary.main">{item[KEYNAMES[level]]}</Box>
                    {item.Required && <Box component="span" color="error.main">*</Box>}
                  </> : <Grid container spacing={1} alignItems='center'>
                    <Grid item>{item[KEYNAMES[level]]}</Grid>
                    <Grid item sx={{ fontWeight: 'fontWeightBold' }} xs>
                      ({item[CHILDNAMES[level]].length || '0'})
                    </Grid>
                    {item.Version && <Grid item>
                      <Chip label={'v' + item.Version}
                        size="mini"
                        sx={{ backgroundColor: 'grey.500', color: 'common.white' }}
                      />
                    </Grid>}
                  </Grid>}
                />
              </Card></Grid>
            </Grid></CardContent>
          </PerfectScrollbar>
        </Card>
      </Grid>
      <Grid item xs height='100%'><Card sx={{ backgroundColor: 'primary.100', height: '100%' }} elevation={0}>
        <PerfectScrollbar>
          <CardContent><Grid container direction='column' spacing={2}>
            <Grid item><Typography variant="h5">
              Selected Data Ownership Assignment
            </Typography></Grid>
            <Grid item xs><List disablePadding>
              {data.filter(d => d.checked).map((model, m, mArr) => <ListItem key={model.Id}>
                <Card sx={{ width: '100%' }}>
                  <CardContent><Grid container spacing={.5} alignItems='center'>
                    <Grid item fontWeight="fontWeightBold" color="primary.main">{model.DataModel}</Grid>
                    <Grid item><Chip label={'v' + model.Version} size="mini"
                      sx={{ backgroundColor: 'grey.500', color: 'common.white' }}
                    /></Grid>
                    <Grid item>
                      ({model.Entities?.filter(c => c.checked).length}/{model.Entities?.length})
                    </Grid>
                  </Grid></CardContent>
                  <Divider />
                  <CardContent>
                    <Grid container spacing={2} direction='column'>
                      {model.Entities?.filter(c => c.checked).map(entity => <React.Fragment key={entity.Id}>
                        <Grid item>
                          <Typography component="p" mb={1}>
                            {entity.Entity}&nbsp;
                            ({entity.Attributes?.filter(c => c.checked).length}/{entity.Attributes?.length})
                          </Typography>
                          <Grid container spacing={1}>
                            {entity.Attributes?.filter(a => a.checked).map(attr => <Grid item key={attr.Id}><Chip
                              label={<Box component="span" color={attr.Required ? "error.dark" : 'text.primary'}>
                                {attr.Attribute}
                                {attr.Required && '*'}
                              </Box>} size="small"
                              sx={{ backgroundColor: 'secondary.100' }}
                              onDelete={() => handleUncheck(attr, entity, model)}
                            /></Grid>)}

                          </Grid>
                        </Grid>

                        {mArr.length - 1 > m && <Grid item><Divider /></Grid>}
                      </React.Fragment>)}
                    </Grid>
                  </CardContent>
                </Card>
              </ListItem>)}
            </List></Grid>
          </Grid></CardContent>
        </PerfectScrollbar>
      </Card></Grid>
    </Grid>
  </DialogContent>
}

export default OwnershipDialog