import {
  Alert,
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";

import { DialogTitle } from "src/components";
import LogsTable from "./LogsTable";
import PerfectScrollbar from "react-perfect-scrollbar";
import React from "react";

function JobDialog({ data, onClose = () => {}, ...props }) {
  return (
    <Dialog fullWidth maxWidth="lg" {...props}>
      <DialogTitle onClose={onClose}>Data Upload Job Details</DialogTitle>
      <PerfectScrollbar>
        <DialogContent dividers>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Typography variant="h5" mb={1}>
                  Details
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography> Service Number </Typography>
                    <Typography variant="subtitle1">
                      {data.SftpServiceNumber}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography> Version Number </Typography>
                    <Typography variant="subtitle1">
                      {data.VersionNumber}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography> Organisation Name </Typography>
                    <Typography variant="subtitle1">
                      {data.OrganisationName}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography> Date Period </Typography>
                    <Typography variant="subtitle1">
                      {data.EffectiveStartDateStr} - {data.EffectiveEndDateStr}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography> Data Model </Typography>
                    <Typography variant="subtitle1">
                      {data.DataModelId}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography> Processed File </Typography>
                    <Typography variant="subtitle1">---</Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography> Data Entity </Typography>
                    <Typography variant="subtitle1">Intern</Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography> Schedule Date </Typography>
                    <Typography variant="subtitle1">
                      {data.ScheduleDateStr}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography> Number of Records </Typography>
                    <Typography variant="subtitle1">
                      {data.NumberOfRecords}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography>Expiry Date </Typography>
                    <Typography variant="subtitle1">
                      {data.ExpirationDate}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography> Uploaded Files </Typography>
                    <Typography variant="subtitle1">
                      {data.TotalUploadedFiles}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography> Completed Files </Typography>
                    <Typography variant="subtitle1">
                      {data.TotalCompletedFiles}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography> Error Files </Typography>
                    <Typography variant="subtitle1">
                      {data.TotalErrorFiles}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Alert severity="info" sx={{ mt: 1 }}>
                      There are no processed files
                    </Alert>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="h5" mb={1}>
                      Logs
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <LogsTable />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </DialogContent>
      </PerfectScrollbar>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default JobDialog;
