import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Card, CardActionArea, Checkbox, Chip, Collapse, FormControlLabel, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"

import { PERMISSIONS_LIST } from "src/constants"

function Permissions({ index, ei, ent, recalculate }) {
  const { watch, getValues, setValue } = useFormContext()
  const watchPermsList = watch(`values.${index}.Entities.${ei}.Permissions`)

  useEffect(() => recalculate(), [watchPermsList, recalculate])

  const handleCheck = (e, permission) => {
    let curr = getValues(`values.${index}.Entities.${ei}.Permissions`)
    const val = e.target.checked
    setValue(`values.${index}.Entities.${ei}.Permissions`,
      val ? [...curr, permission] : curr.filter(x => x !== permission)
    )
  }

  const handleEntity = e => {
    const curr = watchPermsList.length
    setValue(`values.${index}.Entities.${ei}.Permissions`,
      curr < PERMISSIONS_LIST.length ? PERMISSIONS_LIST.map(p => p.name) : []
    )
  }

  return <>
    <TableCell><FormControlLabel label={ent.Entity} disableTypography
      control={<Checkbox
        checked={Boolean(watchPermsList?.length)}
      />}
      onChange={handleEntity}
    /></TableCell>
    {PERMISSIONS_LIST.map(({ name }) => <TableCell key={name}>
      <Checkbox value={name}
        checked={watchPermsList?.includes(name) || false}
        onChange={e => handleCheck(e, name)}
      />
    </TableCell>)}
  </>
}

function Collapsible({ item, index, open, ...props }) {
  const { setValue, getValues } = useFormContext()
  const [isOpen, setOpen] = useState(open)
  const [indeterminate, setIndeterminate] = useState(false)

  const handleSelectAll = e => {
    // NOTE: done in forEach so that `watch` triggers.
    getValues(`values.${index}.Entities`).forEach((ent, ei) => {
      setValue(`values.${index}.Entities.${ei}.Permissions`,
        e.target.checked ? PERMISSIONS_LIST.map(p => p.name) : []
      )
    })
  }

  const recalculateIndeterminate = () => {
    const total = getValues(`values.${index}.Entities`)?.reduce((acc, curr) => acc + (curr.Permissions?.length || 0), 0) || 0
    setIndeterminate(total < (item.Entities.length * PERMISSIONS_LIST.length)
      && total !== 0)
  }

  useEffect(() => {
    setValue(`values.${index}.DataModel`, item.DataModel)
    setValue(`values.${index}.Version`, item.Version)
    setValue(`values.${index}.Entities`, item.Entities.map(Entity => ({
      Entity, Permissions: []
    })))
    recalculateIndeterminate()
    // eslint-disable-next-line
  }, [index, item, setValue])

  return <Card>
    <CardActionArea onClick={() => setOpen(!isOpen)}>
      <Grid container px={2} py={1} spacing={1}
        alignItems='center' justifyContent='space-between'
      >
        <Grid item><Typography>{item.DataModel}</Typography></Grid>
        <Grid item xs><Chip label={'v' + item.Version}
          size="mini"
          sx={{ backgroundColor: 'grey.500', color: 'common.white' }}
        /></Grid>
        <Grid item>
          <Box sx={theme => ({
            padding: 1.5,
            marginRight: -1.5,
            transition: theme.transitions.create(),
            [isOpen && 'transform']: 'rotate(180deg)'
          })}>
            <FontAwesomeIcon icon='fal fa-chevron-down' fixedWidth size='xl' />
          </Box>
        </Grid>
      </Grid>
    </CardActionArea>
    <Collapse in={isOpen} timeout="auto" unmountOnExit>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell><FormControlLabel control={<Checkbox
              indeterminate={indeterminate}
            />}
              label="Select all"
              onClick={handleSelectAll}
            /></TableCell>
            <TableCell>Create</TableCell>
            <TableCell>Read</TableCell>
            <TableCell>Update</TableCell>
            <TableCell>Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {item.Entities?.map((ent, ei) => <TableRow key={ei}>
            <Permissions {...{ index, ei, ent }}
              recalculate={recalculateIndeterminate}
            />
          </TableRow>)}
        </TableBody>
      </Table>

    </Collapse>
  </Card>
}

export default Collapsible