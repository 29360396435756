import {
  Box,
  TextField,
  InputAdornment,
  Chip,
  Button,
  Grid,
  Stack,
} from "@mui/material";
import React, { createContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PerfectScrollbar from 'react-perfect-scrollbar'

import Header from "src/layouts/components/Header";
import { DataTable } from "src/components";
import { useDataTableUtils } from "src/hooks/useUtils";
import ActionDialog from "./ActionDialog";

export const InboxContext = createContext(null);

function InboxMainView(props) {
  const [activeItem, setActiveItem] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const { loading, data, getData, PaginationProps } = useDataTableUtils(
    "/testdata/inbox/17_GetTaskInbox.json"
  );

  const columns = [
    {
      field: "Id",
      title: "No",
    },
    {
      field: "ReferenceNo",
      title: "Reference Number",
    },
    {
      field: "FormType",
      title: "Form Type",
    },
    {
      field: "SubmissionByOrganisation",
      title: "Organisation Name.",
    },
    {
      field: "Status",
      title: "Status Code",
      render: (row) => (
        <>
          <Chip
            label={row.FormStatus}
            size="small"
            disabled={false}
            sx={{
              backgroundColor:
                row.FormStatus === "Pending Admin Approval"
                  ? "#b26500"
                  : "error.main",
              color: "common.white",
            }}
          />
        </>
      ),
    },
    {
      field: "SubmissionDate",
      title: "Submission Date",
    },
    {
      field: "SubmissionByEmail",
      title: "Email",
    },
    {
      field: "actions",
      title: "Actions",
      render: (row) => (
        <>
          <Button
            variant="outlined"
            onClick={() => {
              setOpenDialog(true);
              setActiveItem(row);
            }}
          >
            Approve/Reject
          </Button>
        </>
      ),
    },
  ];

  const handleSearch = (e) => {
    getData({ searchTerm: e.target.value });
  };
  return (
    <InboxContext.Provider
      value={{
        activeItem,
        setActiveItem,
      }}
    >
      <Stack height="100%">
        <Header title="Inbox" />
        <PerfectScrollbar><Box p={2}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs>
              <TextField
                placeholder="Search by name"
                size="small"
                onChange={handleSearch}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <FontAwesomeIcon icon="far fa-search" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <DataTable
            loading={loading}
            PaginationProps={PaginationProps}
            columns={columns}
            data={data}
          />
          {activeItem && (
            <ActionDialog
              open={openDialog}
              onClose={() => setOpenDialog(false)}
            />
          )}
        </Box></PerfectScrollbar>
      </Stack>
    </InboxContext.Provider>
  );
}

export default InboxMainView;
