import React, { useContext, useState } from 'react'
import { DDContext } from './MainView'
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, Grid, Step, StepLabel, Stepper, Typography } from '@mui/material'
import { DialogTitle } from 'src/components'

const STEPS = [
  "Publish to Staging",
  "Publish to Production/Sandbox",
  "Complete"
]

function PublishDialog(props) {
  const { activeItem } = useContext(DDContext)
  const [activeStep] = useState(1)

  return <Dialog fullWidth maxWidth='md' {...props}>
    <DialogTitle onClose={props.onClose}>
      Publish {activeItem.DataModel} Data Model
    </DialogTitle>
    <DialogContent dividers>
      <Stepper activeStep={activeStep} alternativeLabel>
        {STEPS.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Grid container spacing={2} mt={2}>
        <Grid item xs display='flex'><Box textAlign='left' mx='auto'>
          <Typography variant='caption' color='text.secondary'>
            Latest Defined Version
          </Typography>
          <Typography fontWeight='fontWeightBold'>
            25
          </Typography>
        </Box></Grid>
        <Grid item xs display='flex'><Box textAlign='left' mx='auto'>
          <Typography variant='caption' color='text.secondary'>
            Environment
          </Typography>
          <Typography fontWeight='fontWeightBold'>
            Staging
          </Typography>
        </Box></Grid>

        <Grid item xs={12}>
          <Alert severity='warning' sx={{ mt: 1 }}>
            You are about to publish a new version of this data model. Once published, you will be able to verify the new data model version in DXP Staging.
          </Alert>
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.onClose}>
        Cancel
      </Button>
      <Button variant='contained'>
        Proceed
      </Button>
    </DialogActions>
  </Dialog>
}

export default PublishDialog