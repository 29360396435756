import { Box, Button, Card, Chip, Dialog, DialogActions, DialogContent, Divider, Grid, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { DataTable, DialogTitle, SearchField } from 'src/components'
import { DATEFMT_SIMPLE_DATETIME } from 'src/constants'
import { useDataTableUtils } from 'src/hooks/useUtils'

function AttrHistoryDialog({ item, onClose, ...props }) {
  const { loading, data, PaginationProps } = useDataTableUtils('/testdata/entity_history.json')

  return <Dialog open={Boolean(item)} fullWidth maxWidth="lg" {...props}>
    <DialogTitle onClose={onClose}>History</DialogTitle>
    <DialogContent>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs>
          <SearchField />
        </Grid>
      </Grid>
      <DataTable
        loading={loading}
        PaginationProps={PaginationProps}
        columns={[
          {
            field: 'Active',
            title: 'Status',
            render: item => <Chip
              label={item.Active ? 'Active' : 'Inactive'}
              color={item.Active ? 'success' : 'default'}
              variant='outlined'
            />
          }, {
            field: 'MaxRecord',
            title: 'Max Record Returned'
          }, {
            field: 'ModifiedBy',
            title: 'Last Updated By'
          }, {
            field: 'Modified',
            title: 'Last Updated',
            type: 'datetime'
          }
        ]}
        data={data}
        expand={rowData => <Card>
          <Grid container spacing={2} columns={2} m={.5} mb={2}>
            {["GET", "POST", "DELETE"].map(k => <React.Fragment key={k}>
              <Grid item xs={2} md={1}>
                <Typography variant='subtitle1'>
                  {k} Short Description
                </Typography>
                Deserunt aliqua incididunt occaecat excepteur sunt et est voluptate aliquip.
              </Grid>
              <Grid item xs={2} md={1}>
                <Typography variant='subtitle1'>
                  {k} Long Description
                </Typography>
                Deserunt aliqua incididunt occaecat excepteur sunt et est voluptate aliquip. Incididunt nulla exercitation nisi Lorem dolor id enim nostrud sunt eu.
              </Grid>
            </React.Fragment>)}
          </Grid>

          <Divider />
          <Box m={2}>
            <Typography variant='subtitle1'>
              Created by
            </Typography>
            {rowData.CreatedBy} at&nbsp;
            {moment(rowData.Created).format(DATEFMT_SIMPLE_DATETIME)}
          </Box>
        </Card>}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Close</Button>
    </DialogActions>
  </Dialog>
}

AttrHistoryDialog.defaultProps = {
  onClose: () => null
}

export default AttrHistoryDialog