const HOME = '/testdata/'

export const API_GetDesignateDataModelList = HOME + '1_GetDesignateDataModelList.json'
export const API_GetDataModelDropDownList = HOME + '2_GetDataModelDropDownList.json'
export const API_GetDesignateOrganisationList = HOME + '5_GetDesignateOrganisationList.json'

// Dashboard APIs
export const API_GetApplicationSummary = HOME + 'dashboard/13_GetApplicationSummary.json'
export const API_GetOrganisationSummary = HOME + 'dashboard/14_GetOrganisationSummary.json'
export const API_GetDataDefinitionSummary = HOME + 'dashboard/15_GetDataDefinitionSummary.json'
export const API_GetAllPublishersAndSubscribers = HOME + 'dashboard/16_GetAllPublishersAndSubscribers.json'

// Inbox/Enquiry APIs
export const API_GetTaskInbox = HOME + 'inbox/17_GetTaskInbox.json'
export const API_ApplicationProcess = HOME + 'inbox/18_ApplicationProcess.json'
export const API_GetWorkflowFormHistoryGrid = HOME + 'inbox/19_GetWorkflowFormHistoryGrid.json'
export const API_GetSearchResults = HOME + 'inbox/SearchResults-gen.json'