import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Card, CardActions, CardContent, CardHeader, CardMedia, Divider, List, ListItem, ListItemIcon, ListItemText, Typography, useTheme } from '@mui/material'
import { PieChart } from '@mui/x-charts/PieChart'
import axios from 'axios'
import React, { useEffect, useMemo, useState } from 'react'
import { PieCenterLabel } from 'src/components'


const size = {
  height: 150
}

function ChartCard({ url, dataFn, title, icon, dataKey, footer, ...props }) {
  const [data, setData] = useState([])
  const { typography } = useTheme()

  const displayData = useMemo(
    () => (dataKey ? data[dataKey] : data) || [], [data, dataKey]
  )


  const getData = () => axios(url)
    .then(res => setData(dataFn ? dataFn(res) : res.data))

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [])

  return <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
    <CardHeader
      avatar={<FontAwesomeIcon icon={`far fa-${icon}`} size='xl' fixedWidth />}
      title={title}
      titleTypographyProps={{
        variant: 'subtitle1'
      }}
      sx={{
        backgroundColor: 'primary.100',
      }}
    />
    <Box display='flex' alignItems='center' flexGrow={1}>
      <CardMedia sx={{ width: size.height }}>
        <PieChart
          series={[
            {
              startAngle: 40,
              endAngle: 400,
              data: displayData,
              innerRadius: size.height / 3,
              outerRadius: size.height / 2.2,
              paddingAngle: 1,
              cornerRadius: 4,
              highlightScope: { faded: 'global', highlighted: 'item' },
              faded: { additionalRadius: -4 }
            }
          ]}
          {...size}
          slotProps={{ legend: { hidden: true } }}
          margin={{ left: size.height / 2 }}
        >
          <PieCenterLabel lines={[
            'Total',
            {
              style: { fontWeight: typography.fontWeightBold },
              text: displayData.reduce((acc, cur) => acc + cur.value, 0)
            }
          ]} />
        </PieChart>
      </CardMedia>

      <CardContent sx={{ flexGrow: 1 }}>
        <List dense disablePadding>
          {displayData.map(({ label, value, index, color }) => <ListItem
            key={index} disableGutters
            divider secondaryAction={<Typography>{value}</Typography>}
          >
            <ListItemIcon sx={{ color, minWidth: 32 }}>
              <FontAwesomeIcon icon='fas fa-circle' fixedWidth />
            </ListItemIcon>
            <ListItemText>
              {label}
            </ListItemText>

          </ListItem>)}
        </List>

      </CardContent>
    </Box>
    {Boolean(footer) && <>
      <Divider />
      <CardActions>{footer(data)}</CardActions>
    </>}
  </Card>
}

export default ChartCard