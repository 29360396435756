import { Box, styled } from '@mui/material'
import React from 'react'
import ReactQuill from 'react-quill'

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex', width: '100%',
  '& .ql': {
    '&-toolbar': {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
    },
    '&-container': {
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,

    },
    '&-toolbar, &-container': {
      borderColor: theme.palette.divider,
      transition: theme.transitions.create()
    }

  },
  '& .quill': {
    width: '100%',
    display: 'flex', flexDirection: 'column',
    '&:hover > *': {
      borderColor: theme.palette.text.primary
    }
  }
}))

function Quill({ sx, ...props }) {

  return <Wrapper sx={sx}><ReactQuill {...props} /></Wrapper>
}

export default Quill