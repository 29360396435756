import { yupResolver } from '@hookform/resolvers/yup'
import { ATTR_TYPE, CARDINALITY_TYPE, CLASSIFICATION_LEVELS, RELATIONSHIP_TYPE } from 'src/constants'
import * as yup from 'yup'

const entityAttr = {
  resolver: yupResolver(yup.object({
    Name: yup.string().required(),
    Description: yup.string(),
    Classification: yup.string().oneOf(CLASSIFICATION_LEVELS),
    DataType: yup.string().oneOf(ATTR_TYPE),
    DataFormat: yup.string(),
    DataValidationPattern: yup.string().required(),
    DataLength: yup.number().required(),
    IsKey: yup.bool(),
    IsMandatoryKey: yup.bool(),
    IsUniqueKey: yup.bool(),
    IsSearchable: yup.bool(),
    IsPII: yup.bool(),
  })),
  defaultValues: {
    Classification: '',
    DataType: '',
    IsKey: false,
    IsMandatoryKey: false,
    IsUniqueKey: false,
    IsSearchable: false,
    IsPII: false,
  }
}

const entityRship = {
  resolver: yupResolver(yup.object({
    Name: yup.string().required(),
    RelatedEntityName: yup.string().required(),
    RelationshipType: yup.string().oneOf(RELATIONSHIP_TYPE).required(),
    Cardinality: yup.string().when(
      'RelationshipType',
      { is: 'Has A', then: schema => schema.oneOf(CARDINALITY_TYPE).required() }
    ),
    IsA: yup.array().when('RelationshipType',
      {
        is: 'Is A', then: schema => schema.of(yup.object({
          checked: yup.bool(),
          RelatedAttr: yup.number(),
          Attr: yup.mixed().when('checked', {
            is: true, then: s => yup.number()
          }),
        })).compact(x => !x.checked).min(1)
      }
    ),
    HasA: yup.object().when('RelationshipType',
      {
        is: 'Has A', then: schema => schema.shape({
          Attr: yup.array().compact().min(1),
          Related: yup.array().compact().min(1)
        })
      }
    )
  })),
  defaultValues: {
    Name: '',
    RelatedEntityName: '',
    RelationshipType: '',
    Cardinality: '',
    IsA: [],
    HasA: { Attr: [], Related: [] }
  }
}

const newEntity = {
  resolver: yupResolver(yup.object({
    Name: yup.string().required(),
    MaxRecord: yup.number(),
    Description: yup.string(),
    Get: yup.object({
      Short: yup.string(),
      Long: yup.string()
    }),
    Post: yup.object({
      Short: yup.string(),
      Long: yup.string()
    }),
    Put: yup.object({
      Short: yup.string(),
      Long: yup.string()
    }),
    Delete: yup.object({
      Short: yup.string(),
      Long: yup.string()
    }),
  })),
  defaultValues: {
    Name: '',
    MaxRecord: '',
    Description: '',
    Get: {
      Short: '',
      Long: ''
    },
    Post: {
      Short: '',
      Long: ''
    },
    Put: {
      Short: '',
      Long: ''
    },
    Delete: {
      Short: '',
      Long: ''
    },
  }
}

export { entityAttr, entityRship, newEntity }