import { Box, Checkbox, FormControlLabel, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { DDContext } from '../../MainView'

function IsATable(props) {
  const { activeItem } = useContext(DDContext)
  const { register, watch, resetField } = useFormContext()
  const [rshipType, relatedEntity] = useWatch({
    name: ['RelationshipType', 'relatedEntityObj']
  })

  useEffect(() => {
    if (rshipType === 'Is A')
      resetField('IsA', {
        defaultValue: relatedEntity
          ? relatedEntity.Attributes.map(attr => ({
            Attr: '',
            RelatedAttr: attr.Id,
            checked: false
          })) : []
      })
    // eslint-disable-next-line
  }, [rshipType, relatedEntity])

  if (!relatedEntity)
    return <Typography color='text.secondary' fontStyle='italic'>
      Please select a related entity.
    </Typography>

  return <Box mx={-3} mb={-2} mt={2}>
    <Table size='small' sx={{ tableLayout: 'fixed' }} padding='checkbox'>
      <TableHead><TableRow>
        <TableCell>
          {relatedEntity.Entity}
        </TableCell>
        <TableCell>
          {activeItem.Entity}
        </TableCell>
      </TableRow></TableHead>
      <TableBody>
        {relatedEntity.Attributes?.map((attr, a) => <TableRow
          key={relatedEntity.Id + '-' + attr.Id}
        >
          <TableCell>
            <FormControlLabel control={<Controller
              name={`IsA.${a}.checked`}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={!!field.value}
                />
              )}
            />}
              label={attr.Attribute}
            />
          </TableCell>
          <TableCell>
            <TextField select fullWidth sx={{ my: 1 }}
              {...register(`IsA.${a}.Attr`)}
              value={watch(`IsA.${a}.Attr`) || ''}
            >
              {activeItem.Attributes.map(attr => <MenuItem key={attr.Id}
                value={attr.Id}
              >
                {attr.Attribute}
              </MenuItem>)}
            </TextField>
          </TableCell>
        </TableRow>)}
      </TableBody>
    </Table>
  </Box>
}

export default IsATable