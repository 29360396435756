import { Box, Tab, Tabs } from '@mui/material'
import React, { useState } from 'react'
import SFTPTab from './OrganizationTab'

const tabContent = [
  <SFTPTab />,
  'lorem ipsum',
  'lorem ipsum',
]

function HistoryPage(props) {
  const [tabIndex, setTabIndex] = useState(0)

  return <Box p={3} pt={1}>
    <Tabs value={tabIndex}
      onChange={(e, i) => setTabIndex(i)}
      textColor="secondary"
      indicatorColor="secondary"
    >
      <Tab label="Organisation" />
      <Tab label="Option Link 2" />
      <Tab label="Option Link 3" />
    </Tabs>

    {tabContent[tabIndex]}
  </Box>
}

export default HistoryPage