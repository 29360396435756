import React, { useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { SUBSCRIBER } from './form-validation'
import { useArrayTableSearch } from 'src/hooks/useUtils'
import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, Grid, Tab, Tabs, TextField, Tooltip } from '@mui/material'
import { DialogTitle, SearchField } from 'src/components'
import { AddFieldArrayBtn, EditTable } from 'src/components/DataTable'



const HEADERS = [
  {
    title: "Authorised Personnel",
    name: 'AuthorisedPersonelData'
  }, {
    title: "Organization Approval",
    name: 'OrganisationApprovalData'
  }
]

const AddBtn = props => <AddFieldArrayBtn defaultFields={{ Name: '', Email: '' }}>
  Add
</AddFieldArrayBtn>

const TabBody = ({ tabData, hidden, ...props }) => {
  const { watch } = useFormContext()
  const handleSearch = useArrayTableSearch(tabData.name)

  if (hidden)
    return <></>
  return <>
    <CardContent><Grid container spacing={2}>
      <Grid item xs><SearchField onChange={handleSearch} /></Grid>
      <Grid item><AddBtn>
        {tabData.addBtn}
      </AddBtn></Grid>
    </Grid></CardContent>
    <EditTable hidePagination sx={{ '& tr': { verticalAlign: 'top' } }}
      name={tabData.name}
      columns={[
        { field: 'Name' },
        { field: 'Email' }
      ]}
      data={watch(tabData.name)}
    />
    <Box my={2} textAlign='center'><AddBtn>
      {tabData.addBtnText}
    </AddBtn></Box>
  </>
}

function SubscriberDialog({ open, ...props }) {
  const methods = useForm(SUBSCRIBER)
  const { reset, register, formState: { defaultValues } } = methods
  const [tabIndex, setTabIndex] = useState(0)

  const item = useMemo(() => typeof open === 'boolean' ? false : open, [open])

  const handleTabChange = (e, val) => {
    if (val >= HEADERS.length)
      val = 0
    setTabIndex(val)
  }
  const submitForm = data => console.log(data)

  useEffect(() => {
    if (item)
      reset({
        ...item,
        AuthorisedPersonelData: item.AuthorisedPersonelData.map((x, index) => ({
          ...x, hidden: false, index
        })),
        OrganisationApprovalData: item.OrganisationApprovalData.map((x, index) => ({
          ...x, hidden: false, index
        }))
      })
    else
      reset(SUBSCRIBER.defaultValues)
    // eslint-disable-next-line
  }, [item])

  return <FormProvider {...methods}><form onSubmit={methods.handleSubmit(submitForm)}>
    <Dialog fullWidth maxWidth='lg' open={Boolean(open)} {...props}>
      <DialogTitle onClose={props.onClose}>
        {Boolean(item) ? 'Edit' : 'Add'} Data Model Owner Configuration
      </DialogTitle>
      <PerfectScrollbar><DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField fullWidth required
              {...register('OrganisationName')}
              label='Organisation Name'
              disabled={Boolean(defaultValues?.OrganisationName)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField fullWidth required
              {...register('OrganisationAbbreviation')}
              label='Organisation Abbreviation'
              disabled={Boolean(defaultValues?.OrganisationAbbreviation)}
            />
          </Grid>
        </Grid>

        <Card sx={{ mt: 2 }} variant='outlined'>
          <Tabs value={tabIndex} onChange={handleTabChange}>
            {HEADERS.map(({ title }, t) => <Tab key={t}
              label={title}
            />)}
          </Tabs>

          {HEADERS.map((hdr, h) => <TabBody key={hdr.name}
            tabData={hdr} hidden={tabIndex !== h}
          />)}
        </Card>

      </DialogContent></PerfectScrollbar>
      <DialogActions>
        <Button onClick={props.onClose}>
          Cancel
        </Button>
        <Tooltip title='This will reset both tabs!' placement='top'>
          <Button onClick={() => reset()} variant='outlined'>
            Reset
          </Button>
        </Tooltip>
        <Button variant='contained' onClick={methods.handleSubmit(submitForm)}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  </form></FormProvider>
}

export default SubscriberDialog