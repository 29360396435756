import { SUBMISSION_COLOR } from "."

export const getActiveStateColor = status => {
  const s = status.toUpperCase()
  if (s === 'ACTIVE')
    return 'success'
  if (s === 'INACTIVE')
    return 'default'
  if (s === 'PENDING')
    return 'warning'
}

export const getSubmissionStateColor = subm => {
  if (!subm) return 'default'
  let state = subm.split(' ')[0]
  return SUBMISSION_COLOR[state] || 'default'
}

export const getFieldKey = (field, key) => {
  const sp = key.split('.')
  let val = field[sp.shift()]
  while (sp.length && val) {
    let next = sp.shift()
    if (!isNaN(parseInt(next)))
      next = parseInt(next)
    val = val[next]
  }
  return val
}

export const delay = t => new Promise(resolve => setTimeout(resolve, t));