import { Box, Button, Collapse, Dialog, DialogContent, Grid, IconButton, Stack, Typography } from '@mui/material'
import React, { createContext, useEffect, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import Header from 'src/layouts/components/Header'
import SearchForm from './SearchForm'
import { API_GetSearchResults } from 'src/constants/api'
import { DataTable, DialogTitle, SubmissionStatusChip } from 'src/components'
import { useDataTableUtils } from 'src/hooks/useUtils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormDetails } from 'src/views/inbox'

export const EnquiryContext = createContext(null)

function MainView(props) {
  const { loading, data, getData, PaginationProps } = useDataTableUtils(API_GetSearchResults)
  const [openFilters, setOpenFilters] = useState(true)
  const [dialog, setDialog] = useState(false)

  const handleClose = () => setDialog(false)


  // eslint-disable-next-line
  useEffect(() => getData(), [])

  return <EnquiryContext.Provider value={{
    getData, loading
  }}>
    <Stack height='100%'>
      <Header title='Enquiries' />
      <PerfectScrollbar>
        <Box p={2}>
          <Grid container spacing={2} alignItems='center' mb={2}>
            <Grid item xs>
              <Typography variant='h5' color='primary' fontWeight='fontWeightBold'>
                Search applications by details
              </Typography>
              <Typography variant='subtitle2' color='text.secondary'>
                Search by form type, status, or organisation name
              </Typography>
            </Grid>
            <Grid item>
              <Button variant='outlined' size='large'
                onClick={() => setOpenFilters(!openFilters)}
                startIcon={<FontAwesomeIcon icon='fal fa-filter' />}
                endIcon={<FontAwesomeIcon icon={`fal fa-chevron-up`}
                  rotation={openFilters ? 0 : 180}
                />}
              >
                {openFilters ? 'Hide' : 'Show'} Filters
              </Button>
            </Grid>
          </Grid>
          <Collapse in={openFilters}>
            <SearchForm />
          </Collapse>

          {data && <DataTable
            data={data}
            loading={loading}
            PaginationProps={PaginationProps}
            columns={[
              { field: 'ReferenceNumber', title: 'Ref. No.' },
              { field: 'FormType', title: 'Form Type' },
              { field: 'SubmissionByOrganisation', title: 'Organisation Name' },
              {
                field: 'WorkflowStateDescription', title: 'Status',
                render: rowData => <SubmissionStatusChip
                  label={rowData.WorkflowStateDescription}
                />
              },
              { field: 'SubmissionDateStr', title: 'Submission Date', type: 'datetime' },
              { field: 'SubmissionByEmail', title: 'Email' },
              {
                title: 'Actions', render: rowData => <IconButton
                  onClick={() => setDialog(rowData)}
                >
                  <FontAwesomeIcon icon='fal fa-eye' fixedWidth />
                </IconButton>
              }
            ]}
          />}
        </Box>
      </PerfectScrollbar>
    </Stack>
    <Dialog open={Boolean(dialog)} onClose={handleClose}
      fullWidth maxWidth='lg'
    >
      <DialogTitle onClose={handleClose}>View Application</DialogTitle>
      <PerfectScrollbar><DialogContent dividers>
        Ref No: <b>{dialog?.ReferenceNumber}</b> | <SubmissionStatusChip
          label={dialog?.WorkflowStateDescription}
        />
        <FormDetails fetchUrl="/testdata/inbox/18_ApplicationProcess.json" />
      </DialogContent></PerfectScrollbar>
    </Dialog>
  </EnquiryContext.Provider>
}

export default MainView