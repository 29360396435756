import { List, Paper, Slide, useTheme } from '@mui/material'
import React, { useContext } from 'react'
import { DXPContext } from '../MainLayout'
import { MenuItem } from './Sidebar'

function L2Sidebar(props) {
  const theme = useTheme()
  const { secondOpen } = useContext(DXPContext)

  return <Slide direction="right" mountOnEnter unmountOnExit
    {...props}
  >
    <Paper square
      sx={{
        position: 'absolute',
        left: theme.props.sidebar,
        top: 0, bottom: 0,
        width: theme.props.sidebar + 20,
        backgroundColor: 'primary.200',
        zIndex: theme.zIndex.drawer2
      }}
    >
      <List disablePadding>
        {secondOpen?.children?.map(item => (
          <MenuItem key={item.title} item={item} light />
        ))}
      </List>
    </Paper>
  </Slide>
}

export default L2Sidebar