import { Box } from '@mui/material'
import React from 'react'
import { DataTable } from 'src/components'
import { useDataTableUtils } from 'src/hooks/useUtils'

function HistoryPage(props) {
  const { loading, data, PaginationProps } = useDataTableUtils('/testdata/model_history.json')

  return <Box p={2}><DataTable
    loading={loading}
    PaginationProps={PaginationProps}
    columns={[
      {
        field: 'Remarks',
      }, {
        field: 'CreatedBy',
        title: 'Created By'
      }, {
        field: 'CreatedDate',
        title: 'Created Date',
        type: 'datetime'
      }
    ]}
    data={data}
  /></Box>
}

export default HistoryPage