import { Card } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Dendrogram } from 'src/components/charts/Dendrogram'
import { API_GetAllPublishersAndSubscribers } from 'src/constants/api'

const parseDendro = node => {
  if (node.children && node.children.length) {
    node.type = 'node'
    node.children = node.children.map(parseDendro)
  } else
    node.type = 'leaf'
  return node
}

const NODE_COUNT = {
  Publishers: 'publisherNodeCount',
  Subscribers: 'subscriberNodeCount'
}

function OrgCard(props) {
  const [data, setData] = useState([])

  const getData = () => {
    axios(API_GetAllPublishersAndSubscribers)
      .then(res => {
        let d = parseDendro(res.data)
        d.children = d.children.map(x => ({
          ...x,
          name: x.name + ` (${d[NODE_COUNT[x.name]]})`
        }))
        setData(d)
      })
  }

  useEffect(() => {
    getData()
  }, [])

  return <Card>
    <Dendrogram height={600} data={data} />
  </Card>
}

export default OrgCard