import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Grid, IconButton, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { DataTable, SearchField, ViewEditIcon } from 'src/components'
import { useDataTableUtils } from 'src/hooks/useUtils'
import AttrHistoryDialog from './AttrHistoryDialog'
import AttrViewDialog from './AttrViewDialog'

import TEST_ATTRDATA from 'src/testdata/entity_attr.json'

const Icon = ({ hasRelation }) => <Box component={FontAwesomeIcon}
  color={hasRelation ? 'warning.main' : 'info.main'}
  icon={hasRelation ? 'fad fa-circle-nodes' : 'fas fa-circle'}
  size={hasRelation ? 'lg' : 'sm'}
/>

function AttributesPage(props) {
  const { loading, data, PaginationProps } = useDataTableUtils('/testdata/entity_attr.json')

  const [historyItem, setHistoryItem] = useState(null)
  const [viewItem, setViewItem] = useState(null)

  return <>
    <Box p={2}>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs>
          <SearchField />
        </Grid>
      </Grid>
      <DataTable
        loading={loading}
        PaginationProps={PaginationProps}
        columns={[
          {
            field: 'Name',
            render: item => <>
              <Icon hasRelation={item.HasRelation} />
              &ensp;
              {item.Name}
            </>
          }, {
            field: 'Classification',
          }, {
            field: 'DataType'
          }, {
            field: 'DataFormat'
          }, {
            title: 'Actions',
            render: item => <>
              <Tooltip title='View/Edit'>
                <IconButton onClick={() => setViewItem(TEST_ATTRDATA)}>
                  <ViewEditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title='History'>
                <IconButton onClick={() => setHistoryItem(item)}>
                  <FontAwesomeIcon icon='far fa-timeline-arrow' fixedWidth />
                </IconButton>
              </Tooltip>
            </>
          }
        ]}
        data={data}
      >
        <Grid container columnSpacing={3} sx={theme => ({
          fontSize: theme.typography.caption.fontSize,
          fontStyle: 'italic',
          color: theme.palette.text.secondary
        })}>
          <Grid item>
            <Icon />&ensp;Attributes
          </Grid>
          <Grid item><Icon hasRelation />&ensp;Attributes with relation</Grid>
        </Grid>
      </DataTable>

    </Box>
    <AttrHistoryDialog item={historyItem} onClose={() => setHistoryItem(null)} />
    <AttrViewDialog item={viewItem} onClose={() => setViewItem(null)} />
  </>
}

export default AttributesPage