import React from 'react'
import { CssBaseline, ThemeProvider } from '@mui/material'
import {
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import 'react-quill/dist/quill.snow.css'
import 'react-perfect-scrollbar/dist/css/styles.css'

import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

import { ROUTES } from 'src/routes'
import theme from 'src/theme'
import { SnackbarProvider } from 'notistack'

import languagePack from 'src/languages'
import moment from 'moment'

library.add(fab, far, fal, fad, fas)
moment.updateLocale('en', {
  invalidDate: '-'
})

const router = createBrowserRouter(ROUTES)

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: languagePack,
    lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  })

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider anchorOrigin={{
          horizontal: 'right', vertical: 'bottom'
        }}>
          <RouterProvider router={router} />
        </SnackbarProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
