import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Card, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import moment from 'moment'
import React, { useState } from 'react'

import { DATEFMT_SIMPLE_DATETIME } from 'src/constants'
import { DataTable, SearchField } from 'src/components'
import { useDataTableUtils } from 'src/hooks/useUtils'
import DeleteWarningDialog from 'src/components/DeleteWarningDialog'

function RelationshipPage(props) {
  const { loading, data, getData, PaginationProps } = useDataTableUtils('/testdata/entity_relationship.json')

  const [deletable, setDeletable] = useState(null)

  const handleClose = () => setDeletable(null)

  const handleDelete = () => {
    // do something with `deletable`

    // re-fetch data & close dialog
    getData()
    handleClose()
  }

  return <>
    <Box p={2}>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs>
          <SearchField />
        </Grid>
      </Grid>
      <DataTable
        loading={loading}
        PaginationProps={PaginationProps}
        columns={[
          {
            field: 'Entity.Name',
            title: 'Name',
            render: item => `${item.Entity.Name} ${item.RelationshipType} ${item.RelatedEntity.Name}`
          }, {
            field: 'RelatedEntity.Name',
            title: 'Relationship Entity'
          }, {
            field: 'ModifiedBy',
            title: 'Last Updated By'
          }, {
            field: 'Modified',
            title: 'Last Updated',
            type: 'datetime'
          }, {
            field: 'IsDeleted',
            title: 'Is Deleted',
            render: item => item.isDeleted ? 'Yes' : 'No'
          }, {
            title: 'Actions',
            render: (item, index) => <Tooltip title='Delete entity'>
              <IconButton onClick={() => setDeletable(item)}>
                <FontAwesomeIcon icon='fal fa-trash-alt' fixedWidth />
              </IconButton>
            </Tooltip>
          }
        ]}
        data={data}
        expand={rowData => <Card><Grid container spacing={2} m={.5} mb={2}>
          <Grid item xs>
            <Typography variant='subtitle1'>
              Cardinality
            </Typography>
            {rowData.Cardinality}
          </Grid>
          <Grid item xs>
            <Typography variant='subtitle1'>
              Mapping Attributes
            </Typography>
            {rowData.Entity.Key} is mapped to {rowData.RelatedEntity.Key}
          </Grid>
          <Grid item xs>
            <Typography variant='subtitle1'>
              Created by
            </Typography>
            {rowData.CreatedBy}
            <Typography variant='body2' color='text.secondary'>
              {moment(rowData.Created).format(DATEFMT_SIMPLE_DATETIME)}
            </Typography>
          </Grid>
        </Grid></Card>}
      />
    </Box>
    <DeleteWarningDialog open={Boolean(deletable)} maxWidth='sm'
      title='Delete Data Entity'
      onClose={handleClose}
      onConfirm={handleDelete}
    >
      The selected Data Entity Relationship will be deleted. This action is <b>irreversible</b> and will affect all items related to this Data Entity Relationship.<br /><br />
      Are you sure you want to continue?
    </DeleteWarningDialog>
  </>
}

export default RelationshipPage