import { Card, CardActions, CardContent, Divider, Grid, Typography, capitalize } from '@mui/material'
import moment from 'moment'
import React from 'react'

function OrganizationExpanded({ data }) {
  return <Card sx={{ backgroundColor: 'primary.50' }} elevation={1}>
    <CardContent><Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">SFTP Credentials</Typography>
      </Grid>

      {data.Credentials.map((cred, c) => <Grid item xs={12} md={6} key={c}>
        <Typography variant="subtitle1">
          {capitalize(cred.Environment)}
        </Typography>
        <Typography mb={2}>
          Host Name: {cred.HostName}<br />
          Port Number: {cred.Port}<br />
          Username: {cred.Username}<br />
          Password: {cred.Password}<br />
        </Typography>
      </Grid>)}
    </Grid></CardContent>

    <Divider />

    <CardActions sx={{ px: 2 }}>
      <Grid container spacing={1} justifyContent='space-between' color='text.secondary'>
        <Grid item>
          Created by {data.CreatedBy} on {moment(data.Created).format('LL')}
        </Grid>
        <Grid item>
          Modified by {data.ModifiedBy} on {moment(data.Modified).format('LL')}
        </Grid>
      </Grid>
    </CardActions>
  </Card>
}

export default OrganizationExpanded