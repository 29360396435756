import { createTheme } from '@mui/material'
import { colord } from "colord"
import "@fontsource/inter"

const tonalOffset = .28
let theme = createTheme({
  palette: {
    primary: {
      main: '#266181',
      200: '#d0e6f2',
      100: colord('#d0e6f2').lighten(.05).toHex(),
      50: colord('#d0e6f2').lighten(.1).toHex(),
      hover: colord('#d0e6f2').alpha(.2).toHex()
    },
    secondary: {
      main: '#613333',
      200: colord('#613333').lighten(.55).toHex(),
      100: colord('#613333').lighten(.6).toHex(),
      50: colord('#613333').lighten(.65).toHex(),
      hover: colord('#613333').lighten(.55).alpha(.2).toHex()
    },
    success: {
      main: '#87ca75',
      100: colord('#87ca75').lighten(.3).toHex(),
      50: colord('#87ca75').lighten(.35).toHex(),
    },
    info: {
      main: '#14a5a4',
      100: colord('#148ea5').lighten(.15).toHex(),
      50: colord('#148ea5').lighten(.62).toHex(),
    },
    warning: {
      main: '#f4ca35',
      dark: '#d1950c',
      200: colord('#f4ca35').lighten(.25).toHex(),
      100: colord('#f4ca35').lighten(.35).toHex(),
      50: colord('#f4ba35').lighten(.4).toHex(),
    },
    error: {
      main: '#d12e38', dark: '#a95353',
      100: colord('#d12e38').lighten(.4).toHex(),
      50: colord('#d12e38').lighten(.48).toHex(),
    },
    tonalOffset
  },
  typography: {
    fontFamily: [
      'Inter',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(',')
  }
})

theme.shadows.splice(1, 2,
  '0 0 5px rgb(0 0 0 / 20%)',
  '0 0 10px rgb(0 0 0 / 20%)'
)
const shadows = theme.shadows

// extra colours and props
theme = createTheme(theme, {
  palette: {
    white: theme.palette.augmentColor({ color: { main: '#fff' } }),
  },
  props: {
    toolbar: 64,
    sidebar: 110,
    listDrawer: 300
  },
  zIndex: {
    appBar: 1200,
    drawer: 1100,
    drawer2: 1080,
    drawerBackdrop: 1060,
    listDrawer: 1020,
    stickyTableHeader: 200
  },
  shadows,
})

// components
theme = createTheme(theme, {
  typography: {
    subtitle1: { fontWeight: theme.typography.fontWeightBold }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        svg[class*="fa-chevron"] {
          transition: ${theme.transitions.create()};
        }
        button {
          font-family: ${theme.typography.fontFamily};
        }
      `,
    },

    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        },
        startIcon: {
          '& > *:nth-of-type(1)': {
            fontSize: 'unset'
          }
        },
        endIcon: {
          '& > *:nth-of-type(1)': {
            fontSize: 'unset'
          }
        }
      }
    },
    MuiChip: {
      variants: [
        {
          props: { size: 'mini' },
          style: {
            fontSize: theme.typography.caption.fontSize,
            height: theme.spacing(2.5)
          }
        }, {
          props: { variant: 'square' },
          style: {
            borderRadius: theme.shape.borderRadius
          }
        }
      ],
      styleOverrides: {
        label: {
          padding: theme.spacing(0, 2),
          '.MuiChip-avatar + &': {
            padding: theme.spacing(0, 1.5)
          }
        },
        labelMini: { padding: theme.spacing(0, 1) }
      }
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          height: '100vh'
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          '& .MuiIconButton-root > .fa-xmark': { fontSize: '1.2rem' }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          fontSize: '1.4em',
          '& > .svg-inline--fa': {
            // fontSize: '0.875em',
            lineHeight: '0.0714285718em',
            verticalAlign: '-0.0714285705em'
          },
          '& > .fa-fw': {
            height: '1.25em'
          }
        }
      },
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            background: theme.palette.grey[300],
            color: theme.palette.grey[600],
            transition: theme.transitions.create(),
            borderRadius: theme.shape.borderRadius,
            height: '1.65em',
            width: '1.65em',
            '&:hover': {
              background: theme.palette.grey[400],
              color: theme.palette.grey[700]
            }
          }
        }, {
          props: { variant: 'contained', color: 'primary' },
          style: {
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            '&:hover': {
              background: theme.palette.primary.dark,
              color: theme.palette.primary.contrastText,
            }
          }
        }

      ]
    },
    MuiInputBase: {
      variants: [
        {
          props: { variant: "clipboard" },
          style: {
            background: theme.palette.grey[100],
            '&, &.MuiOutlinedInput-root': { borderRadius: 200 },
            '& .MuiInputAdornment-positionEnd': { marginLeft: theme.spacing(.5) },

          }
        }
      ]
    },
    MuiOutlinedInput: {
      variants: [
        {
          props: { variant: 'display' },
          style: {
            '& > .MuiOutlinedInput-notchedOutline': {
              border: 0
            }
          }
        }
      ],
      styleOverrides: {
        notchedOutline: {
          borderColor: theme.palette.divider,
          transition: theme.transitions.create()
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          minHeight: 'unset',
          padding: theme.spacing(2)
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 'unset'
        }
      }
    },
    MuiTable: {
      variants: [
        {
          props: { stickyHeader: true },
          style: {
            '&-stickyHeader .MuiTableHead-root': {
              top: 0,
              position: 'sticky',
              zIndex: theme.zIndex.stickyTableHeader
            }
          }
        }
      ]
    },
    MuiTableCell: {
      styleOverrides: {
        root: { backgroundColor: 'transparent' },
        stickyHeader: {
          '&.MuiTableCell-head': {
            backgroundColor: theme.palette.common.white
          }
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        size: 'small'
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      }
    },
  }
})

console.log(theme)

export default theme