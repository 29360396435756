import { Box, Button, Chip, Grid, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'

import { API_GetApplicationSummary, API_GetDataDefinitionSummary, API_GetOrganisationSummary } from 'src/constants/api'
import { SUBMISSION_COLOR } from 'src/constants'

import ChartCard from './ChartCard'
import { getFieldKey } from 'src/constants/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AddNewOrgDialog, ModelDialog } from 'src/views'
import DendrogramCard from './OrgCard'

const temp_colorvar = ['main', 'light', 'dark']
const ACTIVE_COLOR = {
  Active: 'success.main',
  Inactive: 'grey.400',
  New: 'primary.main',
  "Pending Activation": 'warning.main'
}

function MainView(props) {
  const { palette } = useTheme()

  const [dialog, setDialog] = useState(false)

  return <Box p={2} bgcolor='primary.50' height='100%'><Grid container width="100%" spacing={2} pb={2}>
    <Grid item xs={12} xl={5}>
      <ChartCard title='Applications' icon='file-signature'
        url={API_GetApplicationSummary}
        dataFn={res => Object.entries(res.data.ApplicationStatus)
          .map(([label, value], index) => {
            let color = palette[SUBMISSION_COLOR[label.split(' ')[0]]]
            if (label.startsWith('Pending'))
              color = color[temp_colorvar[index - 1]]
            else if (label === 'Cancelled')
              color = color[100]
            else
              color = color.main
            return {
              label, value, index, color
            }
          })
        }
      />
    </Grid>
    <Grid item xs={12} md={6} xl={4}>
      <ChartCard title='Organisations' icon='building-columns'
        url={API_GetOrganisationSummary}
        dataKey='OrganisationStatus'
        dataFn={res => {
          res.data.OrganisationStatus = Object
            .entries(res.data.OrganisationStatus)
            .map(([label, value], index) => ({
              label, value, index,
              color: getFieldKey(palette, ACTIVE_COLOR[label])
            }))
          return res.data
        }}
        footer={data => <Grid container spacing={2} alignItems='center'>
          <Grid item>
            <Button onClick={() => setDialog('org')}
              startIcon={<FontAwesomeIcon icon='far fa-plus' />}
            >
              Add Organisation
            </Button>
          </Grid>
          <Grid item ml='auto'>
            {Object.entries(data?.PublisherSubscriberCount || {}).map(([key, val]) => <Box key={key} display='flex' justifyContent='space-between' my={.25}>
              <Typography component='span' color='grey.500' key={key} variant='body2'>
                {key}&nbsp;

              </Typography>
              <Chip size='mini' label={val} color='info' />
            </Box>)}
          </Grid>
        </Grid>}
      />
    </Grid>
    <Grid item xs={12} md={6} xl={3}>
      <ChartCard title='Data Definitions' icon='sitemap'
        url={API_GetDataDefinitionSummary}
        dataFn={res => Object
          .entries(res.data.DataDefinitionStatus)
          .map(([label, value], index) => ({
            label, value, index,
            color: getFieldKey(palette, ACTIVE_COLOR[label])
          }))
        }
        footer={() => <Button onClick={() => setDialog('dd')}
          startIcon={<FontAwesomeIcon icon='far fa-plus' />}
        >
          Add Data Model
        </Button>}
      />
    </Grid>
    <Grid item xs={12}>
      <DendrogramCard />
    </Grid>


    <AddNewOrgDialog open={dialog === 'org'}
      onClose={() => setDialog(false)}
    />
    <ModelDialog open={dialog === 'dd'}
      createMode
      onClose={() => setDialog(false)}
    />
  </Grid></Box>
}

export default MainView