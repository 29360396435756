import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, DialogTitle as MuiDialogTitle, Divider, Grid, ListItem, ListItemAvatar, ListItemText, Typography, styled, IconButton, TextField, InputAdornment, Chip, FormControl, InputLabel } from '@mui/material'
import React from 'react'
import { ENVS } from 'src/constants'
import { getSubmissionStateColor } from 'src/constants/utils'

export { default as DataTable } from './DataTable'
export { default as DateTimeControl } from './DateTimeControl'
export * from './charts'

export const SpacedDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(2, 0)
}))

export const SummaryItem = ({ icon, title, children }) =>
  <ListItem alignItems='flex-start' disableGutters>
    <ListItemAvatar><Avatar sx={{
      width: 56, height: 56,
      mr: 1,
      color: 'primary.main',
      backgroundColor: 'grey.100'
    }}>
      <FontAwesomeIcon icon={`fal fa-${icon}`} size="xl" />
    </Avatar></ListItemAvatar>
    <ListItemText>
      <Typography variant="subtitle2">
        {title}
      </Typography>
      {children}
    </ListItemText>
  </ListItem>

export const EnvItem = ({ env }) => {
  const data = ENVS[env?.toLowerCase()] || { color: 'primary', icon: 'question-mark', name: 'Unknown' }

  return <Grid container spacing={1} alignItems="center">
    <Grid item><Avatar sx={{
      backgroundColor: data.color + '.light',
      color: data.color + '.contrastText'
    }}>
      <FontAwesomeIcon icon={`fal fa-${data.icon}`} />
    </Avatar></Grid>
    <Grid item xs>{data.name}</Grid>
  </Grid>
}

export const DialogTitle = ({ onClose, children, alignment = 'center', ...props }) => <MuiDialogTitle {...props}>
  <Grid container alignItems={alignment} flexWrap='nowrap' justifyContent='space-between'>
    {children}
    <IconButton onClick={onClose}>
      <FontAwesomeIcon icon='far fa-times' fixedWidth />
    </IconButton>
  </Grid></MuiDialogTitle>

export const ViewEditIcon = props => <span className="fa-layers fa-fw">
  <FontAwesomeIcon icon="far fa-eye" />
  <FontAwesomeIcon icon="fas fa-pen" transform="shrink-3 right-6 down-3" />
</span>

export const SearchField = ({ InputProps, ...props }) => <TextField
  placeholder={props.label || "Search by name"} size="small"
  {...props}
  InputProps={{
    endAdornment: <InputAdornment position='end'>
      <FontAwesomeIcon icon="far fa-search" />
    </InputAdornment>,
    ...InputProps
  }}
/>

export const SubmissionStatusChip = ({ label, ...props }) => <Chip variant='mini'
  label={label}
  color={getSubmissionStateColor(label)}
  {...props}
/>

export const DisplayField = ({ label, value, children, ...props }) => <FormControl sx={{ mt: 2 }}>
  <InputLabel shrink sx={{
    maxWidth: 'unset',
    transform: 'translate(0, -16px) scale(.75)'
  }}>
    {label}
  </InputLabel>
  {value || children}
</FormControl>