import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, Grid, Typography } from '@mui/material'
import React, { createContext, useMemo, useState } from 'react'
import DataListLayout from 'src/layouts/DataListLayout'

import ORGANIZATIONS from 'src/testdata/organizations'
import SummaryPage from './SummaryPage'
import PermissionsPage from './PermissionsPage'
import PIPage from './PIPage'
import CredentialsPage from './CredentialsPage'
import AddNewOrgDialog from './AddNewOrgDialog'
import ServicesPage from './ServicesPage'
import HistoryPage from './HistoryPage'
import { ORG_TABS } from 'src/constants'
import { getActiveStateColor } from 'src/constants/utils'
import { DialogTitle } from 'src/components'
import { useSnackbar } from 'notistack'

const renderTab = index => {
  const pages = [
    <SummaryPage />,
    <PermissionsPage />,
    <PIPage />,
    <CredentialsPage />,
    <ServicesPage />,
    <HistoryPage />
  ]
  if (index < pages.length)
    return pages[index]
  return <Box p={2}>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nec tellus nec risus dictum tempus. In nec ante odio. Suspendisse eleifend sodales nisl, sit amet scelerisque diam scelerisque id. Cras auctor congue felis non tincidunt. Donec scelerisque porttitor quam a luctus. Sed malesuada aliquam lectus, eu imperdiet lorem dictum quis. Mauris volutpat leo varius magna porttitor ultricies. Etiam rhoncus aliquam odio a sodales. Nullam lobortis faucibus odio, eu scelerisque velit volutpat quis. Maecenas est risus, semper eget velit rhoncus, cursus vehicula magna. Sed varius vitae nulla vulputate lobortis. Vestibulum eu ipsum diam. Etiam non ultricies turpis, lacinia euismod leo. Maecenas bibendum non massa vitae rutrum.</p>
  </Box>
}

export const OrgContext = createContext(null)

function OrganizationsMainView(props) {
  const { enqueueSnackbar } = useSnackbar()

  const [activeItem, setActiveItem] = useState(null)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [addDialogOpen, setAddDialogOpen] = useState(false)
  const [tabIndex, setTabIndex] = useState(0)

  const activeItemChipProps = useMemo(() => (activeItem ? {
    label: activeItem.Status,
    color: getActiveStateColor(activeItem.Status)
  } : {}), [activeItem])

  const handleCloseDeleteDialog = () => setDeleteDialogOpen(false)

  const handleDelete = () => {
    // do something
    // DEMO: toast/snackbar/notification
    enqueueSnackbar(`Deleted ${activeItem?.Name}`, { variant: 'success' })
    handleCloseDeleteDialog()
  }

  return <DataListLayout
    ListProps={{
      header: 'Organizations',
      items: ORGANIZATIONS,
      onItemClick: setActiveItem,
      showNew: 'Created',
      sortKeys: [
        { key: "Created", name: "date", icon: "calendar-arrow-down", reverseIcon: "calendar-arrow-up" },
        { key: "Name", reverseIcon: "arrow-down-a-z", icon: "arrow-down-z-a" }
      ],
      onInteract: () => setAddDialogOpen(true)
    }}
    HeaderProps={{
      title: activeItem ? <>
        <Grid container spacing={2} alignItems='center'>
          <Grid item>{activeItem.Name}</Grid>
          <Grid item>
            <Chip {...activeItemChipProps} />
          </Grid>
        </Grid>
        <Typography variant="caption" component="div">
          <Grid container spacing={4}>
            <Grid item>
              UID: {activeItem.Id}
            </Grid>
            <Grid item>
              Abbreviation: {activeItem.Abbreviation || activeItem.Name}
            </Grid>
          </Grid>
        </Typography>

      </> : "Organizations",
      actions: activeItem ? [
        {
          label: `Edit ${activeItem.Name}`,
          icon: "pen-to-square",
          onClick: () => setAddDialogOpen(activeItem)
        }, {
          label: `Delete ${activeItem.Name}`,
          icon: ["trash", "error.main"],
          onClick: () => setDeleteDialogOpen(true)
        },
      ] : [],
      tabs: ORG_TABS,
      onTabChange: setTabIndex
    }}
  >
    <OrgContext.Provider value={activeItem}>
      {activeItem
        ? renderTab(tabIndex)
        : <Typography p={2}>Please select an organization.</Typography>
      }

      <Dialog
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
      >
        <DialogTitle onClose={handleCloseDeleteDialog}>
          Delete organization
        </DialogTitle>
        <DialogContent>
          <DialogContentText variant="h4">
            Are you sure you wish to delete "{activeItem?.Name}"?
          </DialogContentText>
          <DialogContentText>
            Deleting the organization will delete all related records, including data model/entity, attributes & permissions.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} autoFocus >Cancel</Button>
          <Button onClick={handleDelete} color="error" variant="contained">
            Proceed
          </Button>
        </DialogActions>
      </Dialog>

      <AddNewOrgDialog open={addDialogOpen}
        onClose={(e, reason) => !reason && setAddDialogOpen(false)}

      />
    </OrgContext.Provider>
  </DataListLayout>
}

export default OrganizationsMainView