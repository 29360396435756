import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

const dataModel = {
  resolver: yupResolver(yup.object({
    DataModel: yup.string().required(),
    Description: yup.string(),
    Terms: yup.string(),
  })),
  defaultValues: {
    DataModel: '',
    Description: '',
    Terms: '',
  }
}

export { dataModel }