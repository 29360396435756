import { Button, Card, CardActions, CardContent, Grid, MenuItem, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import React, { useContext } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { DateTimeControl } from 'src/components'
import { APPROVAL_STATUS } from 'src/constants'
import { EnquiryContext } from './MainView'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { searchForm } from './form-validation'

function SearchForm(props) {
  const methods = useForm(searchForm)
  const { register, watch, handleSubmit, reset } = methods
  const { getData, loading } = useContext(EnquiryContext)

  const submitForm = getData

  return <Card><FormProvider {...methods}><form onSubmit={handleSubmit(submitForm)}>
    <CardContent>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField {...register('ReferenceNumber')}
            label='Reference Number'
            fullWidth disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField {...register('SubmissionByOrganisation')}
            label='Organisation Name'
            fullWidth disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField {...register('FormType')}
            label='Form Type'
            select fullWidth
            value={watch('FormType')}
            disabled={loading}
          >
            <MenuItem
              value='DATA ACCESS REQUEST FORM'
            >
              DATA ACCESS REQUEST FORM
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField select fullWidth
            {...register('Status')}
            value={watch('Status')}
            label='Status'
            disabled={loading}
          >
            {APPROVAL_STATUS.map(val => <MenuItem
              key={val} value={val}
            >
              {val}
            </MenuItem>)}
          </TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <DateTimeControl Component={DatePicker}
            name='SubmissionDate'
            label='Submission Date'
            slotProps={{
              textField: { fullWidth: true }
            }}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField {...register('SubmissionByEmail')}
            label='Email' disabled={loading}
            fullWidth type='email'
          />
        </Grid>
      </Grid>
    </CardContent>
    <CardActions sx={{
      justifyContent: 'center',
      '& > button': { minWidth: 150 }
    }}>
      <Button variant='outlined' onClick={() => reset()}>
        Reset
      </Button>
      <Button variant='contained' onClick={handleSubmit(submitForm)}
        disabled={loading}
        startIcon={loading ? <FontAwesomeIcon icon='far fa-spinner-third' spin /> : <></>}
      >
        Search
      </Button>
    </CardActions>
  </form></FormProvider></Card>
}

export default SearchForm