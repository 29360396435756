import React, { useMemo } from 'react'
import { styled } from "@mui/material"
import { useDrawingArea } from '@mui/x-charts';

const StyledText = styled('text')(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fontSize: theme.typography.h6.fontSize,
  fontFamily: theme.typography.fontFamily,
  textAlign: 'center'
}));

export function PieCenterLabel({ children, lines }) {
  const { width, height, left, top } = useDrawingArea()

  const linedText = useMemo(() => lines?.map((line, i) => {
    let props = {}
    if (line.hasOwnProperty('style')) {
      props.style = line.style
      line = line.text
    }
    return <tspan key={i}
      dy={`${i === 0 ? -.5 * (lines.length - 1) : 1.2}em`}
      x={left + width / 2}
      {...props}
    >
      {line}
    </tspan>
  }), [lines, left, width])

  return (
    <StyledText x={left + width / 2} y={top + height / 2}>
      {linedText || children}
    </StyledText>
  );
}