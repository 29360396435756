import { Grid, Typography } from '@mui/material'
import React, { createContext, useState } from 'react'

import DataListLayout from 'src/layouts/DataListLayout'
import JOB_LIST from 'src/testdata/job_list'
import TablePage from './TablePage'
import DataListItem from './DataListItem'

export const MonitoringContext = createContext(null)

function MonitoringMainView(props) {

  const [activeItem, setActiveItem] = useState(null)

  return <MonitoringContext.Provider value={{
    activeItem, setActiveItem,
  }}>
    <DataListLayout
      ListProps={{
        displayKey: 'JobCategory',
        statusKey: 'JobType',
        header: 'Monitoring',
        items: JOB_LIST,
        renderItem: (listItem) => <DataListItem
          {...listItem}
        />,
        onItemClick: setActiveItem,
        sortKeys: [],
      }}
      HeaderProps={{
        title: activeItem ? <>
          <Grid container spacing={2} alignItems='center'>
            <Grid item>{activeItem.JobName}</Grid>
          </Grid>
        </> : "Monitoring"
      }}
    >
      {activeItem?.JobName
        ? <TablePage />
        : <Typography p={2}>Please select a job.</Typography>
      }
    </DataListLayout>
  </MonitoringContext.Provider>
}

export default MonitoringMainView;
