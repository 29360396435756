import React, { useState } from 'react'
import { Box, Button, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'

import { v4 as uuid } from 'uuid'
import { useTestUsernames } from 'src/hooks/useTestHooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import copy from 'copy-to-clipboard'
import { useSnackbar } from 'notistack'
import { EnvItem } from 'src/components'

function CredentialsPage(props) {
  const generateUsername = useTestUsernames()
  const { enqueueSnackbar } = useSnackbar()

  const [apiKeys, setApiKeys] = useState({
    staging: uuid(),
    sandbox: uuid(),
    production: null
  })
  const [keyMasks, setKeyMasks] = useState({
    staging: true,
    sandbox: true,
    production: true
  })

  const [sftp, setSFTP] = useState({
    staging: { username: generateUsername(), hidden: true },
    production: { hostname: '20.197.92.55' }
  })


  const handleGenerateApiKey = env => setApiKeys({
    ...apiKeys,
    [env]: uuid()
  })

  const handleCopy = val => {
    copy(val)
    enqueueSnackbar(<>
      Copied <Paper sx={{
        backgroundColor: 'grey.800', color: 'common.white',
        px: 1, py: .5, mx: 1, display: 'inline-block'
      }} elevation={0}>
        <code>{val}</code>
      </Paper> to clipboard!
    </>, { variant: "success" })
  }

  const mask = (hidden, val) => {
    if (hidden)
      return val.split('').map((w, wi, a) =>
        (wi === 0 || (wi === a.length - 1) || !(wi % 4) || w === '-') ? w : 'X')
        .join('')
    return val
  }

  const ApiRow = ({ env }) => <TableRow>
    <TableCell><EnvItem env={env} /></TableCell>
    <TableCell>
      {apiKeys[env] ? <TextField readOnly fullWidth
        size="small"
        value={mask(keyMasks[env], apiKeys[env])}
        InputProps={{
          sx: { paddingRight: 1 },
          variant: 'clipboard',
          endAdornment: <>
            <InputAdornment position='end'>
              <IconButton onClick={() => setKeyMasks({
                ...keyMasks,
                [env]: !keyMasks[env]
              })}>
                <FontAwesomeIcon fixedWidth
                  icon={`far fa-eye${keyMasks[env] ? '' : '-slash'}`}
                />
              </IconButton>
            </InputAdornment>
            <InputAdornment position='end'>
              <Tooltip title="Copy to clipboard">
                <IconButton color="primary" onClick={e => handleCopy(apiKeys[env])}>
                  <FontAwesomeIcon icon="far fa-copy" />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          </>
        }}
      /> : <Typography component="i">
        API key has not been generated for this environment.
      </Typography>}
    </TableCell>
    <TableCell><Button variant="outlined" fullWidth
      onClick={() => handleGenerateApiKey(env)}
      startIcon={<FontAwesomeIcon icon="far fa-gear" />}
    >
      {apiKeys[env] ? 'Regenerate' : 'Generate'}
    </Button></TableCell>
  </TableRow>

  return <Box p={2}>
    <Typography variant="h5">API Credentials</Typography>
    <Table>
      <TableHead><TableRow>
        <TableCell>Environment</TableCell>
        <TableCell>API Key</TableCell>
        <TableCell />
      </TableRow></TableHead>
      <TableBody>
        <ApiRow env="staging" />
        <ApiRow env="sandbox" />
        <ApiRow env="production" />
      </TableBody>
    </Table>
    <Typography variant="h5" mt={5}>SFTP Credentials</Typography>
    <Table>
      <TableHead><TableRow>
        <TableCell>Environment</TableCell>
        <TableCell>Host Name</TableCell>
        <TableCell>Port #</TableCell>
        <TableCell>Username</TableCell>
        <TableCell />
      </TableRow></TableHead>
      <TableBody>
        {Object.entries(sftp).map(([env, d], i) => <TableRow key={i}>
          <TableCell><EnvItem env={env} /></TableCell>
          <TableCell>{sftp[env].hostname || '--'}</TableCell>
          <TableCell>{sftp[env].port || '--'}</TableCell>
          <TableCell>
            {sftp[env].username ? <TextField readOnly fullWidth
              size="small"
              value={mask(sftp[env].hidden, sftp[env].username)}
              InputProps={{
                sx: { paddingRight: 1 },
                variant: 'clipboard',
                endAdornment: <>
                  <InputAdornment position='end'>
                    <IconButton onClick={() => setSFTP({
                      ...sftp,
                      [env]: { ...sftp[env], hidden: !sftp[env].hidden }
                    })}>
                      <FontAwesomeIcon fixedWidth
                        icon={`far fa-eye${sftp[env].hidden ? '' : '-slash'}`}
                      />
                    </IconButton>
                  </InputAdornment>
                  <InputAdornment position='end'>
                    <Tooltip title="Copy to clipboard">
                      <IconButton color="primary" onClick={e => handleCopy(sftp[env].username)}>
                        <FontAwesomeIcon icon="far fa-copy" />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                </>
              }}
            /> : <Typography component="i">
              Username has not been generated.
            </Typography>}
          </TableCell>
          <TableCell><Button variant="outlined" fullWidth
            onClick={() => setSFTP({
              ...sftp,
              [env]: { ...sftp[env], username: generateUsername() }
            })}
            startIcon={<FontAwesomeIcon icon="far fa-gear" />}
          >
            {sftp[env].username ? 'Regenerate' : 'Generate'}
          </Button></TableCell>
        </TableRow>)}
      </TableBody>
    </Table>
  </Box>
}

export default CredentialsPage