import { Box, Button, Dialog, DialogActions, DialogContent, Grid, MenuItem, Skeleton, TextField, Typography } from '@mui/material'
import React, { useMemo, useEffect, useState } from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { DialogTitle, SearchField } from 'src/components'
import { DATAMODEL } from './form-validation'
import { API_GetDataModelDropDownList } from 'src/constants/api'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useArrayTableSearch } from 'src/hooks/useUtils'
import { EditTable } from 'src/components/DataTable'

const AddBtn = props => {
  const { append, fields } = useFieldArray({ name: 'ApprovalEmailAddressData' })

  return <Button variant='outlined' {...props}
    onClick={() => append({ Name: '', Email: '', index: fields.length })}
    startIcon={<FontAwesomeIcon icon='far fa-plus' />}
  >
    Add Committee Member
  </Button>
}

function DialogView({ open, ...props }) {
  const methods = useForm(DATAMODEL)
  const { reset, register, watch } = methods
  const [loading, setLoading] = useState(true)
  const [options, setOptions] = useState({ DataModelNameList: [], DataProviderList: [] })
  const handleSearch = useArrayTableSearch('ApprovalEmailAddressData', 'Name', methods)


  const item = useMemo(() => typeof open === 'boolean' ? false : open, [open])

  const getOptions = () => {
    setLoading(true)
    axios(API_GetDataModelDropDownList)
      .then(res => {
        setOptions(res.data)
      }).finally(() => setLoading(false))
  }

  useEffect(() => {
    if (item)
      reset({
        ...item,
        ApprovalEmailAddressData: item.ApprovalEmailAddressData.map((x, index) => ({
          ...x, hidden: false, index
        }))
      })
    else
      reset(DATAMODEL.defaultValues)
    getOptions()
    // eslint-disable-next-line
  }, [item])



  const submitForm = data => console.log(data)

  return <FormProvider {...methods}><form onSubmit={methods.handleSubmit(submitForm)}>
    <Dialog fullWidth maxWidth='lg' open={Boolean(open)} {...props}>
      <DialogTitle onClose={props.onClose}>
        {Boolean(item) ? 'Edit' : 'Add'} Data Model Owner Configuration
      </DialogTitle>
      <PerfectScrollbar><DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            {loading ? <Skeleton /> : <TextField select fullWidth
              {...register('DesignateDataModelId')}
              value={watch('DesignateDataModelId')}
              label='Data Model Name'
            >
              {options.DataModelNameList?.map(({ Text, Value }) => <MenuItem
                key={Value} value={Value}
              >
                {Text}
              </MenuItem>)}
              {/* TEST DEMO ONLY */}
              {item && <MenuItem value={item.DesignateDataModelId}>
                {item.DataModelName}
              </MenuItem>}
            </TextField>}
          </Grid>
          <Grid item xs={12} sm={6}>
            {loading ? <Skeleton /> : <TextField select fullWidth
              {...register('OrganisationId')}
              value={watch('OrganisationId')}
              label='Data Model Provider Name'
            >
              {options.DataProviderList?.map(({ Text, Value }) => <MenuItem
                key={Value} value={Value}
              >
                {Text}
              </MenuItem>)}
              {/* TEST DEMO ONLY */}
              {item && <MenuItem value={item.OrganisationId}>
                {item.DataModelProviderName}
              </MenuItem>}
            </TextField>}
          </Grid>
        </Grid>
        <Typography variant='subtitle1' sx={{ mt: 2 }}>
          Data Owner Committee Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs>
            <SearchField onChange={handleSearch} />
          </Grid>
          <Grid item><AddBtn /></Grid>
        </Grid>
        <EditTable hidePagination
          name='ApprovalEmailAddressData'
          columns={[
            { field: 'Name' },
            { field: 'Email' }
          ]}
          data={watch('ApprovalEmailAddressData')}
        />
        <Box my={2} textAlign='center'><AddBtn /></Box>
      </DialogContent></PerfectScrollbar>
      <DialogActions>
        <Button onClick={props.onClose}>
          Cancel
        </Button>
        <Button onClick={() => reset()} variant='outlined'>
          Reset
        </Button>
        <Button variant='contained' onClick={methods.handleSubmit(submitForm)}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  </form></FormProvider>
}

export default DialogView