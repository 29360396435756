import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Collapse, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemButton, Typography } from '@mui/material'
import React, { useContext, useMemo, useState } from 'react'
import { DDContext } from './MainView'

function DataListItem({ onAdd, ...props }) {
  const [open, setOpen] = useState(false)
  const { activeItem, setActiveItem, setIsEntity } = useContext(DDContext)

  const isHeaderActive = useMemo(
    () => activeItem && (activeItem.DataModel === props.DataModel || activeItem.DataModel.DataModel === props.DataModel),
    [activeItem, props]
  )

  const handleClick = () => {
    setIsEntity(false)
    setActiveItem(props)
    if (props.Entities?.length)
      setOpen(!open)
  }

  const handleEntityClick = entity => {
    setIsEntity(true)
    setActiveItem({ ...entity, DataModel: props })
  }

  return <>
    <ListItem divider={open} disablePadding>
      <ListItemButton onClick={handleClick}>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs>
            <Typography
              color={isHeaderActive ? 'secondary.main' : 'text.primary'}
              fontWeight={isHeaderActive ? 'fontWeightBold' : 'fontWeightNormal'}
            >
              {props.DataModel}
            </Typography>
          </Grid>
          <Grid item><IconButton sx={theme => ({
            transition: theme.transitions.create(),
            [open && 'transform']: 'rotate(180deg)'
          })}>
            <FontAwesomeIcon icon='fal fa-chevron-down' />
          </IconButton></Grid>
        </Grid>
      </ListItemButton>
    </ListItem>
    <Collapse in={open}><List disablePadding sx={{ backgroundColor: 'primary.hover' }}>
      {props.Entities.map((ent, ei, arr) => <ListItem
        key={ent.Id} divider={ei === arr.length - 1}
        disablePadding
        sx={theme => ({
          transition: theme.transitions.create(),
          ...activeItem?.Entity === ent.Entity ? {
            fontWeight: 'fontWeightBold',
            color: 'secondary.dark'
          } : {}
        })}
      ><ListItemButton onClick={() => handleEntityClick(ent)}>
          <ListItemAvatar sx={{
            color: ent.HasRelationship ? 'info.main' : 'secondary.light'
          }}>
            <FontAwesomeIcon
              icon={`fas fa-${ent.HasRelationship ? 'diamond' : 'rectangle-wide'}`}
              fixedWidth
            />
          </ListItemAvatar>
          {ent.Entity}
        </ListItemButton></ListItem>)}
      <ListItem divider disablePadding><ListItemButton
        sx={theme => ({
          backgroundColor: 'primary.200',
          transition: theme.transitions.create(),
          '&:hover': { backgroundColor: 'primary.100' }
        })}
        onClick={() => onAdd(props)}
      >
        <Box mx='auto' py={.5} fontWeight='fontWeightBold' color='primary.dark'>
          <Grid container spacing={2}>
            <Grid item>
              <FontAwesomeIcon icon='far fa-plus' />
            </Grid>
            <Grid item>Add Entity</Grid>
          </Grid>
        </Box>
      </ListItemButton></ListItem>
    </List></Collapse>
  </>
}

export default DataListItem