import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

export const searchForm = {
  resolver: yupResolver(yup.object({
    ReferenceNumber: yup.string(),
    SubmissionByOrganisation: yup.string(),
    FormType: yup.string(),
    Status: yup.string(),
    SubmissionDate: yup.date(),
    SubmissionByEmail: yup.string(),
  })),
  defaultValues: {
    ReferenceNumber: '',
    SubmissionByOrganisation: '',
    FormType: '',
    Status: '',
    SubmissionDate: '',
    SubmissionByEmail: '',
  }
}