import { Chip, Grid, Typography } from '@mui/material'
import React from 'react'
import { DataTable, SearchField } from 'src/components'
import { PERMISSIONS_LIST_2 } from 'src/constants'
import { useDataTableUtils } from 'src/hooks/useUtils'

function ServicesTab(props) {
  const { loading, data, PaginationProps } = useDataTableUtils('/testdata/svc_permissions.json')

  const handleSearch = e => {
    console.log("search for:", e.target.value)
    // For example, getData(searchTerm)
  }

  return <>
    <SearchField fullWidth label="Search"
      onChange={handleSearch} sx={{ my: 2 }}
    />
    <DataTable
      loading={loading}
      PaginationProps={PaginationProps}
      columns={[
        {
          field: 'entity',
          title: 'Data Model / Entity',
          render: row => <>
            <Typography>{row.DataModel}</Typography>
            <Typography fontWeight='fontWeightBold' color='secondary'>
              {row.Entity}
            </Typography>
          </>
        }, {
          field: 'Version',
          title: 'Version #'
        }, {
          title: 'Permissions',
          sort: false,
          render: row => <Grid container spacing={1}>
            {PERMISSIONS_LIST_2.map((perm, p) => <Grid item key={p}>
              <Chip label={perm.name} size='small'
                disabled={!row.Permissions.includes(perm.name)}
                sx={{ backgroundColor: perm.color + '.light' }}
              />
            </Grid>)}
          </Grid>
        }
      ]}
      data={data}
    />
  </>
}

export default ServicesTab