import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { ROUTE_LIST } from "src/routes"

const { useLocation } = require("react-router-dom")

const useBreadcrumbs = () => {
  const location = useLocation()
  const { t } = useTranslation()

  const crumbs = useMemo(() => {
    let ret = [['/', t('home')]]
    const found = ROUTE_LIST.filter(route => route.href === location.pathname)
    if (found.length === 1)
      ret.push([found[0].href, found[0].title])
    return ret
  }, [location, t])


  return { crumbs }
}

export default useBreadcrumbs