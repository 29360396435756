import { Button, Dialog, DialogActions, DialogContent, Grid } from '@mui/material'
import React from 'react'
import { DialogTitle } from '.'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function DeleteWarningDialog({ onConfirm, children, title, labels = { confirm: 'Proceed', cancel: 'Cancel' }, ...props }) {

  return <Dialog fullWidth {...props}>
    <DialogTitle onClose={props.onClose} alignment='start'>
      <Grid container spacing={1} width='auto' alignItems='center' flexWrap='nowrap'>
        <Grid item sx={{ color: 'warning.main' }}>
          <FontAwesomeIcon icon='fas fa-triangle-exclamation' size='2x' />
        </Grid>
        <Grid item>{title}</Grid>
      </Grid>
    </DialogTitle>
    <DialogContent>
      {children}
    </DialogContent>
    <DialogActions>
      <Button onClick={props.onClose}>{labels.cancel || 'Cancel'}</Button>
      <Button variant='contained' onClick={onConfirm} color='error'>
        {labels.confirm || 'Proceed'}
      </Button>
    </DialogActions>
  </Dialog>
}

export default DeleteWarningDialog