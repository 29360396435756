import React from 'react'
import { Outlet } from 'react-router-dom'
import { MainLayout } from 'src/layouts'
import { ROUTE_LIST } from './routelist'

function Root(props) {
  return <MainLayout>
    <Outlet />
  </MainLayout>
}

const mapNames = route => {
  let ret = {
    path: route.href,
    element: route.element,
    ...route.RouteProps,
  }
  if (route.children)
    ret.children = route.children.map(mapNames)
  return ret
}

const ROUTES = [
  {
    path: "/",
    element: <Root />,
    // errorElement: <ErrorPage />,
    children: ROUTE_LIST.map(mapNames)
  },
]
export { ROUTES, ROUTE_LIST }