import { Box, Chip, Grid, Link, Typography } from '@mui/material'
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import DataListLayout from 'src/layouts/DataListLayout'

import MODELS from 'src/testdata/organization_models'
import { useTranslation } from 'react-i18next'
import DataListItem from './DataListItem'
import { DD_ENTITY_TABS, DD_TABS } from 'src/constants'
import SummaryPage from './SummaryPage'
import TermsPage from './TermsPage'
import { getActiveStateColor } from 'src/constants/utils'
import { ENTITY_PAGES } from './Entity'
import { DXPContext } from 'src/layouts/MainLayout'
import AttrViewDialog from './Entity/AttrViewDialog'
import AddRelationshipDialog from './Entity/AddRelationshipDialog'
import AddEntityDialog from './Entity/AddEntityDialog'
import PublishDialog from './PublishDialog'
import ModelDialog from './ModelDialog'
import HistoryPage from './HistoryPage'
import VersionPage from './VersionPage'

const PAGES = [
  [
    <SummaryPage />,
    <VersionPage />,
    <HistoryPage />,
    <TermsPage />
  ], ENTITY_PAGES
]

const HEADER_ACTIONS = {
  model: [
    {
      label: `Edit Data Model`,
      icon: "pen-to-square",
      // onClick: () => setAddDialogOpen(activeItem)
    }, {
      label: 'Add Data Entity',
      icon: 'file-plus'
    }, {
      label: 'Publish Data Model',
      icon: 'octagon-check'
    }
  ],
  entity: [
    {
      label: `Edit Data Entity`,
      icon: "pen-to-square",
    }, {
      label: 'Add Data Entity Relationship',
      icon: 'network-wired'
    }, {
      label: 'Add Data Entity Attribute',
      icon: 'list-tree'
    }
  ]
}

const renderTab = (index, isEntity) => {
  const pageset = PAGES[isEntity ? 1 : 0]
  if (index < pageset.length && pageset[index])
    return pageset[index]
  return <Box p={2}>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nec tellus nec risus dictum tempus. In nec ante odio. Suspendisse eleifend sodales nisl, sit amet scelerisque diam scelerisque id. Cras auctor congue felis non tincidunt. Donec scelerisque porttitor quam a luctus. Sed malesuada aliquam lectus, eu imperdiet lorem dictum quis. Mauris volutpat leo varius magna porttitor ultricies. Etiam rhoncus aliquam odio a sodales. Nullam lobortis faucibus odio, eu scelerisque velit volutpat quis. Maecenas est risus, semper eget velit rhoncus, cursus vehicula magna. Sed varius vitae nulla vulputate lobortis. Vestibulum eu ipsum diam. Etiam non ultricies turpis, lacinia euismod leo. Maecenas bibendum non massa vitae rutrum.</p>
  </Box>
}

export const DDContext = createContext(null)

function DDMainView(props) {

  const { t } = useTranslation()
  const [activeItem, setActiveItem] = useState(null)
  const [isEntity, setIsEntity] = useState(false)
  const [isNew, setNew] = useState(false)
  const [openAttr, setOpenAttr] = useState(false)
  const [openRship, setOpenRship] = useState(false)
  const [openEntity, setOpenEntity] = useState(false)
  const [openPublish, setOpenPublish] = useState(false)
  const [openModel, setOpenModel] = useState(false)
  const { activeTab, setActiveTab } = useContext(DXPContext)
  const tabContent = useMemo(() => renderTab(activeTab, isEntity), [isEntity, activeTab])

  const activeItemChipProps = useMemo(() => ((activeItem && !isEntity) ? {
    label: activeItem.Status,
    color: getActiveStateColor(activeItem.Status)
  } : {}), [activeItem, isEntity])

  const headerActions = useMemo(() => {
    if (activeItem)
      return HEADER_ACTIONS[isEntity ? 'entity' : 'model']
    return []
  }, [activeItem, isEntity])

  const handleCrumbClick = () => {
    setIsEntity(false)
    setActiveItem(activeItem.DataModel)
  }

  const handleHeaderAction = actionIndex => {
    // action based on `isEntity` and `actionIndex`
    if (isEntity)
      [setOpenEntity, setOpenRship, setOpenAttr][actionIndex](true)
    else
      [setOpenModel, setOpenEntity, setOpenPublish][actionIndex](true)
  }

  useEffect(() => setActiveTab(0), [activeItem, setActiveTab])

  return <DDContext.Provider value={{
    activeItem, setActiveItem,
    isEntity, setIsEntity
  }}>
    <DataListLayout
      ListProps={{
        header: 'Data Definitions',
        items: MODELS,
        renderItem: (listItem, li, isNew) => <DataListItem
          {...listItem}
          isNew={isNew}
          onAdd={dm => { setNew(dm); setOpenEntity(true) }}
        />,
        displayKey: 'DataModel',
        sortKeys: [
          { key: "Created", name: "date", icon: "calendar-arrow-down", reverseIcon: "calendar-arrow-up" },
          { key: "DataModel", reverseIcon: "arrow-down-a-z", icon: "arrow-down-z-a" }
        ],
        InteractionProps: { children: t('common.addThing', { thing: 'Data Model' }) },
        onInteract: () => {
          setNew(true)
          setOpenModel(true)
        }
      }}
      HeaderProps={{
        title: activeItem ? <>
          <Grid container spacing={2} alignItems='center'>
            <Grid item>{activeItem[isEntity ? 'Entity' : 'DataModel']}</Grid>
            {!isEntity && <Grid item>
              <Chip {...activeItemChipProps} />
            </Grid>}
          </Grid>
          <Typography variant='body1'>
            Published Version - {activeItem.Version}
          </Typography>
        </> : "Data Models",
        actions: headerActions.map((ha, hi) => ({
          ...ha,
          onClick: () => handleHeaderAction(hi)
        })),
        tabs: isEntity ? DD_ENTITY_TABS : DD_TABS,
        [isEntity && 'renderCrumbExtras']: () => <Link
          underline="hover" color="inherit"
          onClick={handleCrumbClick}
          sx={{ cursor: 'pointer' }}
        >
          {activeItem.DataModel.DataModel}
        </Link>,
      }}
    >

      {activeItem
        ? tabContent
        : <Typography p={2}>Please select a data model or entity.</Typography>
      }

      {activeItem && <AddEntityDialog
        open={openEntity} onClose={() => {
          setOpenEntity(false); setNew(false)
        }}
        createMode={isNew || !isEntity}
      />}

      {activeItem && isEntity && <>
        <AttrViewDialog open={openAttr} onClose={() => setOpenAttr(false)} />
        <AddRelationshipDialog open={openRship} onClose={() => setOpenRship(false)} />
      </>}

      {activeItem && !isEntity && <>
        <PublishDialog open={openPublish} onClose={() => setOpenPublish(false)} />
      </>}
      <ModelDialog open={openModel} onClose={() => {
        setOpenModel(false); setNew(false)
      }}
        createMode={isNew}
      />
    </DataListLayout>

  </DDContext.Provider>
}

export default DDMainView