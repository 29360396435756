import React from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'

import { DataTable, SearchField } from 'src/components'
import { useDataTableUtils } from 'src/hooks/useUtils'

function PIPage(props) {
  const { loading, data, PaginationProps } = useDataTableUtils('/testdata/organization_permissions.json')

  return <Box p={2}>
    <Grid container spacing={1} alignItems="center">
      <Grid item xs>
        <SearchField />
      </Grid>
      <Grid item>
        <Button variant="outlined">Manage PII Assignment</Button>
      </Grid>
    </Grid>
    <DataTable
      loading={loading}
      PaginationProps={PaginationProps}
      columns={[
        {
          field: 'entity',
          title: 'Data Model / Entity',
          render: row => <>
            <Typography>{row.dataModel}Model</Typography>
            <Typography fontWeight='fontWeightBold' color='secondary'>
              {row.entity}Entity
            </Typography>
          </>
        }, {
          title: 'Attributes',
          sort: false,
          render: row => [...new Set(row.attributes.flatMap(at => at.items))].join(', ')
        }, {
          field: 'version',
          title: 'Version'
        }
      ]}
      data={data}
    />
  </Box>
}

export default PIPage