import {
  List,
  ListItem,
  ListItemButton,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { MonitoringContext } from "./MainView";

function DataListItem({ onAdd, ...props }) {
  const { activeItem, setActiveItem } = useContext(MonitoringContext);

  const handleJobClick = (entity) => {
    setActiveItem({ ...entity, DataModel: props });
  };

  return (
    <>
      <ListItem disablePadding>
        <Typography
          color={"text.primary"}
          fontWeight={"fontWeightNormal"}
          sx={{ margin: "10px 0px 5px 15px", fontSize: '10px', fontWeight: 'fontWeightBold' }}
        >
          {props.JobCategory}
        </Typography>
      </ListItem>
      <List disablePadding sx={{}}>
        {props.Jobs.map((job, ei, arr) => (
          <ListItem
            key={job.Id}
            divider={ei === arr.length - 1}
            disablePadding
            sx={(theme) => ({
              transition: theme.transitions.create(),
              ...(activeItem?.JobName === job.JobName
                ? {
                    fontWeight: "fontWeightBold",
                    color: "secondary.dark",
                  }
                : {}),
            })}
          >
            <ListItemButton onClick={() => handleJobClick(job)} sx={{ fontSize: '14px' }}>
              {job.JobName}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </>
  );
}

export default DataListItem;
