import { createRef, useMemo } from "react";
import * as d3 from "d3";
import { ResponsiveChartContainer, useDrawingArea } from "@mui/x-charts";
import { useTheme } from "@mui/material";

const MARGIN = { top: 60, right: 60, bottom: 60, left: 60 };

export const DendrogramChart = ({ data, color }) => {
  const { width, height } = useDrawingArea()
  const { palette } = useTheme()
  const boundsWidth = width - 38 - MARGIN.right - MARGIN.left;
  const boundsHeight = height + 100 - MARGIN.top - MARGIN.bottom;

  // events
  const handlePan = node => {
    console.log(node)
  }

  const hierarchy = useMemo(() => {
    return d3.hierarchy(data).sum((d) => d.value);
  }, [data]);

  const dendrogram = useMemo(() => {
    const dendrogramGenerator = d3
      .cluster()
      .size([boundsHeight, boundsWidth]);
    return dendrogramGenerator(hierarchy);
  }, [hierarchy, boundsHeight, boundsWidth]);

  const allNodes = dendrogram.descendants().map((node, i) => <g
    key={'node' + i}
    onClick={() => handlePan(node)}
  >
    <circle
      cx={node.y}
      cy={node.x}
      r={5}
      stroke="transparent"
      fill={node.data.color || color || palette.primary.main}
    />

    <text
      x={node.y + (node.children ? 0 : 15)}
      y={node.x + (node.children ? 15 : 0)}
      fontSize={12}
      textAnchor={node.children ? 'middle' : 'left'}
      alignmentBaseline="middle"
    >
      {node.data.name}
    </text>
  </g>)

  const horizontalLinkGenerator = d3.linkHorizontal();

  const allEdges = dendrogram.descendants().filter(node => node.parent)
    .map((node, i) => <path key={'path' + i}
      fill="none"
      stroke="grey"
      d={horizontalLinkGenerator({
        source: [node.parent.y, node.parent.x],
        target: [node.y, node.x],
      })}
    />)

  return <g
    width={boundsWidth}
    height={boundsHeight}
    transform={`translate(${[MARGIN.left, MARGIN.top].join(",")})`}
  >
    {allEdges}
    {allNodes}
  </g>
}

export const Dendrogram = ({ color, ...props }) => {
  const ref = createRef(null)

  // TODO: interactions

  return <ResponsiveChartContainer series={[]} {...props} ref={ref}>
    <DendrogramChart data={props.data} color={color} />
  </ResponsiveChartContainer>
}