import React, { useContext, useMemo, useState } from 'react'
import {
  Breadcrumbs,
  Button,
  Grid,
  Link,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  Typography
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import useBreadcrumbs from 'src/hooks/useBreadcrumbs'
import { DXPContext } from '../MainLayout'

function Header({
  title, subtitle, actions,
  tabs, onTabChange,
  renderCrumbExtras,
  ...props
}) {
  const { crumbs } = useBreadcrumbs()
  const [anchorEl, setAnchorEl] = useState(null)
  const { activeTab, setActiveTab } = useContext(DXPContext)

  const safeTabIndex = useMemo(
    () => activeTab < tabs?.length ? activeTab : 0,
    [activeTab, tabs]
  )

  const handleTabChange = (e, val) => {
    if (val >= tabs.length)
      val = 0
    setActiveTab(val)
    if (onTabChange)
      onTabChange(val)
  }

  const handleClose = () => setAnchorEl(null)

  return <>
    <Paper square sx={theme => ({
      background: theme.palette.primary[100],
      px: 2.5, pt: 2, pb: 0, mb: .5
    })}>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Breadcrumbs aria-label="breadcrumb">
            {crumbs.map(([path, name], c) => <Link
              underline="hover" color="inherit"
              href={path} key={c}
            >
              {name}
            </Link>)}
            {renderCrumbExtras && renderCrumbExtras()}
          </Breadcrumbs>
        </Grid>
        <Grid item><Grid container spacing={2} mb={tabs ? 0 : 2}>
          <Grid item xs>
            <Typography variant="h3" component="div">
              {title || crumbs[crumbs.length - 1][1]}
            </Typography>
            {subtitle && <Typography variant='subtitle2' component="div">
              {subtitle}
            </Typography>}
          </Grid>
          {(actions && !!actions.length) && <Grid item>
            <Button variant="outlined" color="secondary"
              endIcon={<FontAwesomeIcon icon="far fa-chevron-down" />}
              onClick={e => Boolean(actions && actions.length) && setAnchorEl(e.currentTarget)}
              id="actions-button"
            >
              Actions
            </Button>
            {Boolean(actions && actions.length) && <Menu
              id="actions-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'actions-button',
                disablePadding: true
              }}
            >
              {actions.map((action, a) => {
                let aProps = { ...action }

                // interchangeable `label` & `children`
                if (!action.children && action.label) {
                  aProps.children = action.label
                  delete aProps.label

                  // display icons
                  if (action.icon) {
                    let [icon, color] = Array.isArray(action.icon)
                      ? action.icon
                      : [action.icon, 'grey.600']
                    aProps.children = <>
                      <ListItemIcon sx={{ color }}>
                        <FontAwesomeIcon icon={`far fa-${icon}`} />
                      </ListItemIcon>
                      {aProps.children}
                    </>
                    delete aProps.icon
                  }
                }

                // replace onClick
                if (action.onClick)
                  aProps.onClick = e => {
                    action.onClick(e, action);
                    handleClose()
                  }
                else
                  aProps.onClick = () => handleClose()
                return <MenuItem {...aProps} key={a} />
              })}
            </Menu>}
          </Grid>}
        </Grid></Grid>
        {tabs && <Grid item>
          <Tabs value={safeTabIndex} onChange={handleTabChange} aria-label="navigation">
            {tabs.map(({ name, icon }, t) => <Tab key={t}
              label={name}
              icon={<FontAwesomeIcon icon={`far fa-${icon}`} />}
              iconPosition='start'
            />)}
          </Tabs>
        </Grid>}
      </Grid>
    </Paper>
  </>
}

export default Header