import { Button, Checkbox, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, FormGroup, Grid, InputAdornment, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material'
import PerfectScrollbar from 'react-perfect-scrollbar'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { notification } from '../form-validation'
import TESTDATA from 'src/testdata/svc_sftp-COPY'
import TESTENTITIES from 'src/testdata/svc_entities'
import { useFieldUtils } from 'src/hooks/useUtils'
import { DialogTitle } from 'src/components'

const DATAMODELS = TESTDATA.map(x => x.DataModel)
const TEST_ENTITIES = TESTENTITIES.splice(Math.floor(Math.random() * 290), Math.floor(Math.random() * 6) + 1)


function DataUpdateDialog({ item, inbound, ...props }) {
  const { handleSubmit, register, trigger, formState: { errors }, getValues } = useForm(notification)
  const { errorState } = useFieldUtils({ errors })

  const submitForm = data => {
    data.Entities = data.Entities.filter(e => e.Entity)
    console.log(data)
  }

  useEffect(() => console.log('e', errors), [errors])

  return <form onSubmit={handleSubmit(submitForm, console.log)}>
    <Dialog maxWidth="lg" {...props}>
      <DialogTitle onClose={props.onClose}>
        Data Update Notification Subscription(s)
      </DialogTitle>
      <PerfectScrollbar>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={9} md={6}>
              <TextField select fullWidth
                label='Data Model'
                {...register('DataModel')}
                {...errorState('DataModel')}
                value={getValues('DataModel')}
              >
                {DATAMODELS.map((dm, d) => <MenuItem
                  key={d} value={dm}
                >
                  {dm}
                </MenuItem>)}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <TextField select fullWidth
                label='Version'
                {...register('Version')}
                {...errorState('Version')}
                value={getValues('Version')}
              >
                {[...Array(5)].map((e, i) => <MenuItem
                  key={i} value={i + 1}
                >
                  {i + 1}
                </MenuItem>)}
              </TextField>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormControl onChange={() => trigger('Entities')} component="fieldset"><FormGroup>
              {errors && errors['Entities'] && <Typography variant='caption' color='error.main'>
                {errors['Entities'].root?.message || errors['Entities'].message}
              </Typography>}
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Data Entity</TableCell>
                    <TableCell>Production URLs</TableCell>
                    <TableCell>Sandbox URLs</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {TEST_ENTITIES.map((ent, e) => <TableRow key={e}>
                    <TableCell>
                      <FormControlLabel control={<Checkbox />}
                        label={ent.Entity}
                        {...register(`Entities.${e}.Entity`)}
                        value={ent.Entity}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        {...register(`Entities.${e}.ProductionURL`)}
                        {...errorState(`Entities.${e}.ProductionURL`)}
                        InputProps={{
                          startAdornment: <InputAdornment position='start'>
                            https://
                          </InputAdornment>
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        {...register(`Entities.${e}.SandboxURL`)}
                        {...errorState(`Entities.${e}.SandboxURL`)}
                        InputProps={{
                          startAdornment: <InputAdornment position='start'>
                            https://
                          </InputAdornment>
                        }}
                      />
                    </TableCell>
                  </TableRow>)}
                </TableBody>
              </Table>
            </FormGroup></FormControl>
          </Grid>
        </DialogContent>
      </PerfectScrollbar>
      <DialogActions>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button variant='contained' onClick={handleSubmit(submitForm)}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  </form>
}

export default DataUpdateDialog