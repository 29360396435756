import { AgencyDesignatesView, DashboardView, DefinitionsView, EnquiriesView, InboxView, MonitoringView, OrganizationsView } from "src/views";

import { SidebarIcon } from "src/layouts/components/Sidebar"

export const ROUTE_LIST = [
  {
    title: 'Dashboard',
    Icon: <SidebarIcon icon='display' />,
    href: '/',
    element: <DashboardView />,
    RouteProps: { exact: true }
  }, {
    title: 'Inbox',
    Icon: <SidebarIcon icon='inbox-in' />,
    href: '/inbox',
    element: <InboxView />
  }, {
    title: 'Enquiries',
    Icon: <SidebarIcon icon='messages-question' />,
    href: '/enquiries',
    element: <EnquiriesView />
  }, {
    title: 'Organizations',
    Icon: <SidebarIcon icon='building-columns' />,
    href: '/organizations',
    element: <OrganizationsView />
  }, {
    title: 'Data Definitions',
    Icon: <SidebarIcon icon='sitemap' />,
    href: '/data-definitions',
    element: <DefinitionsView />
  }, {
    title: 'Admin',
    Icon: <SidebarIcon icon='user-gear' />,
    children: [
      {
        title: 'User Management',
        href: 'https://www.crimsonlogic.com/',
        external: true,
        Icon: <SidebarIcon icon='users' />
      }, {
        title: 'System Configuration',
        href: 'https://www.crimsonlogic.com/',
        external: true,
        Icon: <SidebarIcon icon='gears' />
      }
    ]
  }, {
    title: 'Agency Designates',
    Icon: <SidebarIcon icon='building-user' />,
    href: '/agency-designates',
    element: <AgencyDesignatesView />
  }, {
    title: 'Monitoring',
    Icon: <SidebarIcon icon='telescope' />,
    href: '/monitoring',
    element: <MonitoringView />
  }, {
    title: 'Reports',
    Icon: <SidebarIcon icon='chart-mixed' />,
    href: '/reports'
  }
]