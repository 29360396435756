import { FormControl, FormLabel, Grid, MenuItem, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { CARDINALITY_TYPE, RELATIONSHIP_TYPE } from 'src/constants'

import { DDContext } from '../../MainView'

function AddRelationshipMainForm(props) {
  const [rshipType, relatedEntity, cardinality] = useWatch({
    name: [
      'RelationshipType', 'RelatedEntityName', 'Cardinality'
    ]
  })
  const { register, setValue } = useFormContext()
  const { activeItem: { DataModel: { Entities }, Entity } } = useContext(DDContext)

  useEffect(() => {
    const related = Entities.find(x => x.Entity === relatedEntity)
    setValue('relatedEntityObj', related)
  }, [Entities, relatedEntity, setValue])

  return <Grid container spacing={2} columns={14}>
    <Grid item xs={14} sm={5}>
      <Typography variant='caption' color='text.secondary'>
        Current Entity
      </Typography>
      <Typography fontWeight='fontWeightBold'>
        {Entity}
      </Typography>
    </Grid>
    <Grid item xs={14} sm={4}>
      <FormControl fullWidth>
        <FormLabel required><Typography variant='caption' color='text.secondary'>
          Relationship Type
        </Typography></FormLabel>
        <ToggleButtonGroup exclusive color='primary'
          onChange={(e, val) => setValue('RelationshipType', val)}
          value={rshipType}
          fullWidth size='small'
        >
          {RELATIONSHIP_TYPE.map(r => <ToggleButton value={r} key={r}>
            {r}
          </ToggleButton>)}
        </ToggleButtonGroup>
      </FormControl>
      {rshipType === 'Has A' && <TextField label="Cardinality"
        {...register('Cardinality')}
        value={cardinality}
        fullWidth required select
        sx={{ mt: 1 }}
      >
        {CARDINALITY_TYPE.map(x => <MenuItem key={x}
          value={x}
        >
          {x}
        </MenuItem>)}
      </TextField>}
    </Grid>
    <Grid item xs={14} sm={5}>
      <FormControl fullWidth>
        <FormLabel required><Typography variant='caption' color='text.secondary'>
          Related Entity
        </Typography></FormLabel>
        <TextField select
          {...register('RelatedEntityName')}
          value={relatedEntity}
          fullWidth required
        >
          {Entities.map(({ Entity }) => <MenuItem key={Entity}
            value={Entity}
          >
            {Entity}
          </MenuItem>)}
        </TextField>
      </FormControl>
    </Grid>

  </Grid>
}

export default AddRelationshipMainForm