import { Stack } from '@mui/material'
import React, { useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import Header from 'src/layouts/components/Header'
import DataModelTab from './DataModelTab'
import SubscriberTab from './SubscriberTab'

function MainView(props) {
  const [tabIndex, setTabIndex] = useState(0)

  return <Stack height='100%'>
    <Header title='Agency Designates'
      tabs={[
        { "icon": "network-wired", "name": "Data Model Owner" },
        { "icon": "file-certificate", "name": "Authorised Subscriber Organisation" },
      ]}
      onTabChange={setTabIndex}
    />
    <PerfectScrollbar>
      {tabIndex === 0 ? <DataModelTab /> : <SubscriberTab />}
    </PerfectScrollbar>
  </Stack>
}

export default MainView