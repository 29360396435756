import {
  Box,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";

import { DataTable } from "src/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import JobDialog from "./JobDialog";
import { useDataTableUtils } from "src/hooks/useUtils";
import { MonitoringContext } from "./MainView";

function TablePage(props) {
  const { activeItem } = useContext(MonitoringContext);

  const { loading, data, getData, PaginationProps } = useDataTableUtils(
    activeItem.LogListFile
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const handleSearch = (e) => {
    getData({ searchTerm: e.target.value });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [activeItem]);

  const jobTableWithOrgName = [
    {
      field: "OrganisationName",
      title: "Organisation Name",
      sort: false,
    },
    {
      field: "JobName",
      title: "Service Name",
      sort: false,
    },
    {
      field: "Status",
      title: "Status",
      render: (row) => (
        <>
          <Chip
            label={row.Status}
            size="small"
            disabled={false}
            sx={{ backgroundColor: "info.light" }}
          />
        </>
      ),
    },
    {
      field: "actions",
      title: "Actions",
      render: (row) => (
        <>
          <IconButton
            onClick={() => {
              setOpenDialog(true);
              setSelectedData(row);
            }}
          >
            <FontAwesomeIcon fixedWidth icon={`far fa-eye`} />
          </IconButton>
        </>
      ),
    },
  ];

  const jobTableWithoutOrgName = [
    {
      field: "JobName",
      title: "Service Name",
      sort: false,
    },
    {
      field: "Status",
      title: "Status",
      render: (row) => (
        <>
          <Chip
            label={row.Status}
            size="small"
            disabled={false}
            sx={{ backgroundColor: "info.light" }}
          />
        </>
      ),
    },
    {
      field: "actions",
      title: "Actions",
      render: (row) => (
        <>
          <IconButton
            onClick={() => {
              setOpenDialog(true);
              setSelectedData(row);
            }}
          >
            <FontAwesomeIcon fixedWidth icon={`far fa-eye`} />
          </IconButton>
        </>
      ),
    },
  ];

  const endpointTable = [
    {
      field: "EndpointService",
      title: "Endpoint Service",
    },
    {
      field: "DataModel",
      title: "Data Model",
    },
    {
      field: "DataEntity",
      title: "Data Entity",
    },
    {
      field: "VersionNumber",
      title: "Version No.",
    },
    {
      field: "Status",
      title: "Status Code",
      render: (row) => (
        <>
          <Chip
            label={row.Status}
            size="small"
            disabled={false}
            sx={{ backgroundColor: row.Status === "Success" ? "success.main" : "error.main", color: 'common.white' }}
          />
        </>
      ),
    },
    {
      field: "NumberOfRecords",
      title: "No. of Records",
    },
    {
      field: "actions",
      title: "Actions",
      render: (row) => (
        <>
          <IconButton
            onClick={() => {
              setOpenDialog(true);
              setSelectedData(row);
            }}
          >
            <FontAwesomeIcon fixedWidth icon={`far fa-eye`} />
          </IconButton>
        </>
      ),
    },
  ];

  const getColumns = () => {
    if (activeItem.DataModel.JobType === "Endpoint Monitoring") {
      return endpointTable
    } 
    else if (activeItem.DataModel.JobCategory === "Scheduled Jobs") {
      return jobTableWithOrgName
    } else return jobTableWithoutOrgName
  }

  return (
    <Box p={2}>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs>
          <TextField
            placeholder="Search by name"
            size="small"
            onChange={handleSearch}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <FontAwesomeIcon icon="far fa-search" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <DataTable
        loading={loading}
        PaginationProps={PaginationProps}
        columns={getColumns()}
        data={data}
      />
      {selectedData && (
        <JobDialog
          open={openDialog}
          onClose={() => {
            setOpenDialog(false);
          }}
          data={selectedData}
        />
      )}
    </Box>
  );
}

export default TablePage;
