import {
    Box,
    Grid,
    InputAdornment,
    TextField,
} from "@mui/material";

import { DataTable } from "src/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React  from "react";
import { useDataTableUtils } from "src/hooks/useUtils";

function LogsTable(props) {
    const { loading, data, getData, PaginationProps } = useDataTableUtils(
      "/testdata/JobMonitoringLogList/10_GetCronJobMonitoringLogDetails.json"
    );
  
  
    const handleSearch = e => {
      getData({ searchTerm: e.target.value })
    }
  
    return (
      <Box p={0}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs>
            <TextField
              placeholder="Search by name"
              size="small"
              onChange={handleSearch}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <FontAwesomeIcon icon="far fa-search" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <DataTable
          loading={loading}
          PaginationProps={PaginationProps}
          columns={[
            {
              field: "TimeStamp",
              title: "Date and Time",
            },
            {
              field: "LogLevel",
              title: "Log Level",
              sort: false,
            },
            {
              field: "Message",
              title: "Message",
            },
          ]}
          data={data}
        />
      </Box>
    );
  }
  
  export default LogsTable;
  