import { Card, CardContent, Chip, Divider, Grid, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { SCHEDULE_COLOR } from 'src/constants'
import AttributeList from './AttributeList'

const getSchedule = schedule => {
  if (schedule.Type === 'week')
    return moment()
      .day(schedule.Day).hour(schedule.Time)
      .format('dddd, ha')
  if (schedule.Type === 'month')
    return moment()
      .date(schedule.Day).hour(schedule.Time)
      .format('Do, ha')
  return moment().hour(schedule.Time)
    .format('ha')
}

function SFTPExpanded({ data }) {
  return <Card><CardContent><Grid container spacing={2} columns={14}>
    <Grid item xs={14} md={4}><Grid container spacing={1} flexWrap='wrap'>
      <Grid item xs={12}>
        <Typography variant="subtitle1">Schedule</Typography>
        <Chip variant='square' size='small'
          {...SCHEDULE_COLOR[data.Schedule.Type]}
        />&ensp;
        {getSchedule(data.Schedule)}
      </Grid>
      <Grid item xs={12} mb={-1}>
        <Typography variant="subtitle1">Effective Date</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='caption' component='p' color="textSecondary" mb={-.5}>Start</Typography>
        {moment(data.EffectiveStartDate).format()}
      </Grid>
      <Grid item xs={6}>
        <Typography variant='caption' component='p' color="textSecondary" mb={-.5}>End</Typography>
        {moment(data.EffectiveEndDate).format()}
      </Grid>
    </Grid></Grid>

    <Grid item xs={0} md><Divider orientation='vertical' /></Grid>

    <Grid item xs={14} md={4} position='relative'>
      <Grid container spacing={1} direction='column'>
        <Grid item>
          <Typography variant="subtitle1">Service Type</Typography>
          {data.ServiceType}
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">Service Number</Typography>
          {data.ServiceNumber}
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">Filter Query</Typography>
          <i>etc.</i>
        </Grid>
      </Grid>
    </Grid>

    <Grid item xs={0} md><Divider orientation='vertical' /></Grid>

    <Grid item xs={14} md={4}>
      <Grid container spacing={1} direction='column'>
        <Grid item>
          <Typography variant="subtitle1">Created</Typography>
          {data.CreatedBy}<br />
          <i>{moment(data.Created).toLocaleString()}</i>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">Modified</Typography>
          {data.ModifiedBy}<br />
          <i>{moment(data.Modified).toLocaleString()}</i>
        </Grid>
      </Grid>
    </Grid>

    <Grid item xs={14}>
      <Divider sx={{ mb: 1 }} />
      <Typography variant="subtitle1">Attributes</Typography>
      <AttributeList attrs={data.Attributes} />
    </Grid>
  </Grid></CardContent></Card>
}

export default SFTPExpanded