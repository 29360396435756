import { Box, Card, Chip, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { DataTable, SearchField } from 'src/components'
import { useDataTableUtils } from 'src/hooks/useUtils'
import AttrHistoryDialog from './AttrHistoryDialog'
import AttrViewDialog from './AttrViewDialog'
import moment from 'moment'
import { DATEFMT_SIMPLE_DATETIME } from 'src/constants'

function HistoryPage(props) {
  const { loading, data, PaginationProps } = useDataTableUtils('/testdata/entity_history.json')

  const [historyItem, setHistoryItem] = useState(null)
  const [viewItem, setViewItem] = useState(null)

  return <>
    <Box p={2}>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs>
          <SearchField />
        </Grid>
      </Grid>
      <DataTable
        loading={loading}
        PaginationProps={PaginationProps}
        columns={[
          {
            field: 'Active',
            title: 'Status',
            render: item => <Chip
              label={item.Active ? 'Active' : 'Inactive'}
              color={item.Active ? 'success' : 'default'}
              variant='outlined'
            />
          }, {
            field: 'MaxRecord',
            title: 'Max Record Returned'
          }, {
            field: 'ModifiedBy',
            title: 'Last Updated By'
          }, {
            field: 'Modified',
            title: 'Last Updated',
            type: 'datetime'
          }
        ]}
        data={data}
        expand={rowData => <Card><Grid container spacing={2} m={.5} mb={2}>
          <Grid item xs={8}>
            <Typography variant='subtitle1'>
              Description
            </Typography>
            {rowData.Description}
          </Grid>
          <Grid item>
            <Typography variant='subtitle1'>
              Created by
            </Typography>
            {rowData.CreatedBy}
            <Typography variant='body2' color='text.secondary'>
              {moment(rowData.Created).format(DATEFMT_SIMPLE_DATETIME)}
            </Typography>
          </Grid>
        </Grid></Card>}
      />
    </Box>
    <AttrHistoryDialog item={historyItem} onClose={() => setHistoryItem(null)} />
    <AttrViewDialog item={viewItem} onClose={() => setViewItem(null)} />
  </>
}

export default HistoryPage