import React from 'react'
import { Grid, Stack } from '@mui/material'
import PerfectScrollbar from 'react-perfect-scrollbar'
import Header from './components/Header'
import ListSidebar from './components/ListSidebar'

function DataListLayout({ ListProps, HeaderProps, children, ...props }) {
  return <Grid container sx={{ height: '100%' }}>
    <ListSidebar {...ListProps} />
    <Grid item xs>
      <Stack sx={{ height: '100%' }}>
        <Grid item>
          <Header {...HeaderProps} />
        </Grid>
        <Grid item xs sx={{ overflow: 'auto' }}><PerfectScrollbar>
          {children}
        </PerfectScrollbar></Grid>
      </Stack>
    </Grid>
  </Grid>
}

export default DataListLayout