import React, { useEffect, useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  List,
  ListItemButton,
  MenuItem,
  Drawer as MuiDrawer,
  Paper,
  Select,
  Tooltip,
  Typography,
  styled
} from '@mui/material'
import PerfectScrollbar from 'react-perfect-scrollbar'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { SearchField } from 'src/components'

const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
  width: open ? theme.props.listDrawer : 'auto',
  transition: theme.transitions.create(),
  border: 0,

  '& > .MuiPaper-root': {
    position: 'relative',
    zIndex: theme.zIndex.listDrawer,
  }
}))

const Toolbar = styled(Paper,
  { shouldForwardProp: prop => !['open', 'hasInteraction'].includes(prop) }
)(({ theme, open, hasInteraction }) => ({
  background: open ? theme.palette.primary[200] : theme.palette.common.white,
  transition: theme.transitions.create(),
  padding: open ? theme.spacing(1, 2, 2) : 0,
  [!open && 'boxShadow']: 'none',
  [hasInteraction && 'paddingBottom']: theme.spacing(4)
}))

const InteractionButton = styled(Button)(({ theme }) => ({
  borderRadius: 200,
  position: 'absolute',
  top: theme.spacing(-2.5),
  background: theme.palette.background.default,
  '&:hover': {
    background: theme.palette.primary[100]
  }
}))

function ListSidebar({
  header, hiddenText,
  items,
  statusKey = "Status",
  displayKey = 'Name',
  idKey = 'Id',
  sortKeys, searchFn,
  showNew, // whether to show chip on new items
  renderItem, onItemClick,
  onInteract, InteractionProps,
  ...props
}) {
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(true)
  const [sortKey, setSortKey] = useState([(sortKeys[0]?.key || "created"), false]) // true for ascending, false for descending
  const [searchTerm, setSearchTerm] = useState('')
  const [statusFilter, setStatusFilter] = useState('all')

  const [selected, setSelected] = useState(null)

  const statusList = useMemo(() => items.reduce((acc, item) => {
    acc[item[statusKey]] = (acc[item[statusKey]] || 0) + 1
    return acc
  }, {}), [items, statusKey])

  const sortedItems = useMemo(() => {
    items.sort((a, b) => {
      const [key, dir] = sortKey
      if (a[key] < b[key])
        return dir ? -1 : 1
      if (a[key] > b[key])
        return dir ? 1 : -1
      return 0
    })
    let ret = []
    if (searchFn)
      ret = items.filter(searchFn)
    else
      ret = items.filter(item => item[displayKey].toUpperCase().includes(searchTerm.toUpperCase()))

    if (statusFilter !== 'all')
      ret = items.filter(item => item[statusKey].toUpperCase() === statusFilter.toUpperCase())
    return ret
  }, [
    items, searchFn,
    searchTerm, statusFilter,
    statusKey, sortKey, displayKey
  ])

  useEffect(() => {
    if (selected === null) {
      setSelected(sortedItems[0][idKey])
      if (onItemClick)
        onItemClick(sortedItems[0])
    }
    // eslint-disable-next-line
  }, [])

  const handleSort = k => {
    const [key, dir] = sortKey
    if (k === key)
      setSortKey([key, !dir])
    else
      setSortKey([k, true])
  }

  const handleItemClick = item => {
    setSelected(item[idKey])
    if (onItemClick)
      onItemClick(item)
  }

  return <Drawer variant="permanent" open={isOpen} sx={{ height: '100%', overflow: 'auto' }}>
    <Toolbar square open={isOpen} hasInteraction={Boolean(onInteract)}>
      <Grid container spacing={1} direction="column">
        <Grid item display="flex" alignItems='center'>
          {isOpen && <Typography variant="h5">
            {header}
          </Typography>}
          <IconButton onClick={() => setIsOpen(!isOpen)}
            sx={{
              ml: isOpen ? 'auto' : 1,
              mr: isOpen ? 0 : 1,
              my: 1
            }}
          >
            <FontAwesomeIcon icon={`far fa-${isOpen ? 'out' : 'in'}dent`} />
          </IconButton>
        </Grid>
        {isOpen ? <Grid item>
          <SearchField label="Search" onChange={e => setSearchTerm(e.target.value)} />
        </Grid> : <Box
          sx={{
            writingMode: 'vertical-rl',
            textOrientation: 'mixed',
            transform: 'rotate(180deg)',
            display: 'flex',
            alignItems: 'center'
          }}>
          {hiddenText || `Show ${header}`}
        </Box>}
      </Grid>
    </Toolbar>


    {isOpen && <>
      {/* Sort & Filter */}
      <Paper square sx={{
        p: 2,
        ...onInteract && { pt: 4 },
        position: 'relative', display: 'flex',
        justifyContent: 'center'
      }}>
        {onInteract && <InteractionButton variant="outlined"
          startIcon={<FontAwesomeIcon icon="fas fa-circle-plus" />}
          onClick={onInteract}
          children={t("common.addNew")}
          {...InteractionProps}
        />}
        <Grid container spacing={1} alignItems="center">
          <Grid item xs>
            <Select value={statusFilter} size="small" fullWidth
              onChange={e => setStatusFilter(e.target.value)}
            >
              <MenuItem value="all">All ({items.length})</MenuItem>
              {Object.entries(statusList).map(([status, num], s) =>
                <MenuItem key={s} value={status}>
                  {status} ({num})
                </MenuItem>
              )}
            </Select>
          </Grid>
          {sortKeys?.map(({ key, name, icon, reverseIcon }, si) => <Grid item key={si}>
            <Tooltip title={`Sort by ${name || key}`}>
              <IconButton color={key === sortKey[0] ? "primary" : ""}
                variant="contained"
                onClick={() => handleSort(key)}
              >
                <FontAwesomeIcon icon={`far fa-${key === sortKey[0] && !sortKey[1] ? icon : reverseIcon}`} />
              </IconButton>
            </Tooltip>
          </Grid>)}
        </Grid>
      </Paper>

      {/* Item List */}
      <List disablePadding sx={{ height: '100%', overflow: 'auto' }}>
        <PerfectScrollbar>
          {sortedItems.map((item, i) => {
            if (renderItem)
              return <React.Fragment key={i}>
                {renderItem(item, i,
                  moment(item[showNew || 'created']).isSameOrAfter(
                    moment().subtract(2, 'weeks'), 'week'
                  )
                )}
              </React.Fragment>
            return <ListItemButton key={i}
              onClick={() => handleItemClick(item)}
              selected={selected === item[idKey]}
            >
              {item[displayKey] || item}
              {showNew && moment(item[showNew || 'created']).isSameOrAfter(
                moment().subtract(2, 'weeks'), 'week'
              ) &&
                <Chip label="new" size="mini" color="secondary" sx={{ mx: 1 }} />
              }
            </ListItemButton>
          })}
        </PerfectScrollbar>
      </List>
    </>}
  </Drawer>
}

ListSidebar.defaultProps = {
  sortKeys: [
    { key: "created", name: "date", icon: "calendar-arrow-down", reverseIcon: "calendar-arrow-up" },
    { key: "name", reverseIcon: "arrow-down-a-z", icon: "arrow-down-z-a" },
  ],
  handleItemClick: item => console.log('clicked', item)
}

export default ListSidebar