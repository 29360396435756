import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormLabel,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PerfectScrollbar from "react-perfect-scrollbar";
import { DialogTitle, SubmissionStatusChip } from "src/components";
import ApplicationFormDetails from "./ApplicationFormDetails";
import { InboxContext } from "./MainView";

const STATE = {
  approve: {
    label: "Approve",
    color: "primary",
    icon: "check"
  },
  reject: {
    label: "Reject",
    color: "error",
    icon: "close"
  }
}

const ApprovalButton = ({ value, ...props }) => <ToggleButton
  {...props}
  variant='contained'
  color={value.color}
  value={value}
  sx={{
    minWidth: 100,
    ...props.sx
  }}
>
  <Box mr={1}
    color={value.color + '.main'}
  >
    <FontAwesomeIcon icon={`far fa-${value.icon}`} />
  </Box>
  {value.label}
</ToggleButton>

function ActionDialog(props) {
  const { activeItem } = useContext(InboxContext);
  const [approvalState, setApprovalState] = useState()

  return (
    <Dialog fullWidth maxWidth="lg" {...props}>
      <DialogTitle onClose={props.onClose}>
        Approve/Reject Application
      </DialogTitle>
      <PerfectScrollbar><DialogContent dividers>
        <Grid item xs display="flex" alignItems='center'>
          <Typography fontWeight="fontWeightBold" color="text.primary">
            Ref No: {activeItem.ReferenceNo} |
          </Typography>
          <SubmissionStatusChip label={activeItem.FormStatus}
            sx={{ ml: 1 }}
          />
        </Grid>

        <Grid item xs
          sx={theme => ({
            mt: 2, p: 1.5,
            borderTopRightRadius: theme.shape.borderRadius,
            borderTopLeftRadius: theme.shape.borderRadius,
            bgcolor: theme.palette.primary.main,
          })}
        >
          <Typography color={"common.white"} fontWeight={"fontWeightNormal"}>
            Approve/Reject
          </Typography>
        </Grid>
        <Grid container spacing={0} px={2} pb={2} mb={2}
          sx={theme => ({
            bgcolor: theme.palette.primary[200],
            borderBottomLeftRadius: theme.shape.borderRadius,
            borderBottomRightRadius: theme.shape.borderRadius,
          })}
        >
          <Grid item xs={12} pt={2}>
            <TextField multiline fullWidth
              placeholder={"Type your remarks *"} minRows={3}
              InputProps={{
                sx: { bgcolor: 'common.white' }
              }}
              helperText='Provide the rationale, recommendations, or directives for either granting or denying approval.'
              FormHelperTextProps={{ sx: { ml: 1 } }}
            />
          </Grid>
          <Grid item xs={12}>

          </Grid>
          <Grid item xs={12} mt={1} sx={{ display: "flex", gap: 2 }}>
            <FormControl>
              <FormLabel>
                <Typography
                  mt={2}
                  variant="h6"
                  color="primary.dark"
                  fontWeight="fontWeightBold"
                >
                  I choose to
                </Typography>
              </FormLabel>
              <ToggleButtonGroup value={approvalState} exclusive
                onChange={(e, newState) => setApprovalState(newState)}
                sx={{ bgcolor: 'common.white' }}
              >
                <ApprovalButton value={STATE.approve} />
                <ApprovalButton value={STATE.reject} />
              </ToggleButtonGroup>
            </FormControl>

          </Grid>
        </Grid>
        <ApplicationFormDetails fetchUrl="/testdata/inbox/18_ApplicationProcess.json" />
      </DialogContent></PerfectScrollbar>
      <DialogActions>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button variant="contained">Submit</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ActionDialog;
