import React from 'react'
import { DataTable, SearchField } from 'src/components'
import OrganizationExpanded from './OrganizationExpanded'
import { useDataTableUtils } from 'src/hooks/useUtils'

function OrganizationTab(props) {
  const { loading, data, getData, PaginationProps } = useDataTableUtils('/testdata/hist_credentials.json')

  const handleSearch = e => {
    getData({ searchTerm: e.target.value })
  }

  return <>
    <SearchField onChange={handleSearch}
      fullWidth sx={{ mt: 2 }} label="Search"
    />

    <DataTable
      loading={loading}
      PaginationProps={PaginationProps}
      columns={[
        {
          field: 'RepresentativeName',
          title: 'Representative Name'
        }, {
          field: 'Email',
        }, {
          field: 'Status',
        }, {
          field: 'EffectiveStartDate',
          title: 'Effective Start Date',
          type: 'date'
        }, {
          field: 'EffectiveEndDate',
          title: 'Effective End Date',
          type: 'date'
        }
      ]}
      data={data}
      expand={row => <OrganizationExpanded data={row} />}
    />
  </>
}

export default OrganizationTab