import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Chip, Grid, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'

const SelectableChip = styled(Chip, {
  shouldForwardProp: props => props !== 'selected',
})(({ theme, selected }) => ({
  transition: theme.transitions.create(),
  ...selected && ({
    backgroundColor: theme.palette.primary.main,
    '&, & .MuiChip-icon': {
      color: theme.palette.getContrastText(theme.palette.primary.main)
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  })
}))

function AttributeList({ attrs, selectable, preselected = [], onChange, innerRef, ...props }) {
  const [selected, setSelected] = useState(preselected)

  const handleClick = i => {
    if (selected.includes(i))
      setSelected(selected.filter(x => x !== i))
    else
      setSelected([...selected, i])
  }

  useEffect(() => {
    if (selectable) {
      if (onChange)
        onChange(selected)
      if (innerRef)
        innerRef.current = selected
    }
    // eslint-disable-next-line
  }, [selected, selectable])

  return <Grid container spacing={.5} mt={.5} {...props}>
    {attrs.map((attr, a) => <Grid item key={a}>
      <SelectableChip
        label={attr}
        size="small"
        {...selectable && {
          icon:
            <FontAwesomeIcon
              icon={`fa${selected.includes(attr) ? 's' : 'r'} fa-${selected.includes(attr) ? 'check-circle' : 'circle'}`}
            />,
          onClick: () => handleClick(attr),
          selected: selected.includes(attr)
        }}
      />
    </Grid>)}
  </Grid>
}

export default AttributeList