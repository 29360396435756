import { yupResolver } from '@hookform/resolvers/yup'
import moment from 'moment'
import { PERMISSIONS_LIST } from 'src/constants'
import * as yup from 'yup'




const addOrg = {
  resolver: yupResolver(yup.object({
    Name: yup.string().required(),
    Abbreviation: yup.string().required(),
    Description: yup.string().required(),
    RepresentativeName: yup.string().required(),
    RepresentativeEmail: yup.string().email().required(),
    EffectiveStartDate: yup.date().required(),
    EffectiveEndDate: yup.date().min(yup.ref('EffectiveStartDate')).required(),
  })),
  defaultValues: {
    EffectiveStartDate: moment(),
    EffectiveEndDate: moment()
  }
}

const datamodel_permissions = {
  defaultValues: {
    values: []
  },
  resolver: yupResolver(yup.object({
    values: yup.array().of(yup.object({
      DataModel: yup.string(),
      Version: yup.number(),
      Entities: yup.array().of(yup.object({
        Entity: yup.string(),
        Permissions: yup.array().of(yup.string().oneOf(PERMISSIONS_LIST.map(x => x.name)))
      }))
    }))
  }))
}

const sftp = {
  defaultValues: {
    DataModel: '',
    Entity: '',
    Version: '',
    Schedule: {
      Type: '',
      Day: '',
      Time: '',
      EffectiveDays: ''
    },
    ServiceName: '',
    ServiceType: '',
    Attributes: [],
    EffectiveStartDate: moment(),
    EffectiveEndDate: moment(),
    Created: moment(),
    CreatedBy: '',
    Modified: moment(),
    ModifiedBy: '',
    ServiceNumber: ''
  }
}

const notification = {
  defaultValues: {
    DataModel: '',
    Version: '',
    Entities: []
  },
  resolver: yupResolver(yup.object({
    DataModel: yup.string().required(),
    Version: yup.number().required(),
    Entities: yup.array().of(yup.object({
      Entity: yup.string(),
      ProductionURL: yup.string().url(),
      SandboxURL: yup.string().url(),
    })).compact(i => !i.Entity).min(1, "Please select at least one entity")
  }))
}

export { addOrg, datamodel_permissions, sftp, notification }