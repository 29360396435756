import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { entityRship } from '../form-validation'
import AddRelationshipMainForm from './MainForm'
import HasATable from './HasATable'
import { DDContext } from '../../MainView'
import IsATable from './IsATable'
import { DialogTitle } from 'src/components'

function AddRelationshipDialog({ onClose, ...props }) {
  const { activeItem: { Entity } } = useContext(DDContext)
  const methods = useForm(entityRship)
  const { reset } = methods
  const rshipType = useWatch({
    name: 'RelationshipType',
    control: methods.control
  })

  const submitForm = data => console.log(data)

  const resetData = () => {
    reset({
      ...entityRship.defaultValues,
      Name: Entity
    })
  }

  const handleClose = () => {
    resetData()
    onClose()
  }

  const submitDialog = () => {
    methods.handleSubmit(submitForm, console.log)()
    // onClose()
  }

  useEffect(() => {
    resetData()
    // eslint-disable-next-line
  }, [])

  return <FormProvider {...methods}>
    <form onSubmit={methods.handleSubmit(submitForm)}>
      <Dialog onClose={handleClose}
        fullWidth maxWidth="md" {...props}
      >
        <DialogTitle onClose={handleClose}>
          Add Data Entity Relationship
        </DialogTitle>
        <DialogContent dividers>
          <AddRelationshipMainForm />
          {rshipType === 'Is A' && <IsATable />}
          {rshipType === 'Has A' && <HasATable />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            Cancel
          </Button>
          <Button variant='contained' onClick={submitDialog}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  </FormProvider>
}

export default AddRelationshipDialog