import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import { DataTable, DateTimeControl, DialogTitle, EnvItem } from 'src/components'
import { useDataTableUtils, useFieldUtils } from 'src/hooks/useUtils'
import { DDContext } from './MainView'
import { DatePicker } from '@mui/x-date-pickers'
import { useForm } from 'react-hook-form'

function VersionPage(props) {
  const { loading, data, PaginationProps } = useDataTableUtils('/testdata/model_version.json')
  const { activeItem } = useContext(DDContext)
  const { handleSubmit, control, formState: { errors } } = useForm()
  const { errorState } = useFieldUtils({ errors })

  const [dialogItem, setDialogItem] = useState(false)

  const handleClose = () => setDialogItem(false)
  const submitForm = data => console.log(data)

  return <Box p={2}>
    <DataTable
      loading={loading}
      PaginationProps={PaginationProps}
      columns={[
        {
          field: 'Environment',
          render: rowData => <EnvItem env={rowData.Environment} />
        }, {
          field: 'Version',
        }, {
          field: 'Active',
          title: 'Status',
          render: rowData => <Chip variant='outlined'
            label={rowData.Active ? 'Active' : 'Inactive'}
            color={rowData.Active ? 'info' : 'default'}
          />
        }, {
          field: 'PublishedBy',
          title: 'Published By'
        }, {
          field: 'PublishDate',
          title: 'Published Date',
          type: 'datetime'
        }, {
          render: rowData => rowData.Environment === 'Production' && <Tooltip title='Update version'>
            <IconButton onClick={() => setDialogItem(rowData)}>
              <FontAwesomeIcon icon='fal fa-pen-to-square' />
            </IconButton>
          </Tooltip>
        }
      ]}
      data={data}
    />

    <form onSubmit={handleSubmit(submitForm)}><Dialog open={Boolean(dialogItem)} onClose={handleClose}
      fullWidth maxWidth='md'
    >
      <DialogTitle onClose={handleClose}>Update Data Model Version</DialogTitle>
      <DialogContent dividers sx={{ textAlign: 'center' }}>
        Selected published version&nbsp;
        <Typography fontWeight='fontWeightBold' color='primary.dark' component='span'>
          {dialogItem.Version}
        </Typography>&nbsp;
        of data model&nbsp;
        <Typography fontWeight='fontWeightBold' color='primary.dark' component='span'>
          {activeItem.DataModel}
        </Typography>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} md={6}>
            <DateTimeControl Component={DatePicker}
              control={control}
              name='EffectiveStartDate'
              label="Effective Start Date"
              slotProps={{
                textField: {
                  required: true,
                  fullWidth: true,
                  readOnly: true,
                  ...errorState('EffectiveStartDate')
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DateTimeControl Component={DatePicker}
              control={control}
              name='EffectiveEndDate'
              label="Effective End Date"
              slotProps={{
                textField: {
                  required: true,
                  fullWidth: true,
                  readOnly: true,
                  ...errorState('EffectiveEndDate')
                }
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit(submitForm)} variant='contained'>
          Update
        </Button>
      </DialogActions>
    </Dialog></form>
  </Box>
}

export default VersionPage