export const ORG_TABS = [
  { "icon": "file-circle-info", "name": "Summary" },
  { "icon": "user-lock", "name": "Permissions" },
  { "icon": "address-card", "name": "PI Info" },
  { "icon": "key", "name": "Credentials" },
  { "icon": "gears", "name": "Services" },
  { "icon": "timeline-arrow", "name": "History" }
]

export const DD_TABS = [
  { "icon": "file-circle-info", "name": "Summary" },
  { "icon": "code-branch", "name": "Version" },
  { "icon": "timeline-arrow", "name": "Change History" },
  { "icon": "file-contract", "name": "Terms of Use" }
]
export const DD_ENTITY_TABS = [
  { "icon": "list-tree", "name": "Attributes" },
  { "icon": "file-circle-info", "name": "Summary" },
  { "icon": "user-lock", "name": "Endpoint Configuration" },
  { "icon": "network-wired", "name": "Relationship" },
  { "icon": "timeline-arrow", "name": "Change History" }
]