import React, { useContext } from 'react'
import { Box, Card, CardContent, Divider, Grid, Typography } from '@mui/material'
import { OrgContext } from './MainView'
import moment from 'moment'
import { DATEFMT_SIMPLE_DATE } from 'src/constants'
import { SpacedDivider, SummaryItem } from 'src/components'

moment.defaultFormat = DATEFMT_SIMPLE_DATE

function SummaryPage(props) {
  const activeItem = useContext(OrgContext)

  return <Box p={2}>
    <Card variant="outlined">
      <CardContent><Grid container spacing={3}>
        <Grid item><SummaryItem icon="address-book" title="Representative">
          <Typography fontWeight="fontWeightBold" color="secondary">
            {activeItem.RepresentativeName}
          </Typography>
          <Typography fontWeight="fontWeightBold">
            {activeItem.RepresentativeEmail}
          </Typography>
        </SummaryItem></Grid>
        <Grid item><SummaryItem icon="calendar-check" title="Service start date">
          <Typography fontWeight="fontWeightBold">
            {moment(activeItem.EffectiveStartDate).format()}
          </Typography>
        </SummaryItem></Grid>
        <Grid item><SummaryItem icon="calendar-xmark" title="Service end date">
          <Typography fontWeight="fontWeightBold">
            {moment(activeItem.EffectiveEndDate).format()}
          </Typography>
        </SummaryItem></Grid>
      </Grid></CardContent>
      <Divider />
      <CardContent>
        <Typography variant="h5" mb={2}>Description</Typography>
        <Typography variant="subtitle1">Lorem ipsum dolor sit amet</Typography>
        <Typography>
          Consectetur adipiscing elit. Phasellus nec tellus nec risus dictum tempus. In nec ante odio. Suspendisse eleifend sodales nisl, sit amet scelerisque diam scelerisque id. Cras auctor congue felis non tincidunt. Donec scelerisque porttitor quam a luctus. Sed malesuada aliquam lectus, eu imperdiet lorem dictum quis. Mauris volutpat leo varius magna porttitor ultricies. Etiam rhoncus aliquam odio a sodales. Nullam lobortis faucibus odio, eu scelerisque velit volutpat quis. Maecenas est risus, semper eget velit rhoncus, cursus vehicula magna. Sed varius vitae nulla vulputate lobortis. Vestibulum eu ipsum diam. Etiam non ultricies turpis, lacinia euismod leo. Maecenas bibendum non massa vitae rutrum.
        </Typography>
        <SpacedDivider />
        <Typography variant="subtitle1">Maecenas ut leo turpis</Typography>
        <Typography>
          Duis vulputate a neque vel facilisis. Praesent vel erat dictum sapien laoreet suscipit id vitae turpis. Duis posuere augue nec ex aliquam, ut feugiat metus maximus. Vestibulum tempus nunc id velit dignissim tincidunt. Maecenas sodales magna a pharetra feugiat. Aliquam at vestibulum nulla. Etiam fermentum, arcu eget blandit laoreet, leo turpis tincidunt erat, nec rutrum ex libero quis enim.
        </Typography>
        <SpacedDivider />
        <Typography variant="subtitle1">Etiam neque sem</Typography>
        <Typography>
          Porttitor eu nibh eu, ornare consectetur sem. Proin quis viverra leo. Cras id leo tellus. Aenean at molestie sapien. In ex neque, pharetra non placerat a, egestas eu risus. Sed eu tempor risus, ac tristique diam. Nunc sed orci nisi. Vivamus tempus rutrum dui in commodo. Sed gravida sagittis rhoncus. Praesent ultrices dolor a dolor ullamcorper egestas.
        </Typography>
      </CardContent>
    </Card>
  </Box>
}

export default SummaryPage