import moment from "moment";
import { useController, useFormContext } from "react-hook-form";

function DateTimeControl({ name, Component, control, controllerProps, onChange, ...props }) {
  const ctx = useFormContext()
  const { field, defaultValue } = useController({ control: (ctx?.control || control), name, ...controllerProps })

  const handleChange = (...args) => {
    field.onChange(...args)
    if (onChange)
      onChange(...args)
  }

  return <Component
    value={moment(field.value || defaultValue)}
    onChange={handleChange}
    {...props}
  />;
}

export default DateTimeControl