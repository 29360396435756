import { Button, Checkbox, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, MenuItem, TextField as MuiTextField } from '@mui/material'
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { entityAttr } from './form-validation'
import { useFieldUtils } from 'src/hooks/useUtils'
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form'
import { CLASSIFICATION_LEVELS } from 'src/constants'
import { DialogTitle } from 'src/components'

const Context = createContext(null)

const CheckboxCtrl = ({ name, ...others }) => {
  const { isEditing } = useContext(Context)

  return <FormControlLabel control={<Controller
    name={name}
    render={({ field: props }) => (
      <Checkbox
        {...props}
        checked={props.value}
        onChange={(e) => props.onChange(e.target.checked)}
        disabled={!isEditing}
      />
    )}
  />}
    {...others}
  />
}

const TextField = ({ name, InputProps, ...props }) => {
  const { register, formState: { errors } } = useFormContext()
  const { errorState, displayField } = useFieldUtils({ errors })
  const { isEditing } = useContext(Context)

  return <MuiTextField
    {...register(name)}
    {...errorState('Name')}
    {...displayField(!isEditing, InputProps)}
    {...props}
  />
}

function AttrViewDialog({ item, open = false, ...props }) {
  const [isEditing, setEditing] = useState(!Boolean(item))
  const { reset, ...methods } = useForm(entityAttr)

  const titleAction = useMemo(() => {
    if (!item)
      return 'Add'
    return isEditing ? 'Edit' : 'View'
  }, [item, isEditing])

  const submitForm = data => console.log(data)

  const handleCancel = () => {
    if (isEditing && Boolean(item))
      setEditing(false)
    else if (props.onClose)
      props.onClose()
  }

  const handleSubmit = () => {
    if (isEditing)
      methods.handleSubmit(submitForm)
    else
      setEditing(true)
  }

  useEffect(() => {
    if (item) {
      reset(item)
      setEditing(false)
    }
  }, [item, reset])

  return <Context.Provider value={{ isEditing }}>
    <FormProvider {...methods} reset={reset}>
      <form onSubmit={methods.handleSubmit(submitForm)}>
        <Dialog open={Boolean(item) || open}
          fullWidth maxWidth="lg" {...props}
        >
          <DialogTitle onClose={props.onClose}>
            {titleAction} Data Entity Attribute
          </DialogTitle>
          <DialogContent dividers><Grid container pt={1} spacing={2}>
            <Grid item xs={12} md={7}><Grid container spacing={2} direction='column'>
              <Grid item><TextField name="Name"
                label="Name"
                fullWidth required
                value=''
              /></Grid>
              <Grid item><TextField name="Description"
                label="Description"
                fullWidth
              /></Grid>
              <Grid item>
                <TextField name='Classification'
                  label="Classification Level"
                  select value={methods.watch('Classification')}
                  fullWidth required
                >
                  {CLASSIFICATION_LEVELS.map(x => <MenuItem key={x}
                    value={x}
                  >
                    {x}
                  </MenuItem>)}
                </TextField>
              </Grid>
              <Grid item><TextField name='DataFormat'
                label="Data Format Description"
                fullWidth multiline
              /></Grid>
              <Grid item><TextField name='DataValidationPattern'
                label="Data Validation Pattern"
                fullWidth required
              /></Grid>
              <Grid item><TextField name='DataLength'
                label="Data Length"
                fullWidth required
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              /></Grid>
            </Grid></Grid>
            <Grid item xs={12} md={5}>
              <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend" required>Attribute Properties</FormLabel>
                <FormGroup>
                  <Grid container spacing={{ xs: 2, md: 1 }}>
                    <Grid item xs={6} sm={4} md={12} xl={6}>
                      <CheckboxCtrl name='IsKey' label='Key' />
                    </Grid>
                    <Grid item xs={6} sm={4} md={12} xl={6}>
                      <CheckboxCtrl name='IsMandatoryKey' label='Mandatory Key' />
                    </Grid>
                    <Grid item xs={6} sm={4} md={12} xl={6}>
                      <CheckboxCtrl name='IsUniqueKey' label='Unique Key' />
                    </Grid>
                    <Grid item xs={6} sm={4} md={12} xl={6}>
                      <CheckboxCtrl name='IsSearchable' label='Searchable' />
                    </Grid>
                    <Grid item xs={12} sm={8} md={12}>
                      <CheckboxCtrl name='IsPII' label='Personal Identifiable Information (PII)' />
                    </Grid>
                  </Grid>
                </FormGroup>
              </FormControl>

            </Grid>
          </Grid></DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant='contained'
              onClick={handleSubmit}
            >
              {isEditing ? (item ? 'Save' : 'Add') : 'Edit'}
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </FormProvider>
  </Context.Provider>
}

export default AttrViewDialog